/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CustomPersonalDetailTypes {
  MULTILINE = 'multiline',
  BOOLEAN = 'boolean',
  DROP_DOWN = 'drop_down',
}
