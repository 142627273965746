import useSWR from 'swr';
import { KeyedMutator } from 'swr/_internal';
import { AccountData, AccountService } from '../../api';

type UseGetAccountAuthenticatedProps = {
  shouldNotMakeRequest?: boolean;
};
type UseGetAccountAuthenticatedResult = {
  account: AccountData;
  error: any;
  isLoading: boolean;
  updateLocalAccount: KeyedMutator<AccountData>;
};
const accountFetcher = async () => AccountService.getAccountAuthenticated();
export const useGetAccountAuthenticated = ({
  shouldNotMakeRequest,
}: UseGetAccountAuthenticatedProps): UseGetAccountAuthenticatedResult => {
  const { data, error, isLoading, mutate } = useSWR(
    shouldNotMakeRequest ? null : { opId: 'getAccountAuthenticated' },
    accountFetcher,
    {
      revalidateOnFocus: false,
    },
  );
  return {
    account: data,
    error,
    isLoading,
    updateLocalAccount: mutate,
  };
};
