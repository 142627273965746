import classNames from 'classnames';
import { Box, ButtonBase, Icon } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationItem } from './MainSidebar';

type NavigationMainButtonProps = {
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  navigationItem: NavigationItem;
  height: string;
};
export const NavigationItemButton = ({ navigationItem, setIsSidebarOpen, height }: NavigationMainButtonProps) => {
  const { key, name, icon, target, external, navigate } = navigationItem;
  const location = useLocation();
  const buttonComponent = (
    <ButtonBase
      sx={{ width: '100%', height }}
      className={classNames('dashboard-link', {
        'dashboard-link-active': location.pathname === navigate,
      })}
      onClick={() => setIsSidebarOpen(false)}>
      <Icon className="sidebar-link-icon" sx={{ width: 0, direction: 'ltr!important' }}>
        {icon}
      </Icon>

      <span className="sidebar-link-text">{name}</span>

      <Box mx="auto" />
    </ButtonBase>
  );
  return external ? (
    <a target="_blank" href={navigate} rel="noreferrer" key={key}>
      {buttonComponent}
    </a>
  ) : (
    <Link to={navigate} target={target} key={key} style={{ textDecoration: 'none', cursor: 'pointer' }}>
      {buttonComponent}
    </Link>
  );
};
