/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DailyDashboardOut } from '../models/DailyDashboardOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {
  /**
   * Dashboard
   * @returns DailyDashboardOut Successful Response
   * @throws ApiError
   */
  public static dailyDashboard(): CancelablePromise<DailyDashboardOut> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/daily-dashboard',
    });
  }
}
