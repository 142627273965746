import useSWR from 'swr';
import { CalendarService } from '../../api';

const fetcher = async ({ startDate, endDate }: { startDate: string; endDate: string }) =>
  CalendarService.getCalendarUserAvailability(startDate, endDate);

type useCalendarUserAvailabilitiesProps = {
  startDate: string;
  endDate: string;
};

export const useCalendarUserAvailabilities = ({ startDate, endDate }: useCalendarUserAvailabilitiesProps) => {
  const { data, error, isLoading } = useSWR(
    {
      startDate,
      endDate,
      opId: 'getCalendarUserAvailabilities',
    },
    fetcher,
    { refreshInterval: 60000 },
  );

  return {
    items: data || {},
    isLoading,
    isError: error,
  };
};
