import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { FormTextField } from '../../formComponents/FormTextField';
import { InformationFormValues } from './InformationSection';
import { Language } from '../../../api';

type InformationSectionProps = {
  language: Language;
  values: InformationFormValues;
  initialValues: InformationFormValues;
  disabled: boolean;
};
export const InformationSectionLanguageTab = ({
  language,
  values,
  initialValues,
  disabled,
}: InformationSectionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const textBoxWidth = isSmallScreen ? 300 : 500;
  return (
    <Grid container flexDirection="column" gap={2}>
      <FormTextField
        isFieldChanged={initialValues.label[language] !== values.label[language]}
        width={textBoxWidth}
        label={t('editOrderPage.orderPageName')}
        fieldName={`label.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPageNameCaption')}
      />
      <FormTextField
        isFieldChanged={initialValues.description?.[language] !== values.description?.[language]}
        width={textBoxWidth}
        label={t('editOrderPage.orderPageDescription')}
        fieldName={`description.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPageDescriptionCaption')}
      />

      <FormTextField
        isFieldChanged={initialValues.activity_label?.[language] !== values.activity_label?.[language]}
        width={textBoxWidth}
        label={t('editOrderPage.activityLabel')}
        fieldName={`activity_label.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.activityLabelCaption')}
      />
      <FormTextField
        isFieldChanged={initialValues.location?.[language] !== values.location?.[language]}
        width={textBoxWidth}
        label={t('editOrderPage.orderPageLocation')}
        fieldName={`location.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPageLocationCaption')}
      />

      <FormTextField
        isFieldChanged={initialValues.phone?.[language] !== values.phone?.[language]}
        width={textBoxWidth}
        label={t('editOrderPage.orderPagePhone')}
        fieldName={`phone.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPagePhoneCaption')}
      />
    </Grid>
  );
};
