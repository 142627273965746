import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

interface DroppableContainerProps {
  id: string;
  children: React.ReactNode;
}

const DroppableContainer: React.FC<DroppableContainerProps> = ({ id, children }) => {
  const { t } = useTranslation();

  const { setNodeRef } = useDroppable({
    id,
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // @ts-ignore
  const hasNoItems = children.props.items.length === 0;

  return (
    <Grid
      sx={{
        boxShadow: isSmallScreen ? 0 : 3,
        p: isSmallScreen ? 0 : 2,
        borderRadius: 1,
      }}
      container
      flexDirection="column"
      ref={setNodeRef}
      minHeight="150px">
      {hasNoItems ? (
        <Grid container height="100%" justifyContent="center" alignItems="center" gap={1}>
          <AddCircleOutline style={{ color: '#4BBD00', height: '32px', width: '32px' }} />
          <Typography variant="h6">{t('emailTemplates.dndhere')}</Typography>
        </Grid>
      ) : (
        children
      )}
    </Grid>
  );
};

export default DroppableContainer;
