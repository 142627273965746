import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountData, AccountService, OrderPageData } from '../../../api';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { useLanguage } from '../../../common/GeneralUtils';

type RestrictionsSettingsSectionProps = {
  orderPage: OrderPageData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
  account: AccountData;
};

export const DeletionSection = ({
  orderPage,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
  account,
}: RestrictionsSettingsSectionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const language = useLanguage();

  const handleDelete = async () => {
    try {
      posthog.capture('Editing settings', { section: 'DeletionSection', orderPageId: orderPage.id });
      account = await AccountService.archiveOrderPage(orderPage.id);
      await updateLocalAccount(account);
      navigate(`/dashboard/order-pages`);
      setIsUpdatedSnackOpen(true);
      setIsDialogOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container flexDirection="column" alignContent="center" alignItems="center" gap={4}>
      <Typography variant="h6">{t('deleteOrderPage.title')}</Typography>
      <Grid item>
        <Button variant="contained" color="error" onClick={() => setIsDialogOpen(true)} disabled={readOnly}>
          {t('deleteOrderPage.button', { name: orderPage.label[language] })}
        </Button>
      </Grid>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={(actionConfirmed) => {
          if (actionConfirmed) {
            void handleDelete();
          }
          setIsDialogOpen(false);
        }}
        title={t('deleteOrderPage.confirmTitle')}
        content={t('deleteOrderPage.confirmText', { name: orderPage.label[language] })}
        ctaText={t('deleteOrderPage.confirm')}
        confirmButtonColor="error"
      />
    </Grid>
  );
};
