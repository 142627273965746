/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DiscountStatus {
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
}
