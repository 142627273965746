import React, { useEffect, useRef, useState } from 'react';
import './MarketPlaceMain.scss';
import { Grid, List, ListItem, ListItemIcon, ThemeProvider, useMediaQuery } from '@mui/material';

import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Check } from '@mui/icons-material';
import { usePostHog } from 'posthog-js/react';
import { MarketPlaceItemData, MarketplaceItemsService } from '../api';
import { theme } from '../common/GeneralUtils';
import BusinessCardList from './BusinessCardList';
import MarketPlaceHeader from './MarketPlaceHeader';
import { SECTION_BGCOLOR } from '../marketingsite/HomePage';
import WhatsAppButton from '../marketingsite/WhatsAppButton';

export const homePageTheme = createTheme({
  customTheme: {},
  typography: {
    fontFamily: "'Heebo', sans-serif",
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#4abd00',
      light: '#79ecaf',
      dark: '#1e4901',
    },
  },
});

const SCROLL_HEIGHT_TO_ACTIVE_TOPBAR = 100;
const ExplanationTypography = ({ children, noAlignment }: { children: any; noAlignment?: boolean }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Typography
      align={noAlignment ? undefined : 'center'}
      style={{ lineHeight: 1.5, fontSize: isSmallScreen ? '14px' : '16px', fontWeight: 500 }}>
      {children}
    </Typography>
  );
};
const Title = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Typography align="center" style={{ fontSize: isSmallScreen ? '22px' : '40px', fontWeight: 700 }}>
      {children}
    </Typography>
  );
};
const SubTitle = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Typography align="center" style={{ fontSize: isSmallScreen ? '11px' : '20px', fontWeight: 500 }}>
      {children}
    </Typography>
  );
};
const conditions = [
  'בעלי עסק סדנאות סיורים או חוויות שהיה פעיל לפני תחילת המלחמה',
  'עסק נפגע משמעותית בהכנסותיו והוא בסיכון להסגר',
  'הנכם תורמים מעצמכם ומהעסק בזמן המלחמה',
  'בבעלותכם חשבון סליקה בחשבונית ירוקה, grow, או טרנזילה (ויבוק סולקת את הכסף ישירות אל בעלי העסקים)',
];
const MarketPlaceMain = () => {
  const posthog = usePostHog();

  const [activeTopBar, setActiveTopBar] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const businessCardsRef = useRef(null);
  const conditionsCards = useRef(null);
  const scrollToRef = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  const [marketPlaceItems, setMarketPlaceItems] = useState<MarketPlaceItemData[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    posthog.startSessionRecording({});
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > SCROLL_HEIGHT_TO_ACTIVE_TOPBAR;
      if (scrollCheck) {
        setActiveTopBar(true);
      } else {
        setActiveTopBar(false);
      }
    });

    const fetchMarketplaceItems = async () => {
      const result = await MarketplaceItemsService.getMarketplaceItems();
      setMarketPlaceItems(result);
    };

    void fetchMarketplaceItems();
  }, []);

  return (
    <ThemeProvider theme={homePageTheme}>
      <div className="market-place-main">
        <Helmet>
          <title>WeBook - {t('marketplace.main_title')}</title>
          <meta name="description" content={t('marketplace.meta_description')} />
          <link rel="canonical" href="https://www.webook.live/" />
        </Helmet>
        <div className="image-bg">
          <div className="overlay" />
          <div className="inner">
            <MarketPlaceHeader activeTopBar={activeTopBar} secondaryWhatsappButton />
            <div>
              <div className="titles-container" style={{ paddingTop: isSmallScreen ? '5px' : '20px' }}>
                <div className="big-title" style={{ marginBottom: isSmallScreen ? '0px' : '20px' }}>
                  {t('marketplace.vouchers.title')}
                </div>
                <div className="small-title" style={{ marginBottom: isSmallScreen ? '0px' : '20px' }}>
                  {t('marketplace.vouchers.subtitle')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Grid container height={isSmallScreen ? 20 : 80} bgcolor={SECTION_BGCOLOR} />
        <Grid
          container
          pb={isSmallScreen ? '20px' : '80px'}
          px={isSmallScreen ? 4 : 40}
          justifyContent="center"
          flexDirection="column"
          gap={3}
          bgcolor={SECTION_BGCOLOR}>
          <Title>אודות היוזמה</Title>
          <Grid container gap={isSmallScreen ? 4 : 20} justifyContent="center" flexWrap="nowrap">
            <Button onClick={() => scrollToRef(businessCardsRef)} variant="contained">
              <Typography color="white"> לרכישת שובר</Typography>
            </Button>
            <Button style={{ borderColor: 'black' }} onClick={() => scrollToRef(conditionsCards)} variant="outlined">
              <Typography color="black">להצטרפות חינם</Typography>
            </Button>
          </Grid>
          <ExplanationTypography>
            עשרות בעלי סדנאות טיולים וחוויות הפסיקו לעבוד בעקבות ההתקפה הרצחנית. בעלי העסקים שלנו מעבירים סדנאות חינם
            למפונים ונפגעים ואנחנו רוצים לעזור להם ביום שאחרי.
          </ExplanationTypography>
          <ExplanationTypography>
            לבעלי סדנאות יש המון הוצאות בסיס ובשביל לעזור להם לשרוד את התקופה הקשה הזאת חברת WeBook בנתה את הפלטופרמה
            שמאפשרת לרכוש שוברים לסדנאות ולממש אותם אחרי שננצח. הפלטפורמה מאפשרת בעזרתכם להמשיך לתרום סדנאות ללא עלות
            למפונים ונפגעים וגם לשרוד את התקופה הקשה שאנחנו נמצאים בה ולא לאבד את העסק המדהים שלהם.
          </ExplanationTypography>
          <ExplanationTypography>
            הרעיון הוא ממש פשוט: קונים עכשיו מבעלי הסדנאות שוברים, כדי לתת מקדמה ולעזור למעט כסף לזרום דרך העסק שלא
            יסגר. בתמורה, מקבלים שוברים למימוש מתי שתרצו, אחרי שננצח.
          </ExplanationTypography>
          <ExplanationTypography>ההצטרפות והשימוש בפלטפורמה לניהול ומכירת השוברים היא בחינם.</ExplanationTypography>
        </Grid>
        <Grid container py={isSmallScreen ? '20px' : '80px'} ref={businessCardsRef} justifyContent="center" gap={4}>
          <Title>העסקים שלנו</Title>
          <BusinessCardList marketPlaceItems={marketPlaceItems} />
        </Grid>
        <Grid
          ref={conditionsCards}
          container
          bgcolor={SECTION_BGCOLOR}
          py={isSmallScreen ? '20px' : '80px'}
          px={isSmallScreen ? 2 : 40}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap={isSmallScreen ? 1 : 3}>
          <Title>בעלי עסק בתחום סדנאות, סיורים או חוויות? הצטרפו עוד היום בחינם לגמרי!</Title>
          <SubTitle>למי ההצטרפות והשימוש בחינם?</SubTitle>
          <List>
            {conditions.map((condition) => (
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ExplanationTypography noAlignment>{condition}</ExplanationTypography>
              </ListItem>
            ))}
          </List>
          <Grid item display="flex" justifyContent="center" width={180}>
            <WhatsAppButton
              color="black"
              secondaryWhatsappButton
              cta={'להצטרפות חינם'}
              messageText={'Hey Webook! I would like to join for free'}
            />
          </Grid>
        </Grid>

        <footer
          style={{
            display: 'grid',
            justifyContent: 'center',
            marginBottom: '8px',
          }}>
          <div hidden>
            <a target="_blank" href="https://uptime.betterstack.com" rel="noreferrer">
              <img
                style={{ width: '130px', height: '52px' }}
                alt="Better Stack Website Monitoring"
                src="https://uptime.betterstack.com/assets/static_assets/badges/light.png"
              />
            </a>
          </div>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default MarketPlaceMain;
