import { Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DraggableItem, TextItem } from './DraggableItems';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { Language } from '../../../api';
import 'react-quill/dist/quill.snow.css';

type EditTextDialogProps = {
  selectedItem?: DraggableItem;
  selectedLanguage: Language;
  setSelectedItem: (item: DraggableItem) => void;
  isEditItemDialogOpen: boolean;
  setIsEditItemDialogOpen: (isOpen: boolean) => void;
  updateItem: (item: DraggableItem) => void;
};
export const EditTextDialog = ({
  selectedItem,
  selectedLanguage,
  setSelectedItem,
  isEditItemDialogOpen,
  setIsEditItemDialogOpen,
  updateItem,
}: EditTextDialogProps) => {
  const { t } = useTranslation();

  const modules = {
    toolbar: [[{ header: [1, 2, false] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], ['link']],
  };

  const styleId = 'dynamic-quill-link-styles';
  let styleElement = document.getElementById(styleId);
  if (styleElement) {
    styleElement.innerHTML = `.ql-editor a { color: #4BBD00 !important; text-decoration: underline !important; }`;
  } else {
    // Otherwise, create a new style element
    styleElement = document.createElement('style');
    styleElement.id = styleId;
    styleElement.innerHTML = `.ql-editor a { color: #4BBD00 !important; text-decoration: underline !important; }`;
    document.head.appendChild(styleElement);
  }
  return (
    <ConfirmationDialog
      title={t('emailTemplates.editDialog.title')}
      content={
        <Grid container flexDirection="column" dir={selectedLanguage === Language.HE ? 'rtl' : 'ltr'} height="80vh">
          <ReactQuill
            style={{ direction: 'ltr' }}
            theme={'snow'}
            value={(selectedItem as TextItem).content}
            onChange={(newText: string) => {
              setSelectedItem({
                ...selectedItem,
                content: newText,
              } as TextItem);
            }}
            modules={modules}
          />
        </Grid>
      }
      isOpen={isEditItemDialogOpen}
      ctaText={t('save')}
      confirmButtonColor="primary"
      onClose={(actionConfirmed) => {
        setIsEditItemDialogOpen(false);
        if (actionConfirmed) {
          void updateItem(selectedItem!);
        }
      }}
    />
  );
};
