import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderPageData } from '../../../api';

export type ParallelPersonsCapacitiesSettingProps = {
  orderPageData: OrderPageData;
  initialBlockedCapacities: number[];
  setDetail: (blockerCapacities: number[]) => any;
};
export const ParallelPersonsCapacitiesSetting = ({
  orderPageData,
  initialBlockedCapacities,
  setDetail,
}: ParallelPersonsCapacitiesSettingProps) => {
  const { t } = useTranslation();
  const customDetail =
    orderPageData.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities;
  const [blockedCapacities, setBlockedCapacities] = useState<number[]>(initialBlockedCapacities);
  const handleCheckboxChange = (number: number) => {
    setBlockedCapacities((prevSelected) => {
      if (prevSelected.includes(number)) {
        return prevSelected.filter((num) => num !== number);
      }
      return [...prevSelected, number];
    });
  };
  useEffect(() => {
    setDetail(blockedCapacities);
  }, [blockedCapacities]);
  return (
    <FormGroup>
      <Grid item container flexDirection="column" alignItems="center">
        {customDetail?.map((capacity) => (
          <Grid item>
            <FormControlLabel
              control={<Checkbox />}
              checked={blockedCapacities.includes(capacity)}
              label={t('availabilitySettings.capacity', {
                capacity,
              })}
              onChange={() => handleCheckboxChange(capacity)}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
};
