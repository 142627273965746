import { createContext, useContext, useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import ActivityDialog from './ActivityDialog/ActivityDialog';
import { WidgetThemeProvider } from '../../order_page/theme/Theme';

const initialState = {};

const CommonDialogsContext = createContext(initialState);

export const useCommonDialogs = () => useContext(CommonDialogsContext);

export const CommonDialogsProvider = ({ children }) => {
  const posthog = usePostHog();
  const [activityDialogState, setActivityDialogState] = useState({
    isOpen: false,
  });

  useEffect(() => {
    posthog.startSessionRecording({});
  }, []);
  const openActivityDialog = (activityLineType, data) => {
    setActivityDialogState({
      ...activityDialogState,
      isOpen: true,
      activityLineType,
      data,
    });
  };

  const closeActivityDialog = () => {
    setActivityDialogState({
      ...activityDialogState,
      isOpen: false,
    });
  };

  return (
    <WidgetThemeProvider>
      <CommonDialogsContext.Provider
        value={{
          openActivityDialog,
          closeActivityDialog,
          activityDialogState,
        }}>
        <ActivityDialog />

        {children}
      </CommonDialogsContext.Provider>
    </WidgetThemeProvider>
  );
};
