import { usePriceRenderer } from '../calendar/calendar-items/usePriceRenderer';
import { OrderData } from '../api';

type PriceCellProps = {
  order: OrderData;
};
export const PriceCell = ({ order }: PriceCellProps): JSX.Element => {
  const { SlimPrice } = usePriceRenderer({
    orderId: order.id,
    discountId: order.discount_id,
    initialPrice: order.price,
    returningCustomerFromOrderId: order.returning_customer_from_order_id,
    extraPaymentLines: order.extra_payment_lines,
  });
  return <SlimPrice />;
};
