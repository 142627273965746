import { Grid, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../orders/Orders.scss';
import { QuestionMark } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import '../orders/UpcomingOrders.scss';
import ConfirmationNumber from '@mui/icons-material/ConfirmationNumber';
import { AccountData, VoucherOut, VoucherService, VoucherStatus } from '../api';
import { getVoucherPaymentStatusTextOrIcon, getVoucherStatus } from './utils';
import VoucherPopper from './VoucherPopper';
import OrderBoxDialog from '../calendar/calendar-items/OrderBoxDialog';
import { CellProps, useTable } from '../table/useTable';
import VouchersActions from './VouchersActions';
import { priceFormatter } from '../order_page/utils';
import { useAuth } from '../session/InternalAuthProvider';
import { useCalendar } from '../calendar/CalendarContext';
import EditOrderDialog from '../calendar/dialogs/EditOrderDialog/EditOrderDialog';
import { WorkshopCircle } from '../common/ui/WorkshopCircle';
import { useLanguage } from '../common/GeneralUtils';
import TimeUtils from '../common/TimeUtils';
import { formatDate } from '../common/formatDate';

export const voucherFetcher = async ({ search, page, perPage }: { search?: string; page: number; perPage: number }) =>
  VoucherService.getVouchers(page, perPage, search);

const VouchersTable = () => {
  const { t } = useTranslation();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const language = useLanguage();

  const [voucherOpen, setVoucherOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<VoucherOut | undefined>(undefined);
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderId, setOrderId] = useState<string | undefined>(undefined);

  // @ts-ignore
  const { editOrderDialogState, closeEditOrderDialog } = useCalendar();

  const tableDetails: CellProps<VoucherOut>[] = [
    {
      id: 'createdat',
      alignment: 'center',
      label: t('vouchers.createdat'),
      render: (voucher: VoucherOut) => (
        <Typography>
          {TimeUtils.getLocalTime(voucher.created_at, account.timezone).format(
            `${formatDate(account).shortDateFormat} HH:mm`,
          )}
        </Typography>
      ),
    },
    {
      id: 'name',
      alignment: 'center',
      label: t('vouchers.name'),
      render: (voucher: VoucherOut) => `${voucher.firstname} ${voucher.lastname}`,
    },
    {
      id: 'num_persons',
      alignment: 'center',
      label: t('vouchers.num_persons'),
      render: (voucher: VoucherOut) => voucher.persons,
    },
    {
      id: 'phone_of_buyer',
      alignment: 'center',
      label: t('vouchers.phone_of_buyer'),
      render: (voucher: VoucherOut) => voucher.phone,
    },
    {
      id: 'workshop',
      alignment: 'center',
      label: t('orders.workshop'),
      render: (voucher: VoucherOut) => (
        <WorkshopCircle account={account} orderPageId={voucher.order_page_id} language={language} withTooltip />
      ),
    },
    {
      id: 'price',
      alignment: 'center',
      label: t('vouchers.price'),
      render: (voucher: VoucherOut) => priceFormatter(voucher.price, authState.account as unknown as AccountData),
    },
    {
      id: 'order_number',
      alignment: 'center',
      label: t('order_number'),
      render: (voucher: VoucherOut) => `#${voucher.order_number}`,
    },
    {
      id: 'paymentStatus',
      alignment: 'center',
      label: t('vouchers.paymentStatus'),
      render: (voucher: VoucherOut) => getVoucherPaymentStatusTextOrIcon(voucher.payment_status, t),
    },
    {
      id: 'status',
      alignment: 'center',
      label: t('vouchers.status'),
      render: (voucher: VoucherOut) => getVoucherStatus(voucher.status, t),
    },
    {
      id: 'orderlink',
      alignment: 'center',
      label: t('vouchers.orderlink'),
      render: (voucher: VoucherOut) =>
        voucher.visits_used?.[0] ? (
          <Grid
            sx={{ cursor: 'pointer' }}
            item
            onClick={() => {
              setOrderOpen(true);
              if (voucher.visits_used?.[0]) {
                setOrderId(voucher.visits_used?.[0]);
              }
            }}>
            <Tooltip title={t('info')} arrow>
              <ConfirmationNumber style={{ color: '#bababa' }} />
            </Tooltip>
          </Grid>
        ) : (
          ''
        ),
    },
    {
      id: 'action-icon',
      alignment: 'left',
      width: 50,
      label: t('vouchers.details'),
      render: (voucher: VoucherOut) => (
        <IconButton
          className="action-icon"
          onClick={() => {
            setSelectedVoucher(voucher);
            setVoucherOpen(true);
          }}>
          <Tooltip title={t('info')} arrow>
            <QuestionMark style={{ color: '#bababa' }} />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  const {
    mutate: mutateVouchers,
    data,
    TableComponent,
  } = useTable<VoucherOut>({
    actionsComponent: <VouchersActions />,
    defaultRowsPerPage: 8,
    opId: 'getVouchers',
    fetcher: voucherFetcher,
    tableDetails,
  });
  const vouchersList = data || [];

  const archiveVoucher = () => {
    if (selectedVoucher) {
      const otherVouchers = vouchersList.filter((x) => x.id !== selectedVoucher.id);
      void mutateVouchers([...otherVouchers, { ...selectedVoucher, status: VoucherStatus.ARCHIVED }]);
    }
  };
  const redeemVoucherCallback = (voucher: VoucherOut) => {
    const otherVouchers = vouchersList.filter((x) => x.id !== voucher.id);
    void mutateVouchers([...otherVouchers, { ...voucher, status: VoucherStatus.USED }]);
  };
  return (
    <Grid container>
      <TableComponent />
      <VoucherPopper
        open={voucherOpen}
        setOpen={setVoucherOpen}
        voucher={selectedVoucher}
        archiveVoucher={archiveVoucher}
      />
      <OrderBoxDialog open={orderOpen} setOpen={setOrderOpen} orderId={orderId} />
      <EditOrderDialog
        editOrderDialogState={editOrderDialogState}
        closeEditOrderDialog={() => {
          void mutateVouchers();
          void closeEditOrderDialog();
        }}
        redeemVoucherCallback={redeemVoucherCallback}
      />
    </Grid>
  );
};

export default VouchersTable;
