import { t } from 'i18next';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Tooltip } from '@mui/material';
import { ChangeCircle, DeleteForever } from '@mui/icons-material';
import { VoucherPaymentStatus, VoucherStatus } from '../api';

export const getVoucherPaymentStatusTextOrIcon = (status: VoucherPaymentStatus, translations: any) => {
  switch (status) {
    case VoucherPaymentStatus.PENDING_PAYMENT:
      return t('vouchers.payment_status.pending_payment');
    case VoucherPaymentStatus.PAID:
      return (
        <Tooltip title={translations('vouchers.payment_status.paid')} arrow>
          <AutoAwesomeIcon style={{ color: '#0DF205' }} />
        </Tooltip>
      ); // <img src={wbImage} alt="Favicon" />;
    case VoucherPaymentStatus.MANUAL:
      return (
        <Tooltip title={translations('vouchers.payment_status.manual')} arrow>
          <PlumbingIcon style={{ color: '#bababa' }} />
        </Tooltip>
      );
    case VoucherPaymentStatus.CONFIRMED:
      return translations('vouchers.payment_status.confirmed');
    case VoucherPaymentStatus.CONVERTED_FROM_ORDER:
      return (
        <Tooltip title={translations('vouchers.payment_status.converted_from_order')} arrow>
          <ChangeCircle style={{ color: '#bababa' }} />
        </Tooltip>
      );
    default:
      return '';
  }
};

export const getVoucherStatusText = (status: VoucherStatus) => {
  switch (status) {
    case VoucherStatus.USED:
      return t('vouchers.voucherstatus.used');
    case VoucherStatus.PENDING_PAYMENT:
      return t('vouchers.voucherstatus.pending_payment');
    case VoucherStatus.ACTIVE:
      return t('vouchers.voucherstatus.active');

    default:
      return '';
  }
};

export const getVoucherStatus = (status: VoucherStatus, translations: any) => {
  switch (status) {
    case VoucherStatus.USED:
      return (
        <Tooltip title={translations('vouchers.voucherstatus.used')} arrow>
          <CheckIcon style={{ color: '#0DF205' }} />
        </Tooltip>
      );
    case VoucherStatus.ACTIVE:
      return (
        <Tooltip title={translations('vouchers.voucherstatus.active')} arrow>
          <HourglassEmptyIcon style={{ color: '#bababa' }} />
        </Tooltip>
      );

    case VoucherStatus.ARCHIVED:
      return (
        <Tooltip title={translations('vouchers.voucherstatus.archived')} arrow>
          <DeleteForever style={{ color: '#bababa' }} />
        </Tooltip>
      );

    default:
      return null;
  }
};
