import moment from 'moment';
import { OrderCustomDetails } from './steps/VoucherSummary';
import { getHoliday } from '../common/getHoliday';
import { HolidayCalendar } from '../api';

export enum PluginType {
  WEEKEND_AND_ISRAELI_HOLIDAYS = 'weekendAndIsraeliHolidays',
  REE_ESCORTS = 'freeEscorts',
}

export const getPluginData = (plugins: any, type: any) => {
  return plugins.find((p: any) => p.type === type);
};
const isWeekendOrHoliday = (date: any) => {
  // calendar hardcoded a  currently no customers require price adiddion no hholidays other the jewish
  return moment(date).day() === 6 || moment(date).day() === 5 || getHoliday(HolidayCalendar.ISR, date);
};
const handleWeekendAndIsraeliHolidaysPlugin = (
  plugins: any,
  price: number,
  date: any,
  shouldUseweekendPlugin?: boolean,
) => {
  // shouldUseweekendPlugin is for vouchers that include weekend.
  const pluginData = getPluginData(plugins, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS);
  if (!pluginData) {
    return price;
  }
  if (isWeekendOrHoliday(date) || shouldUseweekendPlugin) {
    return Math.floor(price * pluginData.multiply);
  }

  return price;
};

const customDetailsHasPlugin = (customDetails: OrderCustomDetails, pluginType: PluginType) => {
  return customDetails[pluginType] === true;
};
const PluginUtils = {
  getPluginData,
  handleWeekendAndIsraeliHolidaysPlugin,
  customDetailsHasPlugin,
  isWeekendOrHoliday,
};

export default PluginUtils;
