import { HelpOutline } from '@mui/icons-material';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import uuid from 'react-uuid';

const BORDER_COLOR = '#00000063';
export const InfoWithTooltip = ({ title }: { title: string }) => {
  const id = uuid();
  return (
    <>
      <Tooltip
        place="top"
        content={title}
        id={`infoWithTooltip-${id}`}
        variant="light"
        border={`1px solid ${BORDER_COLOR}`}
      />
      <HelpOutline style={{ color: BORDER_COLOR }} data-tooltip-id={`infoWithTooltip-${id}`} />
    </>
  );
};
