import { Theme } from '@mui/material/styles/createTheme';
// had to hack this as couldnt generalise component. feels like mui bug. Make a generic step component make icon disapear
export const stepStyle = (theme: Theme) => {
  return {
    '& .MuiStepLabel-root .Mui-completed': {
      color: theme.customTheme.palette.primary, // circle color (COMPLETED)
    },
    // '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    //   color: 'blue', // Just text label (COMPLETED)
    // },
    '& .MuiStepLabel-root .Mui-active': {
      color: theme.customTheme.palette.primary, // circle color (ACTIVE)
    },
    // '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    //   color: 'blue', // Just text label (ACTIVE)
    // },
    '& .MuiStepLabel-root .MuiStepIcon-text': {
      fill: theme.customTheme.palette.primary,
    },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: theme.customTheme.palette.background,
    },
  };
};
