import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import uuid from 'react-uuid';
import { AccountData } from '../../api';

export const StyledCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;

export const WorkshopCircle = ({
  account,
  orderPageId,
  withTooltip,
  language,
}: {
  account: AccountData;
  orderPageId?: string;
  withTooltip?: boolean;
  language: 'he' | 'en';
}) => {
  if (!orderPageId) {
    return <StyledCircle style={{ backgroundColor: '#6577b3' }} />;
  }
  const orderPage = account.experiences[0].order_pages.find((x) => x.id === orderPageId);
  const id = `tooltip-${orderPageId}-${uuid()}`;
  return (
    <>
      <Tooltip
        place="bottom"
        content={withTooltip ? orderPage?.label[language] : ''}
        id={id}
        variant="light"
        border="1px solid black"
      />

      <StyledCircle style={{ backgroundColor: orderPage?.color || '#6577b3' }} data-tooltip-id={id} />
    </>
  );
};
