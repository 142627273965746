import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledToggle = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '50%!important',
  margin: '8px',
  minWidth: 32,
  height: 32,
  marginInlineStart: '8px!important',
  marginInlineEnd: '0!important',
  fontSize: '1rem',
  background: 'white',
  border: '1px solid rgba(0, 0, 0, 0.12)!important',
  color: '#3f4648',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: '#ffffff!important',
    background: theme.palette.primary.main,
  },
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  '&:hover:selected': {
    color: '#ffffff!important',
    background: theme.palette.primary.light,
  },
}));

const ToggleDays = (props) => {
  const { days, setDays } = props;
  const { t } = useTranslation();

  const DAYS = [
    {
      key: 'sunday',
      label: t('days.sunday'),
    },
    {
      key: 'monday',
      label: t('days.monday'),
    },
    {
      key: 'tuesday',
      label: t('days.tuesday'),
    },
    {
      key: 'wednesday',
      label: t('days.wednesday'),
    },
    {
      key: 'thursday',
      label: t('days.thursday'),
    },
    {
      key: 'friday',
      label: t('days.friday'),
    },
    {
      key: 'saturday',
      label: t('days.saturday'),
    },
  ];

  return (
    <ToggleButtonGroup
      size="small"
      arial-label="Days of the week"
      value={days}
      onChange={(event, value) => setDays(value)}>
      {DAYS.map((day, index) => (
        <StyledToggle key={day.key} value={index} aria-label={day.key}>
          {day.label}
        </StyledToggle>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleDays;
