import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

export const VouchersIframe = ({ src }: { src?: string }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const iframeId = uuid();
  const [height, setHeight] = useState(702);
  const [width, setwidth] = useState(isSmallScreen ? 300 : 600);
  useEffect(() => {
    const iframe = document.getElementById(iframeId);

    const handleMessage = (event: any) => {
      if (event.data && event.data.height && iframe) {
        setHeight(event.data.height);
      }
      if (event.data && event.data.width && iframe) {
        setwidth(event.data.width);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return src ? (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item width={width}>
        <iframe
          className="full-height-i  frame"
          id={iframeId}
          src={`${window.origin}/${src}`}
          title="Full Height Iframe"
          width={width}
          height={height}
          style={{ border: 0 }}
        />
      </Grid>
    </Grid>
  ) : null;
};
