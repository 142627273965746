import * as React from 'react';
import { ReactNode, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  '&:hover': {
    color: grey[700],
  },
  textTransform: 'none',
}));
type ConfirmationDialogProps = {
  isOpen: boolean;
  onClose: (actionConfirmed: boolean) => void;
  title: string;
  content?: string | ReactNode;
  ctaText?: string;
  confirmButtonColor?: 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  hideCancelButton?: boolean;
  loadingButton?: boolean;
};

interface ConfirmationDialogButtonProps extends LoadingButtonProps {
  $disabled?: boolean;
  loadingButton?: boolean; // Explicitly define the type for the loading prop
  variant?: 'contained' | 'outlined' | 'text';
}

export const ConfirmationDialogButton = styled(({ loadingButton, ...props }: ConfirmationDialogButtonProps) =>
  loadingButton ? <LoadingButton {...props} /> : <Button {...props} />,
)<ConfirmationDialogButtonProps>``;

const ConfirmationDialog = ({
  hideCancelButton,
  isOpen,
  onClose,
  title,
  content,
  ctaText,
  confirmButtonColor = 'error',
  loadingButton,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: isSmallScreen ? '90%' : '40%' } }}
      open={isOpen}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {content && <DialogContentText id="alert-dialog-description">{content}</DialogContentText>}
      </DialogContent>
      <Grid p={2}>
        <DialogActions>
          {!hideCancelButton && <ColorButton onClick={() => onClose(false)}>{t('cancel')}</ColorButton>}
          <ConfirmationDialogButton
            loading={loading}
            loadingButton={loadingButton}
            variant="contained"
            color={confirmButtonColor}
            onClick={() => {
              if (loadingButton) {
                setLoading(true);
              }
              onClose(true);
              if (loadingButton) {
                setLoading(false);
              }
            }}
            sx={{ boxShadow: 'none', textTransform: 'none' }}
            autoFocus>
            {ctaText || t('delete')}
          </ConfirmationDialogButton>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmationDialog;
