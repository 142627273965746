import { Alert, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OrderPageTypography } from './OrderPageTypography';

type OrderPageErrorProps = {
  message: string;
  isShowingError: boolean;
  setClearError: () => void;
};
export const OrderPageError = ({ message, isShowingError, setClearError }: OrderPageErrorProps) => {
  const [visible, setVisible] = useState(isShowingError);

  useEffect(() => {
    setVisible(isShowingError);

    if (isShowingError) {
      const timeoutId = setTimeout(() => {
        setClearError();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [isShowingError, setClearError]);

  return (
    <Grid container mt={2} justifyContent="center" style={{ display: visible ? 'flex' : 'none' }}>
      <Alert severity="error">
        <OrderPageTypography color="error">{message}</OrderPageTypography>{' '}
      </Alert>
    </Grid>
  );
};
