/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BuildingBlockType {
  TEXT = 'text',
  IMAGE = 'image',
  LINE_BREAK = 'lineBreak',
}
