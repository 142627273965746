import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { DayType } from '../DayType';

type PickerCondensedDayProps = {
  isLegend?: boolean;
  dayType: DayType;
  isSelected?: boolean;
};

export const PickerCondensedDay = styled(Grid)<PickerCondensedDayProps>(({ theme, isLegend, dayType, isSelected }) => ({
  width: isLegend ? '20px' : '60px',
  height: isLegend ? '20px' : '30px',
  borderRadius: '4px',
  gap: '4px',
  textAlign: 'center',
  lineHeight: '30px',
  fontSize: '12px',

  color: isSelected ? theme.customTheme.palette.color : theme.customTheme.palette.background,
  background: isSelected
    ? theme.customTheme.palette.calendarPicker[dayType]?.selected
    : theme.customTheme.palette.calendarPicker[dayType]?.background,
  '&:hover': {
    background: isLegend
      ? theme.customTheme.palette.calendarPicker[dayType]?.background
      : theme.customTheme.palette.calendarPicker[dayType]?.hover,
    cursor: isLegend ? 'cursor' : 'pointer',
  },
}));
