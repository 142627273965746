import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { theme, useLanguage } from '../common/GeneralUtils';

export const WithWebookWidget = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const language = useLanguage();
  const hebrewDir = language === 'he';
  return (
    <Grid container justifyContent="center" gap={isSmallScreen ? 2 : 10} alignItems="center">
      <Grid item>
        <img
          src={'https://webook-static.s3.eu-central-1.amazonaws.com/webook/without.png'}
          width={isSmallScreen ? '120px' : undefined}
          alt={'Without webook'}
        />
      </Grid>
      <Grid item>
        <img
          style={{ transform: hebrewDir ? 'rotate(180deg)' : 'none' }}
          src={'https://webook-static.s3.eu-central-1.amazonaws.com/webook/arrowright.png'}
          width={isSmallScreen ? '30px' : undefined}
          alt={'Just an arrow'}
        />
      </Grid>
      <Grid item>
        <img
          src={'https://webook-static.s3.eu-central-1.amazonaws.com/webook/with.png'}
          width={isSmallScreen ? '120px' : undefined}
          alt={'With webbok'}
        />
      </Grid>
    </Grid>
  );
};
