import moment from 'moment/moment';
import 'moment/locale/he';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from '../CalendarWrapper';
import { useLanguage } from '../../common/GeneralUtils';
import './MonthView.scss';
import { getHoliday } from '../../common/getHoliday';
import { useAuth } from '../../session/InternalAuthProvider';
import { AccountData } from '../../api';

const HEADER_FORMAT = 'ddd';
type MonthViewProps = {
  currentDate: string;
  DayComponent: React.ComponentType<{ date: string }>;
};
export const MonthViewSkeleton = ({ currentDate, DayComponent }: MonthViewProps) => {
  const today = moment().startOf('day').format(DATE_FORMAT);
  const language = useLanguage();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const initDates = () => {
    const dates = [];
    const currentDateMom = moment(currentDate, DATE_FORMAT);
    const daysBefore = currentDateMom.day();
    const daysAfter = 6 - currentDateMom.clone().endOf('month').day();
    let startDate = currentDateMom.clone().subtract(daysBefore, 'days');
    const endDate = currentDateMom.clone().endOf('month').add(daysAfter, 'days');
    while (startDate.isSameOrBefore(endDate)) {
      dates.push(startDate.format(DATE_FORMAT));
      startDate = moment(startDate).add(1, 'days');
    }
    return dates;
  };

  const dates = initDates();

  return (
    <Box className="calendar-container month-view" sx={{ overflowY: 'auto!important', marginTop: '10px' }}>
      <Box className="flex header-row" sx={{ borderInlineStart: '1px solid #e6e6e6' }}>
        {[...Array(7).keys()].map((numOfDay) => {
          return (
            <div
              key={numOfDay}
              className={`cell flex-1${
                moment(currentDate).startOf('week').add(numOfDay, 'days').format(DATE_FORMAT) === today
                  ? ' today-box'
                  : ''
              }`}>
              <Box>
                {language === 'he' && !smallScreen && <span>יום </span>}
                <span>
                  {moment(currentDate).startOf('week').add(numOfDay, 'days').locale(language).format(HEADER_FORMAT)}
                </span>
              </Box>
            </div>
          );
        })}
      </Box>

      <Box className="month-grid">
        {dates.map((date, index) => {
          return (
            <Box
              sx={{ background: moment(date).isSameOrAfter(moment()) ? undefined : '#80808017' }}
              key={index}
              className={`month-box${date === today ? ' today-box' : ''}`}
              maxWidth={isSmallScreen ? '46px' : undefined}
              minHeight={isSmallScreen ? '46px' : '100px'}>
              <Box
                className={`month-text${
                  moment(date).month() !== moment(currentDate).month() ? ' not-this-month' : ''
                }`}>
                <Grid container gap={0.5} alignItems="center">
                  <Grid item>{moment(date).date()}</Grid>
                  <Grid item overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                    <Typography fontSize="12px" color="grey">
                      {getHoliday(account.holiday_calendar, date, language)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <DayComponent date={date} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
