/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CustomDetailTypes {
  STR = 'str',
  LOCATION = 'location',
  BOOL = 'bool',
  RESOURCE_INT = 'resourceInt',
}
