import React, { useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { FileDownload, Group } from '@mui/icons-material';
import '../../../components/EventParticipantTable.scss';
import TablePagination from '@mui/material/TablePagination';
import { useLanguage } from '../../../../common/GeneralUtils';
import RotatingLoader from '../../../../common/ui/RotatingLoader';
import { CustomPersonalDetailData, EnrichedEventData, OrderData } from '../../../../api';
import { useEventOrders } from '../../../swr/useEventOrders';
import { handleDownload } from './SaveToCsv';
import EventParticipantsRow from './EventParticipantsRow';

export const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 8, paddingRight: 8 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

function participantsSort(a: OrderData, b: OrderData) {
  // Check if both 'a' and 'b' have 'checkin' and 'checkin.persons' equal to 'persons'
  const aMatches = a.checkin && a.checkin.persons === a.persons;
  const bMatches = b.checkin && b.checkin.persons === b.persons;

  // Sort based on the matching conditions
  if (aMatches && !bMatches) {
    return 1; // 'a' comes after 'b'
  }
  if (!aMatches && bMatches) {
    return -1; // 'a' comes before 'b'
  }
  const aName = `${a.firstname} ${a.lastname}`;
  const bName = `${b.firstname} ${b.lastname}`;
  return aName.localeCompare(bName);
}

type EventParticipantsTableProps = {
  event: EnrichedEventData;
  orderPage: any;
  setOrder: (order: OrderData) => void;
  setEditOrderIsOpen: (isOpen: boolean) => void;
  setCheckInDialogIsOpen: (isOpen: boolean) => void;
  isInEventMode: boolean;
};
const EventParticipantsTable = ({
  event,
  orderPage,
  setOrder,
  setEditOrderIsOpen,
  setCheckInDialogIsOpen,
  isInEventMode,
}: EventParticipantsTableProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [onlyFreeOrdersChecked, setOnlyFreeOrdersChecked] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoading: isLoadingOrderList, items: ordersList, removeOrder } = useEventOrders({ eventId: event.id });
  // @ts-ignore
  const { t } = useTranslation();
  const language = useLanguage();
  let filteredOrdersList = ordersList.filter(
    (order) =>
      order.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.order_number?.toString() === searchQuery,
  );
  if (onlyFreeOrdersChecked) {
    filteredOrdersList = filteredOrdersList.filter((x) => x.price === 0);
  }

  // Added pagination here as front finds it hard to show more then few 10s of lines and starts hanging
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const shouldEnablePagination = filteredOrdersList.length > 40;
  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };
  if (isLoadingOrderList) {
    return (
      <Box
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Box>
    );
  }
  return (
    <Grid container flexDirection="column" overflow="auto" flexWrap="nowrap" mt={1}>
      <Grid container mb={2} justifyContent="space-between">
        <Grid container gap={2} xs={7}>
          <Grid item maxWidth="350px">
            <TextField
              placeholder={t('orders.searchParticipantsPlaceHolder')}
              size="small"
              variant="outlined"
              value={searchQuery}
              onChange={(e: any) => setSearchQuery(e.target.value)}
              fullWidth
            />
          </Grid>
          {!isInEventMode && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={onlyFreeOrdersChecked}
                    onChange={() => setOnlyFreeOrdersChecked(!onlyFreeOrdersChecked)}
                    color="primary"
                  />
                }
                label={t('orders.onlyFreeOrdersChecked')}
              />
            </Grid>
          )}
        </Grid>

        <IconButton
          onClick={() =>
            handleDownload(event, filteredOrdersList, orderPage.custom_personal_details, orderPage.custom_details)
          }>
          <FileDownload />
        </IconButton>
      </Grid>
      <Grid
        container
        flexDirection="column"
        className="event-participant-table"
        overflow="auto"
        mb={4}
        border="1px solid #555555"
        borderRadius="4px">
        {!filteredOrdersList.length && ordersList.length ? (
          <Grid container m={4}>
            {t('orders.nonfound')}
          </Grid>
        ) : (
          <Grid container className="table-container">
            <StyledTable size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {!isInEventMode && (
                    <>
                      <TableCell sx={{ padding: '0!important', width: '40px' }} />
                      <TableCell sx={{ padding: '0!important', width: '30px' }} />
                      <TableCell sx={{ padding: '0!important', width: '30px' }} />
                    </>
                  )}
                  <TableCell sx={{ padding: '0!important', width: '30px' }} />
                  <TableCell align="left">{t('orders.name')}</TableCell>
                  {orderPage.custom_details &&
                    orderPage.custom_details.map((custom_detail: any) => {
                      return <TableCell align="left">{custom_detail.label[language]}</TableCell>;
                    })}
                  <TableCell>{isSmallScreen ? <Group /> : t('orders.num_persons')}</TableCell>
                  {orderPage.custom_personal_details
                    .filter((x: CustomPersonalDetailData) => !x.hide_from_console)
                    .map((customDetail: CustomPersonalDetailData) => {
                      const label = customDetail.console_label || customDetail.label;
                      return <TableCell align="left">{label[language]}</TableCell>;
                    })}
                  <TableCell align="left">{t('orders.comment')}</TableCell>
                  <TableCell align="left">{t('orders.phone')}</TableCell>
                  <TableCell align="left">{t('orders.price')}</TableCell>

                  <TableCell align="left">{t('orders.status')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="table-body">
                {filteredOrdersList
                  .sort(participantsSort)
                  .slice(
                    shouldEnablePagination ? rowsPerPage * page : 0,
                    shouldEnablePagination ? rowsPerPage * (page + 1) : filteredOrdersList.length,
                  )
                  .map((order, index) => (
                    <EventParticipantsRow
                      order={order}
                      index={index}
                      orderPageCustomPersonalDetails={orderPage.custom_personal_details}
                      orderCustomDetails={orderPage.custom_details}
                      removeOrder={removeOrder}
                      setCheckInDialogIsOpen={setCheckInDialogIsOpen}
                      setOrder={setOrder}
                      setEditOrderIsOpen={setEditOrderIsOpen}
                      isInEventMode={isInEventMode}
                    />
                  ))}
              </TableBody>
            </StyledTable>
          </Grid>
        )}
      </Grid>
      {shouldEnablePagination && (
        <Grid container justifyContent="flex-end">
          <TablePagination
            sx={{ px: 2 }}
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            count={filteredOrdersList.length}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EventParticipantsTable;
