import { Box, Grid } from '@mui/material';
import './OrderBox.scss';
import moment from 'moment';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useDraggable } from '@dnd-kit/core';
import { useAuth } from '../../session/InternalAuthProvider';
import AuthStateUtils from '../../session/AuthStateUtils';
import { OrderData, OrderStatus } from '../../api';
import { getEmployeeNames } from '../CalendarUtils';
import { useCalendar } from '../CalendarContext';
import { CustomDetailRenderer } from './CustomDetailRenderer';

type OrderBoxProps = {
  order: OrderData;
  css?: any;
  viewType: 'month' | 'day';
  openOrder: (event: any, order: OrderData) => void;
  shouldNotDragNDrop?: boolean;
};

const OrderBox = ({ order, css, viewType, openOrder, shouldNotDragNDrop }: OrderBoxProps) => {
  const employeesFeatureEnabled = useFeatureFlagEnabled('employees-feature-enabled');
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: order.id,
  });

  // @ts-ignore
  const { authState } = useAuth();
  // TODO support multiple experiences
  const orderPage = AuthStateUtils.getOrderPage(authState, null, order.order_page_id);

  // @ts-ignore
  const { users, setShouldBlockSwiping } = useCalendar();
  useEffect(() => {
    setShouldBlockSwiping(isDragging);
  }, [isDragging, setShouldBlockSwiping]);
  const hasNoEmployees = order.employee_ids.length === 0;

  const getTimeText = () => {
    if (!order.time) {
      return '';
    }
    return `${moment.unix(order.time).tz('utc').format('H:mm')} - ${moment
      .unix(order.time + order.duration)
      .tz('utc')
      .format('HH:mm')}`;
  };
  const formatEmployees = () => {
    if (order.employee_ids.length) {
      return getEmployeeNames(order.employee_ids, users).map((x) => (
        <Grid key={`employee-${x}`} item color={'black'}>
          <Typography fontSize="12px">{x}</Typography>
        </Grid>
      ));
    }
    return '';
  };

  const shouldHaveRedBorder =
    (hasNoEmployees && employeesFeatureEnabled) || order.status === OrderStatus.PAYMENT_LINK_GENERATED;

  return viewType === 'month' ? (
    <Box
      className="order-box month-order-box"
      onClick={(event: any) => openOrder(event, order)}
      p={0.5}
      sx={{
        backgroundColor: orderPage?.color || '#6577b3',
        border: shouldHaveRedBorder ? '1px solid red' : '1px solid rgb(230, 230, 230)',
      }}>
      <Grid container flexDirection="column" alignItems="center">
        <Grid dir="ltr">{getTimeText()}</Grid>
        <Grid container item justifyContent="center" flexWrap="nowrap" alignItems="center" gap={0.5}>
          {formatEmployees()}
        </Grid>

        <Grid>{`${order.firstname} ${order.lastname}`}</Grid>

        {order.order_number != null && order.order_number !== 0 && <Grid item>{`${order.order_number}#`}</Grid>}
      </Grid>
    </Box>
  ) : (
    <Box
      ref={shouldNotDragNDrop ? undefined : setNodeRef}
      {...(shouldNotDragNDrop ? {} : attributes)}
      {...(shouldNotDragNDrop ? {} : listeners)}
      className="order-box"
      onClick={(event: any) => (isDragging ? undefined : openOrder(event, order))}
      sx={{
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition: isDragging ? 'none' : 'transform 200ms ease',
        zIndex: isDragging ? 1000 : 'auto', // Bring the item to the front while dragging
        width: `calc(${css.width}% - 2px)`,
        left: `${css.left}%`,
        top: `${css.top}px`,
        height: `${css.height - 2}px`,
        backgroundColor: orderPage?.color || '#6577b3',
        border: shouldHaveRedBorder ? '1px solid red' : '1px solid rgb(230, 230, 230)',
      }}>
      <Box className="text-center order-box-inner">
        <Grid container alignContent="center" justifyContent="center" flexDirection="column" gap={0.5} p={0.5}>
          <Grid item>
            <CustomDetailRenderer order={order} orderPage={orderPage} />
            <Grid container item justifyContent="center" flexWrap="nowrap" alignItems="center" gap={0.5} mt={1}>
              {formatEmployees()}
            </Grid>
            <Grid style={{ position: 'absolute', top: '5px', left: '5px', zIndex: 1 }}>{order.persons}</Grid>
          </Grid>
          <Grid item mt={0.5}>{`${order.firstname} ${order.lastname}`}</Grid>
          {order.order_number != null && order.order_number !== 0 && (
            <Grid item mt={-0.5}>{`${order.order_number}#`}</Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderBox;
