import React from 'react';

import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LocationSearchInput = (props) => {
  const { onChange, error } = props;

  // const autoCompleteRef = useRef();
  // const inputRef = useRef();
  const { t } = useTranslation();

  // useEffect(() => {
  //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
  //     componentRestrictions: { country: 'il' },
  //   });
  //
  //   autoCompleteRef.current.addListener('place_changed', async () => {
  //     const place = await autoCompleteRef.current.getPlace();
  //     onChange(place.formatted_address);
  //   });
  // }, []);

  return (
    <TextField
      autoFocus
      size="small"
      error={error}
      helperText={error}
      fullWidth
      variant="outlined"
      placeholder={t('order_page.enter_location')}
      className="location-text-field"
      type="text"
      onChange={onChange}
    />
  );
};

export default LocationSearchInput;
