/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentLinkPaymentStatus {
  PAID = 'paid',
  PENDING_PAYMENT = 'pending_payment',
}
