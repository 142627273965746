import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
  staticBlock?: boolean;
  gridDirection: 'row' | 'row-reverse';
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, children, staticBlock, gridDirection }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    alignItems: 'center',
  };
  if (isSmallScreen) {
    return (
      <Grid container gap={2} flexDirection={gridDirection}>
        {children}
      </Grid>
    );
  }
  return (
    // no editing in small screen
    <Grid
      flexWrap="nowrap"
      ref={setNodeRef}
      {...attributes}
      container
      gap={2}
      flexDirection={gridDirection}
      style={{
        ...style,
        border: '1px solid black',
        padding: '16px',
        borderRadius: '8px',
      }}>
      {!staticBlock && (
        <Grid {...listeners} style={{ cursor: 'grab' }}>
          <Typography fontSize="24px" fontWeight={700}>
            ☰
          </Typography>
        </Grid>
      )}
      {children}
    </Grid>
  );
};
