import React from 'react';
import { Checkbox, FormControlLabel, Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormikState } from 'formik';
import { CustomPersonalDetailData } from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';
import { PersonalDetailsValues } from '../PersonalDetailsStep';

type RenderBooleanDetailProps = {
  customPersonalDetail: CustomPersonalDetailData;
  setFieldValue: (field: string, value: string | boolean) => void;
  values: PersonalDetailsValues;
  touched: FormikState<PersonalDetailsValues>['touched'];
  errors: FormikState<PersonalDetailsValues>['errors'];
};
export const RenderBooleanDetail = ({
  customPersonalDetail,
  setFieldValue,
  values,
  touched,
  errors,
}: RenderBooleanDetailProps) => {
  const language = useLanguage();
  const theme = useTheme();
  // @ts-ignore
  const value = values.custom_personal_details?.[customPersonalDetail.id];
  const boolDetailId = customPersonalDetail.id;
  const error =
    // @ts-ignore
    touched.custom_personal_details?.[boolDetailId] &&
    // @ts-ignore
    errors.custom_personal_details?.[boolDetailId];
  return (
    <Grid container flexDirection="column" item>
      <FormControlLabel
        control={
          <Checkbox
            id={customPersonalDetail.id}
            name={`custom_personal_details.${customPersonalDetail.id}`}
            checked={value}
            onChange={(event) =>
              setFieldValue(`custom_personal_details.${customPersonalDetail.id}`, event.target.checked)
            }
            sx={{
              color: theme.customTheme.palette.border,
              '&.Mui-checked': {
                color: theme.customTheme.palette.primary,
              },
            }}
          />
        }
        label={
          <Grid container columnGap={1}>
            {customPersonalDetail.label[language]}
          </Grid>
        }
      />
      {error && (
        <Grid item color={theme.customTheme.palette.error} mt={-1}>
          <Typography fontSize="12px">{error}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
