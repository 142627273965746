import React from 'react';
import './TopBar.scss';
import { Avatar, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { Home, Logout } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuthInfo, useLogoutFunction } from '@propelauth/react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const UserListItemText = styled(ListItemText)(() => ({
  fontSize: '15px',
}));

const TopBar = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isSidebarOpen, setIsSidebarOpen } = props;
  const { user } = useAuthInfo();
  const logoutFn = useLogoutFunction();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    handleClose();
    void logoutFn(true);
  };

  const titleCase = (string) => {
    if (!string) {
      return '';
    }
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div className="top-bar">
      <div className="icons-container">
        <StyledIconButton
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
          sx={{ display: { sm: 'inline', md: 'none' } }}>
          <Icon>menu</Icon>
        </StyledIconButton>
      </div>

      <div className="flex-1" />
      {user && (
        <div className="user-name-container" onClick={handleClick}>
          {`${titleCase(user?.firstName)} ${titleCase(user?.lastName)}`}
          <Avatar className="user-avatar" sx={{ bgcolor: deepPurple[500] }}>
            {user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}
          </Avatar>
        </div>
      )}
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Home fontSize="small" />
          </ListItemIcon>
          <UserListItemText className="menu-item-text">{t('top_bar.home')}</UserListItemText>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <UserListItemText className="menu-item-text">{t('top_bar.logout')}</UserListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopBar;
