import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import MonkeybookWidgetFooter from './MonkeybookWidgetFooter';
import { WidgetThemeProvider } from '../../order_page/theme/Theme';
import { Language } from '../../api';

export const CustomerFacingWidgetWrapper = ({ children }: { children: React.ReactNode }) => {
  const [languages, setLanguages] = useState<Language[]>([Language.HE, Language.EN]);
  const posthog = usePostHog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const webookTheme = {
    palette: {
      primary: 'black',
      text: {
        primary: 'black',
        button: {
          contained: 'white',
          outlined: 'white',
        },
      },
      background: 'white',
      toggleButton: {
        selectedBg: '#47b406',
      },
      border: '#47b406',
      warning: '#ed6c02',
    },
  };
  useEffect(() => {
    posthog.startSessionRecording({});
  }, [posthog]);
  return (
    <WidgetThemeProvider remoteThemeOverride={webookTheme}>
      <Grid
        container
        display="flex"
        justifyContent="space-around"
        sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        style={{
          height: '100%',
          width: '100%',
        }}>
        <Grid
          container
          flexDirection="column"
          style={{
            background: webookTheme.palette.background,
            height: '100%',
            width: '100%',
            maxWidth: '710px',
          }}
          p={isSmallScreen ? 1 : 0}>
          <Grid
            mt={isSmallScreen ? 0 : 2}
            container
            flexDirection="column"
            style={{
              minHeight: '500px',
              color: webookTheme.palette.text.primary,
              border: `2px solid ${webookTheme.palette.border}`,
              borderRadius: '8px',
            }}
            boxShadow={4}>
            {React.Children.map(children, (child: any) => React.cloneElement(child, { setLanguages }))}
          </Grid>
          <Grid item mt={1}>
            <MonkeybookWidgetFooter languages={languages} />
          </Grid>
        </Grid>
      </Grid>
    </WidgetThemeProvider>
  );
};
