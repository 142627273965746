import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

const StyledCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;

export const DiscountStatusCircle = ({ discountIsActive }: { discountIsActive: boolean }) => {
  const { t } = useTranslation();

  const id = `tooltip-discount-status-${uuid()}`;
  return (
    <>
      <Tooltip
        place="bottom"
        content={discountIsActive ? t('discounts.active') : t('discounts.notActive')}
        id={id}
        variant="light"
        border="1px solid black"
      />

      <StyledCircle style={{ backgroundColor: discountIsActive ? '#4abd00' : '#A6A6A6' }} data-tooltip-id={id} />
    </>
  );
};
