import React, { useState } from 'react';
import { AccordionDetails, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderData, OrderPageData } from '../../../../../api';
import PersonalDetailsStep, { PageContext } from '../../../../../order_page/steps/PersonalDetailsStep';
import { OrderPageIntent } from '../../../../../order_page/OrderPage';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';

type PersonalDetailsPanelProps = {
  order: Partial<OrderData>;
  personalDetailsFormRef: React.RefObject<any>;
  orderPage: OrderPageData;
  createVoucher?: boolean;
  setOrderCustomDetails: (details: any) => void;
  expanded?: AccordionPanelsNames;
  handleChange: any;
};
export const PersonalDetailsPanel = ({
  order,
  personalDetailsFormRef,
  orderPage,
  createVoucher,
  setOrderCustomDetails,
  expanded,
  handleChange,
}: PersonalDetailsPanelProps) => {
  const { t } = useTranslation();
  const [previewName, setPreviewName] = useState(`${order?.firstname} ${order?.lastname}`);
  return (
    <Accordion
      onChange={handleChange}
      expandedPanelName={expanded}
      panelName={AccordionPanelsNames.PersonalDetailsPanel}>
      <AccordionSummary title={t('calendar.edit_order.personal')} summaryComponent={<Grid>{previewName}</Grid>} />
      <AccordionDetails>
        <PersonalDetailsStep
          formRef={personalDetailsFormRef}
          orderPage={orderPage}
          firstname={order.firstname}
          lastname={order.lastname}
          email={order.email}
          phone={order.phone}
          custom_personal_details={order.custom_personal_details}
          orderPageIntent={createVoucher ? OrderPageIntent.GIFT_CARD : OrderPageIntent.BUY_NOW}
          setOrderCustomDetails={setOrderCustomDetails}
          pageContext={PageContext.ADMIN}
          setPreviewName={setPreviewName}
        />
      </AccordionDetails>
    </Accordion>
  );
};
