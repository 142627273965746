import { Grid, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { theme, useLanguage } from '../common/GeneralUtils';

const Title = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Typography style={{ fontSize: isSmallScreen ? '22px' : '40px', fontWeight: 700, paddingTop: '8px' }}>
      {children}
    </Typography>
  );
};
const SubTitle = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <Typography style={{ fontSize: isSmallScreen ? '13px' : '20px', fontWeight: 500 }}>{children}</Typography>;
};

export const TermsComponent = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const language = useLanguage();
  return language === 'he' ? (
    <Grid container padding={isSmallScreen ? 3 : 10} gap={2} flexDirection="column">
      <Title>שימו לב! השימוש באתר כפוף לאמור בתנאי השימוש, ומעיד על הסכמה לתנאי השימוש.</Title>
      <SubTitle> תנאי השימוש נערכו בלשון זכר, אך הם מיועדים לגברים ונשים כאחד.</SubTitle>
      <SubTitle>
        עצם הכניסה לאתר ו/או הורדת מידע ו/או השימוש בשירותים (כהגדרתם להלן), בין באמצעות האתר, ובין באמצעות או כל שירות
        עזר אחר, כפי שיהיה מעת לעת (לעיל ולהלן: “השירותים”), מעיד על הסכמתך לתנאים המפורטים במסמך זה או במסמכים אחרים
        המקושרים למסמך זה (“תנאי השימוש”) וליתר התנאים המופיעים או שיופיעו במסכי האתר בעת השימוש.
      </SubTitle>
      <SubTitle>
        אם אינך מסכימ/ה לאילו מתנאי השימוש ו/או התנאים הנוספים (כהגדרתם להלן) שיופיעו במסכי האתר , הנך מתבקש/ת להימנע
        מלעשות כל שימוש באתר, לרבות בשירותים המוצעים באמצעותם ולרבות באמצעות שירותי העזר.
      </SubTitle>
      <SubTitle>
        תנאי שימוש אלה יחולו על כל שימוש שהוא שיעשה בתחומי מדינת ישראל על ידך, המשתמש/ת (כהגדרתו להלן), באתר ו/או בכל
        אמצעי עזר אחר, לרבות שימוש בשירותים, והם מהווים הסכם משפטי מחייב.
      </SubTitle>
      <Title>1. הפלטפורמה של Monkeybook </Title>
      <SubTitle>
        . הפלטפורמה של Monkeybook – מערכת ניהול הזמנות עבור לקוחות וספקים בכתובת www.monkeybook.io ו/או בכל פלטפורמה
        אחרת שתוקם בעתיד. האתר ו/או כל מערכת אחרת הקשורה אליו מספק פתרונות לשימוש בשירותי ניהול הזמנות עבור לקוחות
        וספקים ומתווכת בין ספקים לבין צורכי שירותים, בין אם כלקוח/ה פרטי/ת ובין אם כלקוח/ה ו/או משתמש/ת עסקי
        (“משתמש/ת/ים” או “את/ה”), המעוניינים לצרוך שירותי ניהול הזמנות (“ספקי השירות/ים”) המעוניינים לספק שירות מצד שני.
        האתר מאפשר למשתמש/ת לנהל הזמנות עבור לקוחות וספקים.
      </SubTitle>
      <SubTitle>
        האתר משמש כמתווך בלבד בין המשתמש/ת וספקי השירותים לא יישאו בכל אחריות ביחס לתכנים ו/או השירותים שסופקו ע”י צדדים
        שלישיים, לרבות ספקי השירותים, תוכנם, מהימנותם, דיוקם, אמינותם וכן לכל נזק, אי נוחות, אובדן או עוגמת נפש שייגרמו
        לך, במישרין או בעקיפין, כתוצאה משימוש במידע המופיע במסכי האתר ו/או משימוש בשירותים.
      </SubTitle>
      <Title>2. שינויים</Title>
      <SubTitle>2.1 שינויים בתנאי השימוש</SubTitle>
      <SubTitle>
        Monkeybook רשאית לבצע שינויים בתנאי השימוש בכל עת, בהתאם לשיקול דעתה הבלעדי. שינויים בתנאי השימוש שהינם בכפוף
        להוראות הדין יכנסו לתוקף מיום פרסומם באתר, בהתאם להוראות הדין כאמור, ללא מתן הודעה מוקדמת. המשך שימושך באתר
        ובשירותים הניתנים בהם לאחר עריכת השינויים בתנאי השימוש כאמור יעידו על הסכמתך וקבלתך את תנאי השימוש החדשים. אם
        אינך מסכימ/ה למי מתנאי השימוש החדשים, עליך להימנע מהמשך שימוש באתר ובשירותים המוצעים בהם.
      </SubTitle>
      <Title>2.2 שינויים באתר</Title>
      <SubTitle>
        Monkeybook רשאית בהתאם לשיקול דעתה הבלעדי, בכל עת, בכל דרך ומכל סיבה שהיא, לשנות או למחוק כל או חלק מהאתר /או
        השירותים, או להטיל חיובים עבור גישה לחלק או לכל החלקים באתר, /או שירותים, עם או בלי הודעה מראש. Monkeybook לא
        תהא אחראית כלפיך ו/או כלפי כל צד שלישי כאשר משנה או מורידה כל או חלק מהאתר ו/או השירותים. ככל שאת/ה מתנגד/ת
        לשינויים שבוצעו או יבוצעו על ידי Monkeybook, הנך מתבקש/ת להימנע מלעשות כל שימוש באתר ו/או השירותים. עצם שימושך
        באתר, ובשירותים המוצעים באמצעותם, לאחר ביצוע השינויים כאמור, יעיד על קבלתך והסכמתך לשינויים.
      </SubTitle>
      <SubTitle>
        Monkeybook רשאית בהתאם לשיקול דעתה הבלעדי, בכל עת, בכל דרך שהיא ומכל סיבה שהיא, לחסום באופן מיידי את גישתך לאתר
        ו/או לשירותים. הנך מאשר/ת ומסכימ/ה כי Monkeybook לא תישא באחריות כלפיך או כלפי צד שלישי, בגין חסימת הגישה שלך
        לאתר ו/או השירותים. הפסקת הגישה שלך לאתר לא תשנה ו/או תבטל כל חבות שתיווצר כתוצאה מהשימוש שלך באתר ו/או ו/או
        בשירותים, בין אם התחייבות כזו היא כלפינו או כלפי צדדים שלישיים.
      </SubTitle>
      <Title>3. כללי שימוש למשתמש/ת</Title>
      <SubTitle>
        כמפורט לעיל, כל משתמש/ת מוזמנ/ת להשתמש באתר ובשירותים, כל עוד הוא/היא מקבל/ת על עצמו/ה את תנאי השימוש. יחד עם
        זאת, Monkeybook תהא רשאית לחסום את גישתך לאתר ו/או לחלק או לכל השירותים ובאתר, לתקופה מוגבלת או בלתי מוגבלת,
        בהתאם לשיקול דעתה הבלעדי ומכל סיבה שהיא מבלי צורך לספק לך נימוק או הסבר. מבלי לגרוע מהאמור, חסימת גישתך לאתר,
        ו/או לשירותים עשויה לחול, בין היתר, במקרים בהם יעלה חשש שהפרת את תנאי השימוש (או כל הוראה אחרת או את הוראות
        הדין, או במקרה בו תפגע/י או תנסה/י לפגוע בהתנהלותה התקינה של האתר או בצד שלישי כלשהו. אם נחסמה גישתך לאתר כאמור,
        לא תהיה/י רשאי/ת לשוב ולהירשם באתר תחת שם משתמש/ת אחר/ת בתקופת ההגבלה, ככל שתהיה כזו. לתשומת לבך כי רישום לאתר
        תוך התחזות לאחר מהווה עבירה פלילית ואסורה על פי חוק.
      </SubTitle>
      <SubTitle>
        בעצם השימוש באתר ו/או בשירותים הנך מצהיר/ה כי תעשה/י באתר ו/או בשירותים המוצעים באמצעותם שימוש אישי בלבד עבורך
        ועבור מי מטעמך, שהשימוש נועד למטרות חוקיות העומדות בכפוף להוראות תנאי השימוש ולהוראות הדין ותמנע/י מכל שימוש
        אסור. הנך מודע/ת לכך כי את/ה האחראי/ת הבלעדי/ת על השימוש באתר.
      </SubTitle>
      <SubTitle>
        אם הנך קטין/ה (מתחת לגיל 18), השימוש באתר מותנה בקבלת אישור האפוטרופוס שלך לתנאי השימוש, לרבות למדיניות הפרטיות
        המפורטת בהם. במידה ולא התקבל אישור מפורש כאמור מהאפוטרופוס שלך, הנך מתחייב/ת להימנע מלעשות כל שימוש באתר, לרבות
        בשירותים המוצעים באמצעותם. כמו כן, מצב שבו הנך מנוע/ה מכל סיבה שהיא להתקשר בהסכמים חוזיים, הנך מתחייב/ת להימנע
        מלעשות כל שימוש באתר, לרבות בשירותים המוצעים באמצעותם.
      </SubTitle>
      <SubTitle>
        כפי שצויין, Monkeybook לא תהא אחראית על כל נזק שהוא שייגרם לך ו/או לספקי השירותים בעקבות ובמהלך מתן שירותי
        התחבורה מעצם היותה כמתווכת בלבד בין המשתמש/ת לספקי השירותים.
      </SubTitle>
      <SubTitle>
        לתשומת לבך כי אי עמידתך בתנאי זה עשויה לחשוף אותך לאחריות פלילית ו/או אזרחית ותהיה/תהיי צפוי/ה לעונשים הקבועים
        בחוק בשל הפרתם.
      </SubTitle>
      <Title>4. ההרשמה לחשבון באתר</Title>
      <SubTitle>
        נכון למועד פרסום תנאי השימוש, השימוש באפליקציה ובאתר ללקוחות פרטיים (כפי שיוגדרו על ידי החברה מעת לעת) (“הלקוח
        הפרטי“) לצורך הזמנת השירותים ולצורך הרשמה איננו כרוך בתשלום. החברה רשאית לגבות בעתיד תשלום בגין ההרשמה ו/או
        שימוש מסוים באתר ו/או בשירותים המסופקים, וככל שהחברה תבקש לעשות זאת תתפרסם על כך הודעה באתר או בכל דרך אחרת. בכל
        מקרה החברה לא תגבה ממך כל תשלום ללא הסכמתך המפורשת לכך.
      </SubTitle>
      <SubTitle>
        משתמש/ת באתר (“החשבון“) יש צורך בהרשמה. במסגרת ההרשמה תתבקש/י למסור מידע אישי שלך שיכלול בין היתר שם, מספר
        טלפון, כתובת דואר אלקטרוני ופרטי אמצעי התשלום (להלן: “פרטי החשבון”). אינך מחויב/ת למסור פרטים אלה אך דע/י לך כי
        לא תוכל/י לעשות שימוש באתר ובחשבון הדורשים הרשמה לשירותים אלה ללא מסירת הפרטים הדרושים כאמור. הנתונים
        שתמסור/תמסרי בעת ההרשמה לחשבון יישמרו במאגר המידע של האתר.
      </SubTitle>
      <SubTitle>
        עליך להקפיד על מסירת פרטים נכונים בעת ההרשמה לאפליקציה ו/או לחשבון ועל עדכון האפליקציה ו/או החשבון בכל שינוי
        בפרטים, שיהיה בהתאם לפירוט במדיניות הפרטיות להלן.
      </SubTitle>
      <SubTitle>
        Monkeybook שומרת לעצמה את הזכות לנקוט כל פעולה כפי שתמצא לנכון ובהתאם לשיקול דעתה הבלעדי, כדי להבטיח את בטחון
        האתר /או החשבון, לרבות וללא הגבלה, בקשת פרטים נוספים בכדי לאשר עסקאות בהן קשור חשבונך וכן סגירת חשבונך לפי
        הצורך.
      </SubTitle>
      <Title>5. תשלום</Title>
      <SubTitle>
        התשלום לספק השירותים, מתאפשר באמצעות כרטיס אשראי כאשר פרטי האשראי יסופקו על ידי המשתמש לצורך ביצוע התשלום עבור
        השירותים.
      </SubTitle>
      <SubTitle>
        Monkeybook רשאית והלקוח מסכים כי התשלום עבור השירותים ייגבה באמצעות אמצעי התשלום שיימסר כאמור ללא צורך בהרשאה
        נוספת.
      </SubTitle>
      <SubTitle>אי תשלום במועד יגרור ריבית פיגורים בגובה 8% שנתי.</SubTitle>
      <Title>6. הגבלת אחריות</Title>
      <SubTitle>
        Monkeybook לא תישא בכל אחריות ביחס לתכנים ו/או השירותים שסופקו ע”י צדדים שלישיים, לרבות ספקי השירותים, תוכנם,
        מהימנותם, דיוקם, אמינותם וכן לכל נזק, אי נוחות, אובדן או עוגמת נפש שייגרמו לך, במישרין או בעקיפין, כתוצאה
        משירותים שיסופקו על ידי צד שלישי.
      </SubTitle>
      <SubTitle>
        Monkeybook תעשה כמיטב יכולתה כדי להפעיל את האתר ומהערכת ההזמנות, האתר והשירותים המוצעים בהם בצורה תקינה, ללא
        תקלות טכניות וללא הפרעות. יחד עם זאת, והיות ופעילות האתר והמערכת מותנית בספקים וצדדים שלישיים, לרבות המפעילים
        הסלולאריים, אינטרנט ספקי השירותים וכדומה, ייתכן והאתר והמערכת לא יהיו תמיד חסינים מפני הפרעות ותקלות בפעילותם
        הסדירה.
      </SubTitle>
      <SubTitle>
        הנך מתחייב מראש כי לא תהיה לך שום טענה, דרישה או תביעה כלשהי כלפי Monkeybook בגין כל תקלה או הפרעה כאמור, לרבות
        בגין כל נזק הנגרם, במישרין או בעקיפין, כתוצאה מהפרעות או תקלות כאמור.מוסכם ומובהר כי Monkeybook לא תישא בכל
        אחריות ו/או חבות בגין כל שיבוש, טעות או השמטה בחומר באתר ו/או בשירותים השונים ו/או בתכנים בהם, ולא יהיו אחראים
        לכל נזק ישיר או עקיף, הנגרם עקב הגישה לאתר למערכת לשירותים והשימוש בהם או עקב מניעה כלשהי מהגישה או השימוש בהם.
      </SubTitle>
      <SubTitle>
        ו/או אלי מרצבסקי ו/א או מי מטעמם, לא יישאו באחריות חוזית או נזיקית לנזק, הפסד או הוצאה – –כלשהם ישירים, עקיפים
        או תוצאתיים שייגרמו ללקוח או לרכושו כתוצאה מתקלה במערכת או מכל סיבה אחרת. Monkeybook ו/או אלי מרצבסקי ו/א או מי
        מטעמם אינם אחראים לתוכנו, שלמותו, מהימנותו או דיוקו של מידע שנמסר ללקוח באמצעות או במסגרת השירותים ולא יישאו בכל
        נזק, אובדן, הוצאה ישירים או עקיפים שיגרמו - - כתוצאה מכל האמור לעיל.
      </SubTitle>
      <SubTitle>
        עוד מובהר, Monkeybook ו/או אלי מרצבסקי ו/א או מי מטעמם אינם אחראיים לנזקים הקשורים ברשת האינטרנט מעצם היותן
        מבוססות על תוכנות, חומרות ורשתות תקשורת, חשופות לסיכונים הטבועים במערכות מסוג זה, לרבות תוכנות מפגעות וירוסים,
        סוסים טרויאנים או רכיבים מזיקים אחרים וכד'(, ציתות לקווי תקשורת, פריצהעל ידי גורמים עוינים, התחזות והונאות
        מקוונות אחרות ומתקפות סייבר. ידוע כי אין אפשרות לחסימה -מוחלטת וייתכנו נזקים והפסדים עקב התממשות איזה מהסיכונים,
        לרבות גילוי או שיבוש מידע המועבר או מוצג בשירות, ובכלל זאת, פעולות לא מורשות בשירות, שיבושים בפעולת המערכות
        ובזמני התגובה שלהן. המשתמש מודע כאמור למגבלת רשת האינטרנט בקשר לאבטחת מידע ופוטר את Monkeybook ו/או אלי מרצבסקי
        ו/א או מי מטעמם ומי מטעמה מכל אחריות בעניין זה.
      </SubTitle>
      <Title>7. תעריפים</Title>
      <SubTitle>Monkeybook שומרת לעצמה את הזכות לעדכן את תעריפי השירותים מעת לעת.</SubTitle>
      <Title>8. תקופת ההסכם וביטול ההסכם</Title>
      <SubTitle>
        תנאי שימוש אלה ישמשו כהסכם מחייב התקף החל ממועד הרישום באתר ו/או חתימת הסכם פרטני לקבלת השירותים. במידה ואת/ה
        מבקש/ת לבטל את ההסכם, עליך להודיע לנו על רצונך לבטל את ההסכם לכתובת בדואר שתפרוסם באתר.
      </SubTitle>
      <SubTitle>
        במידה ואנו נבקש לבטל את ההסכם, נשתדל להודיע לך בדואר אלקטרוני על כוונתנו לעשות כן, ובכל מקרה נוכל לחסום את גישתך
        לאתר באופן מיידי. במקרה זה, הביטול יכנס לתוקף מייד עם חסימת גישתך לאתר.
      </SubTitle>
      <SubTitle>
        בסיום תקופת ההסכם, הינך נדרש/ת לחדול באופן מיידי משימוש באתר ו/או בחשבון ו/או בשירותים כלשהם המסופקים על ידנו.
      </SubTitle>
      <Title>9. תנאים מיוחדים הנוגעים לרכיבי צד שלישי</Title>
      <SubTitle>
        המשתמש מצהיר באופן מפורש כי ידוע לו כי Monkeybook משמשת כמתווכת בלבד ולא תהיה אחראית על שום פעילות, נזק, ביטול
        או יחסים מסחריים המתקיים בין המשתמש לצד שלישי. המשתמש מצהיר באופן מפורש כי הוא מוותר מראש על כל טענה כנגד
        Monkeybook בעניין נזק הנגרם על ידי צד שלישי.
      </SubTitle>
      <Title>10. קניין רוחני</Title>
      <SubTitle>
        המשתמש מצהיר כי ידוע לו כי הקניין הרוחני של פלטפורמת Monkeybook – מערכת ניהול הזמנות עבור לקוחות וספקים בכתובת
        www.monkeybook.io ו/או בכל פלטפורמה אחרת שתוקם בעתיד וכן לרבות מיני פורטלים וכל סוג של פורמט בינארי המכיל מידע,
        שנמצא ברשת האינטרנטית או במרחב הדיגיטלי, הזכות לעשות בו כל שימוש חוקי, כולל סוגי מידע שכזה בין היתר, מסמך
        דיגיטלי, דומניים, שרתים, קובצי אודיו, תמונות או כל סוג מידע דיגיטלי רלוונטי אחר שנמצא ברשת או כל מכשיר אחר שיכול
        לאחסן מידע דיגיטלי. לרבות (אך לא רק), אתרי אינטרנט, שמות מתחם, קבוצות חברים, רשתות חבריות, מידע במערכות, מידע
        בקבוצות, מאגרי מידע, רשימת לקוחות צד שלישי וכדומה, שייך ובבעלות אלי מרצ'בסקי בעלי Monkeybook בלבד, והמשתמש
        מתחייב שלא להעלות כל טענה ו/או דרישה ו/או תביעה בעניין זה ולא לעשות שום שימוש מסחרי בקניין רוחני זה.
      </SubTitle>
      <SubTitle>
        המשתמש מצהיר במפורש כי הינו משתמש בפלטפורמה בלבד, כי עצם השימוש בפלטפורמה Monkeybook לא מקנה או יקנה לו זכות
        כלשהי בקניין הרוחני שלה, ואין ולא תהיה לו כל זכות בקניין הרוחני מכל סוג ומין בפלטפורמת Monkeybook כמתואר לעיל,
        והוא מוותר מראש באופן מפורש סופי ומוחלט על כל טענה בעניין זה.
      </SubTitle>
      <Title>11. איסוף מידע, גישה ושליחת הודעות</Title>
      <SubTitle>
        חלק מהשירותים הניתנים טעונים הרשמה ו/או מסירת מידע אישי, כדוגמת: שם המלא, מספר זהות, כתובת, פרטי אשראי, דרכי
        ההתקשרות עמך, מידע אודות מיקומך וכיוצ"ב.
      </SubTitle>
      <SubTitle>
        המידע לעיל מאפשר לך גם, בין היתר, להתחבר לאזור האישי שלך, בו תידרש להזדהות, בין אם על ידי שם המשתמש והסיסמא שלך
        או בדרך אחרת.
      </SubTitle>
      <SubTitle>
        לפלטפורמה Monkeybook מאגרי מידע רשומים בהם ישמרו הנתונים והפרטים שנמסרו ו/או שיאספו אודות המשתמשים, המיועדים,
        בין היתר, למטרות ניהול ותפעול הפלטפורמה, לרבות המידע והשירותים המוצעים בו ו/או באמצעותו, מתן שירות ללקוחות
        ולמשתמשים באתר, למטרות דיוור ישיר, שיווק, הן למטרות תפעוליות והן למטרות מסחריות ושיווקיות, וכיוצא באלו.
      </SubTitle>
      <SubTitle>
        מובהר בזאת, כי לא חלה על המשתמש כל חובה שבדין למסור את המידע כאמור וכי מסירת המידע תלויה ברצונו ובהסכמתו. יחד עם
        זאת, המשתמש מודע לכך שעל מנת ליצור קשר עם הפלטפורמה ו/או להירשם באתר, מילוי חלק מהשדות מהווה תנאי הכרחי. כמו כן,
        תשומת ליבך כי ללא מסירת פרטים מסוימים, לא נוכל לתפעל ולספק את השירותים המוצעים, חלקם או כולם.
      </SubTitle>
      <SubTitle>
        המשתמש מצהיר כי כל המידע שימסור ו/או יעדכן באתר הפלטפורמה, הינו נכון, אמין ומדויק וכן כי ביצע את יצירת הקשר ו/או
        ההרשמה לאזור האישי ו/או לרשימת הדיוור בשמו ועבור עצמו בלבד ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם
        הורשה לעשות על ידי צד ג' לעשות כן במפורש. המידע אשר מסר המשתמש, אף בטרם מסר פרטים כלשהם, וכן מידע נוסף אשר יצטבר
        אודותיו ופעולותיו באמצעות הפלטפורמה ישמרו במאגרי המידע של הפלטפורמה כמפורט לעיל, ויעשה בו שימוש בהתאם למטרות
        המאגר המפורטות לעיל, בהתאם למדיניות פרטיות זו ולהוראות הדין.
      </SubTitle>
      <SubTitle>
        בעת ביצוע פעולות מסוימות במסגרת השירותים, ייתכן ותידרש העברת מידע גם לספקים, המיישמים את ביצוע הפעולה, ולצדדים
        הקשורים באותה פעולה – דוגמת ספקי מחשוב, חברות סליקה, ספקי שירותי ענן (אחסון המידע) וכיוצא באלו. המידע יישמר אצל
        החברה וכן יועבר לצדדים הרלוונטיים לשם השלמת הפעולות.
      </SubTitle>
      <SubTitle>
        במסגרת השימוש בפלטפורמה אתה עשוי להתבקש לאפשר גישה לשירותים מסוימים, בין אם מהמחשב, הטלפון הנייד או כל מכשיר קצה
        אחר, וזאת לטובת ביצוע פעולות מסוימות. הרשאות אלו כוללות, אך אינן מוגבלות ל:
      </SubTitle>
      <SubTitle> • גישה לשירותי מיקום.</SubTitle>
      <SubTitle> • גישה להודעות טקסט – לצורך אימות זהותך באמצעות קריאת קוד האימות הנשלח אליך ב-SMS מהחברה.</SubTitle>
      <SubTitle>
        • גישה לנתוני זיהוי/זהות מכשיר – עבור יצירת מזהה ייחודי ברישום לשירותים שונים, זיהוי ואימות – ולשם הגנה על
        פרטיותך.
      </SubTitle>
      <SubTitle>
        אינך חייב לאשר את השירותים האלו, וכמו כן תוכל לסגור את הגישה אליהם דרך מכשירך, אולם סגירת הגישה כאמור עשויה
        להוביל לכך שלא תוכל לקבל את השירותים המסופקים, כולם או חלקם.
      </SubTitle>
      <SubTitle>
        החברה רשאית לעשות שימוש במידע לשם קבלת החלטות ביחס אליך או אחרים, בנושאים של מתן המלצות, מניעת הונאות, אבטחת
        השירותים, למטרות תפעוליות, גילוי תרמיות ו/או פעילות בלתי חוקית אחרת, אבטחת השירותים, ויצירת קשר עמך.
      </SubTitle>
      <SubTitle>
        המשתמש מאשר לפלטפורמה לשלוח לו הודעות תפעוליות, התראות ועדכונים בדרכים שונות ובין היתר באמצעות המייל או באמצעות
        מסרונים, כגון מסרון המכיל קוד הזדהות לשם גישה לאתר, או לשם שחזור סיסמא, או הודעות ביחס לפעולותיך, אישורי ביצועי
        פעולות או הוראות ביצוע ושימושים שונים בשירותים.
      </SubTitle>
      <SubTitle>
        במסגרת שירותי הפלטפורמה עשויות להופיע פרסומות ו/או קישורים שונים לאתרים ו/או יישומים חיצוניים. לחיצה על הקישור
        תעשה על דעתך ועל אחריותך בלבד, שכן תצא משירותי הפלטפורמה ותופנה לאתר ו/או יישום חיצוני, ותהיה כפוף לתנאיו, לרבות
        מדיניות הפרטיות שלו. מאותו שלב, לפלטפורמה לא תהיה שליטה על הנעשה במידע שלך, ולפיכך לא תישא באחריות ביחס לכך.
      </SubTitle>
      <Title> 12. שיפוי ופיצוי</Title>
      <SubTitle>
        הנך מתחייב/ת בזאת לשפות ולפצות את Monkeybook או מי מטעמה, בגין כל נזק, הוצאה או הפסד, ישיר או עקיף, לרבות הוצאות
        משפט ושכ”ט עורכי דין, שיגרמו לה בקשר עם הפרת תנאי כלשהו מתנאי השימוש, או ביצוע כל פעולה אחרת בניגוד לדין בקשר עם
        האפליקציה, האתר והשירותים.
      </SubTitle>
      <Title>13. סמכות שיפוט וברירת הדין</Title>
      <SubTitle>
        סמכות השיפוט הבלעדית לדון בכל מחלוקת ו/או סכסוך בקשר עם ה האתר, השירותים, Monkeybook, ו/או תנאי השימוש, תהא
        נתונה לבית המשפט המוסמך במחוז תל אביב, ישראל. הדין החל על כל מחלוקת וסכסוך כאמור בקשר עם האתר הנו הדין הישראלי
        בלבד.
      </SubTitle>
      <Title>14. ויתור על טענות</Title>
      <SubTitle>
        כל שימוש הינו באחריות הבלעדית של המשתמש - ולמשתמש לא תהיה כל טענה, תביעה או דרישה לרבות בגין שירותי ניהול הזמנות
        ו/או שירותים אחרים בגין השימוש באתר ו/או במערכת ההזמנות ו/או בגין כל שימוש אחר.
      </SubTitle>
      <Title>
        השימוש באתר בפועל מהווה הסכמה חד משמעית ובלתי חוזרת מצד המשתמש לתנאי השימוש המפורטים לעיל – ללא כל הגבלה או
        הסתייגות.
      </Title>
    </Grid>
  ) : (
    <Grid container padding={isSmallScreen ? 3 : 10} gap={2} flexDirection="column">
      <Title>
        Notice! The use of the site is subject to the terms of use and indicates agreement to the terms of use.
      </Title>
      <SubTitle>
        The terms of use are written in the male language but are intended for both men and women alike.
      </SubTitle>
      <SubTitle>
        The entry to the site and/or downloading information and/or using services (as defined below), whether through
        the site or through any other assistance service, from time to time (hereinafter: "Services"), indicates your
        agreement to the terms detailed in this document or in other documents linked to this document ("Terms of Use")
        and to the other terms appearing or to be displayed on the site screens during use.
      </SubTitle>
      <SubTitle>
        If you do not agree to any of the terms of use and/or additional terms (as defined below) that will appear on
        the site screens, you are requested to refrain from using the site, including the services offered through it
        and including assistance services.
      </SubTitle>
      <SubTitle>
        These terms of use will apply to any use made within the borders of the State of Israel by you, the user (as
        defined below), on the site and/or any other assistance means, including the use of services, and they
        constitute a binding legal agreement.
      </SubTitle>
      <Title>1. Monkeybook Platform</Title>
      <SubTitle>
        The Monkeybook Platform - order management system for customers and suppliers at www.monkeybook.io and/or any
        other platform that may be established in the future. The site and/or any other system related to it provides
        solutions for using order management services for customers and suppliers and mediates between suppliers and the
        service needs, whether as private customers and as business users ("Users") who want to consume order management
        services ("Service Providers") who want to provide services on the other side. The site allows the user to
        manage orders for customers and suppliers.
      </SubTitle>
      <SubTitle>
        The site serves as a mediator only between the user and the service providers and shall not be responsible for
        any content and/or services provided by third parties, including service providers, their content, credibility,
        accuracy, and any damage, discomfort, loss or distress caused to you, directly or indirectly, as a result of
        using the information on the site screens and/or using the services.
      </SubTitle>
      <Title>2. Changes</Title>
      <SubTitle>2.1 Changes to the Terms of Use</SubTitle>
      <SubTitle>
        Monkeybook reserves the right to make changes to the terms of use at any time, at its sole discretion. Changes
        to the terms of use that are subject to legal provisions will take effect from the date of their publication on
        the site, as provided by law, without prior notice. Your continued use of the site and the services provided
        after making changes to the terms of use as stated will indicate your agreement and acceptance of the new terms
        of use. If you do not agree to the new terms of use, you must refrain from continuing to use the site and the
        services offered therein.
      </SubTitle>
      <SubTitle>2.2 Changes to the Site</SubTitle>
      <SubTitle>
        Monkeybook may, at its sole discretion, at any time, in any way, and for any reason, change or delete all or
        part of the site/services, or impose charges for access to part or all of the site/services, with or without
        prior notice. Monkeybook will not be responsible for you or any third party when it changes or deletes all or
        part of the site/services. If you object to the changes made or to be made by Monkeybook, you are requested to
        refrain from using the site and/or services. The fact that you continue to use the site and/or services, after
        making the changes as stated, will indicate your acceptance and agreement to the changes.
      </SubTitle>
      <SubTitle>
        Monkeybook may, at its sole discretion, at any time, in any way, and for any reason, immediately block your
        access to the site and/or services. You acknowledge and agree that Monkeybook will not be liable to you or any
        third party for blocking your access to the site and/or services. Blocking your access to the site will not
        change or cancel any obligations that arise as a result of your use of the site and/or services, whether such
        obligation is towards us or towards third parties.
      </SubTitle>
      <Title>3. General Use for the User</Title>
      <SubTitle>
        As detailed above, every user is invited to use the site and the services as long as he/she accepts the terms of
        use. However, Monkeybook may block your access to the site and/or to part or all of the services on the site for
        a limited or unlimited period, according to its sole discretion and for any reason without the need to provide
        you with justification or explanation. Without derogating from the above, blocking your access to the site
        and/or services may apply, among other things, in cases where there is concern that a violation of the terms of
        use (or any other instruction or legal provision) will occur or if you harm or attempt to harm the proper
        functioning of the site or any third party. If your access to the site is blocked as mentioned, you are not
        allowed to return and register on the site under another username during the restriction period, if any. It
        should be noted that registration on the site while impersonating someone else constitutes a criminal offense
        and is prohibited by law.
      </SubTitle>
      <SubTitle>
        By using the site and/or services, you declare that you will use the site and/or services for personal use only,
        for yourself and on behalf of your representatives, that the use is intended for legal purposes in accordance
        with the terms of use and legal provisions, and that you will refrain from any prohibited use. You are aware
        that you are solely responsible for the use of the site.
      </SubTitle>
      <SubTitle>
        If you are a minor (under the age of 18), the use of the site is subject to obtaining the approval of your legal
        guardian for the terms of use, including the privacy policy detailed therein. If explicit approval is not
        received from your legal guardian, you are obligated to refrain from any use of the site, including the services
        offered through it. Also, if you are prevented for any reason from making binding agreements, you undertake to
        refrain from any use of the site, including the services provided by them.
      </SubTitle>
      <SubTitle>
        As stated, Monkeybook will not be responsible for any damage caused to you and/or service providers during the
        provision of transportation services as a mere mediator between the user and the service providers.
      </SubTitle>
      <SubTitle>
        Note that your failure to comply with these terms may expose you to criminal and/or civil liability and you may
        be expected to be subject to the penalties set forth in the law for their violation.
      </SubTitle>
      <Title>4. Registration for an Account on the Website</Title>
      <SubTitle>
        As of the date of publication of the terms of use, using the application and the website for private customers
        (as defined by the company from time to time) ("the private customer") for the purpose of ordering services and
        for registration does not involve payment. The company may charge a fee in the future for registration and/or
        specific use of the website and/or provided services, and if the company chooses to do so, it will publish a
        notice on the website or through other means. In any case, the company will not charge you any fees without your
        explicit consent.
      </SubTitle>
      <SubTitle>
        A user of the website ("the account") needs to register. During the registration process, you will be asked to
        provide personal information, including, among other things, your name, phone number, email address, and account
        payment details ("account details"). You are not obligated to provide this information, but be aware that you
        will not be able to use the website and the account requiring registration for these services without providing
        the required details. The data you provide during the account registration will be stored in the site's
        information database.
      </SubTitle>
      <SubTitle>
        You must ensure the accuracy of the information provided during the application and/or account registration, and
        update the application and/or account with any changes in the details, as specified in the privacy policy below.
      </SubTitle>
      <SubTitle>
        Monkeybook reserves the right to take any action it deems necessary to ensure the security of the
        website/account, including, without limitation, requesting additional information to approve transactions
        related to your account and closing your account as needed.
      </SubTitle>
      <Title>5. Payment</Title>
      <SubTitle>
        Payment for the services is possible through credit card when credit card details are provided by the user for
        payment of the services.
      </SubTitle>
      <SubTitle>
        Monkeybook is authorized, and the customer agrees that payment for the services will be made using the provided
        payment methods without requiring additional authorization.
      </SubTitle>
      <SubTitle>Failure to pay on time will incur an annual interest rate of 8%.</SubTitle>
      <Title>6. Limitation of Liability</Title>
      <SubTitle>
        Monkeybook shall not assume any responsibility for the content and/or services provided by third parties,
        including service providers, their content, reliability, accuracy, and any damage, inconvenience, loss, or
        mental anguish caused to you, directly or indirectly, as a result of services provided by a third party.
      </SubTitle>
      <SubTitle>
        Monkeybook will make every effort to operate the website and the order system, the website, and the services
        offered therein properly, without technical malfunctions and interruptions. However, since the operation of the
        website and the system depends on suppliers and third parties, including mobile operators, internet service
        providers, and the like, the website and the system may not always be immune to disruptions and technical
        issues.
      </SubTitle>
      <SubTitle>
        You expressly agree that you will not have any claims or demands against Monkeybook for any such disruption or
        disturbance, including any damage caused directly or indirectly as a result of such disruptions or disturbances.
        It is agreed and clarified that Monkeybook will not assume any responsibility for any disruption, error, or
        omission in the material on the website and/or in the various services and/or in the content therein, and will
        not be responsible for any direct or indirect damage resulting from accessing the website, system, and using
        them, or due to any other cause.
      </SubTitle>
      <SubTitle>
        Eli Marzovski and/or anyone on their behalf shall not assume contractual or tort liability for any damage, loss,
        or expense – direct, indirect, or consequential – incurred by the customer or their property as a result of a
        malfunction in the system or for any other reason. Monkeybook and/or Eli Marzovski and/or anyone on their behalf
        are not responsible for the content, completeness, reliability, or accuracy of information provided to the
        customer through or within the services and will not be liable for any direct or indirect loss incurred as a
        result of the above.
      </SubTitle>
      <SubTitle>
        Furthermore, Monkeybook and/or Eli Marzovski and/or anyone on their behalf are not liable for damages related to
        the internet network per se, as they are based on programs, materials, and communication networks exposed to
        inherent risks in such systems, including malicious software, viruses, Trojans, or other harmful components,
        disruptions to communication lines, intrusion by malicious actors, impersonation and other online fraud, and
        cyber attacks. It is known that there is no absolute protection, and damages and losses may occur due to the
        realization of any of these risks, including disclosure or disruption of transmitted or displayed information in
        the service, and, in general, unauthorized actions in the service, disruptions in the operation of the systems,
        and their response times. The user is aware of the limitations of the internet network regarding information
        security and releases Monkeybook and/or Eli Marzovski and/or anyone on their behalf from any liability in this
        regard.
      </SubTitle>
      <Title>7. Rates</Title>
      <SubTitle>Monkeybook reserves the right to update the service rates from time to time.</SubTitle>
      <Title>8. Agreement Period and Termination of Agreement</Title>
      <SubTitle>
        These terms of use will serve as a binding agreement effective from the date of registration on the website
        and/or signing a private agreement to receive the services. In case you wish to terminate the agreement, you
        must notify us of your intention to terminate the agreement at the address published on the website.
      </SubTitle>
      <SubTitle>
        If we seek to terminate the agreement, we will make an effort to inform you by email of our intention to do so,
        and in any case, we may block your access to the website immediately. In this case, the termination will take
        effect immediately upon blocking your access to the website.
      </SubTitle>
      <SubTitle>
        At the end of the agreement period, you are required to immediately cease using the website and/or the account
        and/or any services provided by us.
      </SubTitle>
      <Title>9. Special Terms Regarding Third-Party Components</Title>
      <SubTitle>
        The user explicitly declares that it is known to him that Monkeybook serves only as an intermediary and will not
        be responsible for any activity, damage, cancellation, or commercial relationship that occurs between the user
        and a third party. The user explicitly waives any claim against Monkeybook for damage caused by a third party.
      </SubTitle>
      <Title>10. Intellectual Property</Title>
      <SubTitle>
        The user declares that he is aware that the intellectual property of the Monkeybook platform – the order
        management system for customers and suppliers at www.monkeybook.io or any other platform established in the
        future, including mini portals and any binary format containing information found on the internet or in the
        digital space – has the right to be legally used, including various types of digital information such as digital
        documents, domains, servers, audio files, images, or any other relevant digital information, whether on the
        internet or on any other device capable of storing digital information. This property solely belongs to
        Monkeybook and the user undertakes not to make any claims, demands, or actions regarding it and not to make any
        commercial use of this intellectual property.
      </SubTitle>
      <SubTitle>
        The user explicitly states that he is a user of the platform only, and the use of the Monkeybook platform does
        not grant or confer any rights to him in its intellectual property. He renounces any claim in this matter
        explicitly and definitively.
      </SubTitle>
      <Title>11. Information Collection, Access, and Messaging</Title>
      <SubTitle>
        Some of the provided services require registration and/or submission of personal information, such as full name,
        ID number, address, credit card details, contact information, location information, and more.
      </SubTitle>
      <SubTitle>
        The above information allows you to connect to your personal area, where identification is required, either by
        your username and password or by other means.
      </SubTitle>
      <SubTitle>
        Monkeybook maintains databases where user data is stored for the purpose of managing and operating the platform,
        including the services offered, customer service, and user support, as well as for direct communication,
        marketing, operational purposes, and commercial and marketing purposes, among others.
      </SubTitle>
      <SubTitle>
        The user acknowledges that there is no obligation to provide the information mentioned, and the provision of
        information is voluntary. However, certain fields must be filled out to use the platform's services, and without
        providing specific information, some or all of the services may not be available.
      </SubTitle>
      <SubTitle>
        The user affirms that all information provided on the platform is accurate and that any contact or registration
        is done for himself and not on behalf of third parties, except in cases explicitly authorized by a third party.
        The information provided by the user, even before disclosing any details, and additional information accumulated
        about him through the platform, will be stored in the platform's databases and used in accordance with the
        specified purposes and privacy policy.
      </SubTitle>
      <SubTitle>
        In certain service operations, the transfer of information to providers implementing the action and to parties
        related to the action, such as computer suppliers, clearing companies, cloud service providers, etc., may be
        required. The information will be stored by the company and may be transferred to relevant parties for action
        completion.
      </SubTitle>
      <SubTitle>
        As part of platform usage, you may be asked to enable access to specific services, whether from a computer,
        mobile phone, or any other endpoint device, for specific operations. These permissions may include, but are not
        limited to:
      </SubTitle>
      <SubTitle> • Access to location services.</SubTitle>
      <SubTitle>
        • Access to text messages – for identity verification through reading the verification code sent to you via SMS
        by the company.
      </SubTitle>
      <SubTitle>
        • Access to device identification/information – for creating a unique identifier for registration in different
        services, identification, and verification purposes, and to protect your privacy.
      </SubTitle>
      <SubTitle>
        You are not obligated to grant these permissions, and you can disable access through your device. However,
        disabling access may result in the inability to receive some or all of the provided services.
      </SubTitle>
      <SubTitle>
        The company may use the information to make decisions regarding you or others, such as providing
        recommendations, preventing fraud, ensuring service security, operational purposes, detecting abnormalities, or
        other unlawful activities, ensuring service security, and establishing contact with you.
      </SubTitle>
      <SubTitle>
        The user authorizes the platform to send operational messages, notifications, and updates through various means,
        including email or messages, such as a message containing an identification code for site access, password
        recovery, messages regarding your actions, performance confirmation, and various uses of the services.
      </SubTitle>
      <SubTitle>
        Advertisements and/or links to external websites and/or applications may appear as part of platform services.
        Clicking on the link is solely at your discretion and responsibility, as you will exit the platform services and
        be directed to an external site and/or application, subject to its terms, including its privacy policy. The
        platform has no control over the information you provide to external sites, and therefore, it is not responsible
        for it.
      </SubTitle>
      <Title>12. Indemnification and Compensation</Title>
      <SubTitle>
        You hereby undertake to indemnify and compensate Monkeybook or its representatives for any damage, expense, or
        loss, direct or indirect, including legal expenses and attorney's fees, caused to it in connection with the
        violation of any terms of use or any other action contrary to the law in relation to the application, the
        website, and the services.
      </SubTitle>
      <Title>13. Jurisdiction and Governing Law</Title>
      <SubTitle>
        The exclusive jurisdiction to adjudicate any dispute or conflict regarding the website, services, Monkeybook,
        and/or terms of use shall be given to the competent court in the Tel Aviv district, Israel. Israeli law shall
        apply to any dispute or conflict in connection with the website, and no other law shall apply.
      </SubTitle>
      <Title>14. Waiver of Claims</Title>
      <SubTitle>
        Any use is the sole responsibility of the user, and the user shall have no claim, demand, or requirement,
        including for order management services and/or other services related to the use of the site and/or the order
        system and/or any other use.
      </SubTitle>
      <Title>
        The actual use of the site constitutes a clear and irrevocable agreement by the user to the terms of use
        detailed above, without limitation or reservation.
      </Title>
    </Grid>
  );
};
