import { Grid } from '@mui/material';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Button from '@mui/material/Button';
import uuid from 'react-uuid';

export const getUTMParameters = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: `${params.get('utm_source')}hc`,
    utm_medium: `${params.get('utm_medium')}hc`,
    utm_campaign: `${params.get('utm_campaign')}hc`,
    utm_term: `${params.get('utm_term')}hc`,
    utm_content: `${params.get('utm_content')}hc`,
  };
};
export const GATest = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-WJ3R4QJ4',
    });
    // ReactGA.initialize('G-79MYBV72RB');
  }, []);
  const utmParams = getUTMParameters();

  const onClick = () => {
    // ReactGA.event({
    //   category: 'your category',
    //   action: 'your action',
    //   label: 'your label', // optional
    //   value: 99, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: 'xhr', // optional, beacon/xhr/image
    // });
    //
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: uuid(),
          affiliation: 'Speculo',
          value: 300,
          tax: '',
          shipping: '',
          currency: 'ILS',
          coupon: '',
          items: [
            {
              item_name: 'name',
              item_id: 'id',
              price: 300,
              category: '',
              variant: '',
              quantity: 2,
            },
          ],
        },
        customer: {
          customer_id: '',
          customer_first_name: 'customer_first_name',
          customer_last_name: 'customer_last_name',
          customer_email: 'customer_email',
          customer_phone: 'customer_phone',
          customer_country: 'customer_country',
          customer_state: 'customer_state',
          customer_zipcode: 'customer_zipcode',
        },
        ...utmParams,
      },
    });
  };
  return (
    <Grid container justifyContent="center" padding={8}>
      <Button onClick={onClick} variant="outlined">
        Go Davai
      </Button>
    </Grid>
  );
};
