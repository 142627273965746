import './DetailsStep.scss';
import React from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { SpecificEventError, VoucherError } from '../OrderUtils';

const ErrorBox = ({ error, orderPageLabel }: { error: VoucherError | SpecificEventError; orderPageLabel: string }) => {
  const { t } = useTranslation();
  let text;
  switch (error) {
    case VoucherError.EXPIRED:
      text = t('vouchers.expired');
      break;
    case VoucherError.ALREADY_USED:
      text = t('vouchers.already_used');
      break;
    case VoucherError.NOT_FOUND:
      text = t('vouchers.not_found');
      break;
    case VoucherError.ARCHIVED:
      text = t('vouchers.archived');
      break;
    case SpecificEventError.NO_MORE_TICKETS:
      text = t('vouchers.no_tickets');
      break;
    default:
      text = t('vouchers.not_found');
  }
  return (
    <div className="order-page">
      <Box className={'order-page-box order-page-box-padding'} boxShadow={4}>
        <div className="page-title">{orderPageLabel}</div>

        <Grid container flexDirection="column" my={10} alignItems="center" alignContent="center">
          <Alert
            className="error-box"
            severity="error"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography variant="h5">{text}</Typography>
          </Alert>
        </Grid>
      </Box>
    </div>
  );
};

export default ErrorBox;
