import { Box, Grid, Paper, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import './Orders.scss';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Typography from '@mui/material/Typography';
import TabPanel from '../common/ui/TabPanel';
import { ArchivedOrders } from './ArchivedOrders';
import { AllOrders } from './AllOrders';
import { DailyDashboard } from './DailyDashboard';

const Orders = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>Monkeybook - {t('main_sidebar.orders')}</title>
      </Helmet>
      <Grid container mb={2} p={isSmallScreen ? 2 : undefined}>
        <DailyDashboard />
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container p={isSmallScreen ? 2 : undefined}>
          <Typography variant="h5">{t('orders.allOrders')}</Typography>
        </Grid>
        <Grid container>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={t('orders.orders')} />
            <Tab label={t('orders.archived')} />
          </Tabs>
        </Grid>
      </Box>
      <TabPanel value={value} index={0}>
        <Paper elevation={2} className="paper-tab-content">
          <AllOrders />
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper elevation={2} className="paper-tab-content">
          <ArchivedOrders />
        </Paper>
      </TabPanel>
    </Box>
  );
};

export default Orders;
