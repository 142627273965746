import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDraggable } from '@dnd-kit/core';
import { BuildingBlockType } from '../../../api';

type DraggableAddItemProps = { item: BuildingBlockType; isDisabled: boolean };
export const DraggableAddItem = ({ item, isDisabled }: DraggableAddItemProps) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: item,
  });
  return (
    <Grid>
      <Grid
        ref={isDisabled ? undefined : setNodeRef}
        {...(isDisabled ? {} : attributes)}
        {...(isDisabled ? {} : listeners)}
        sx={{
          transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
          transition: isDragging ? 'none' : 'transform 200ms ease',
        }}>
        <Button variant="outlined" fullWidth disabled={isDisabled} key={item} sx={{ minWidth: '140px' }}>
          <Grid container alignItems="baseline" gap={1}>
            <Typography fontSize="20px" fontWeight={700}>
              ☰
            </Typography>
            <Typography>{t(`emailTemplates.add${item}`)}</Typography>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};
