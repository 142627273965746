import './OrderPage.scss';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Divider, Fade, Grid, Step, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { usePostHog } from 'posthog-js/react';
import { Gift } from 'react-feather';
import { styled } from '@mui/material/styles';
import { includes } from 'lodash';
import DetailsStep from './steps/DetailsStep';
import { useOrderPage } from './OrderPageContext';
import PersonalDetailsStep from './steps/PersonalDetailsStep';
import DateAndTimeStep from './steps/dateAndTimeStep/DateAndTimeStep';
import { CodesService, OrderService, PaymentFormType, PaymentsService, VoucherService, VoucherStatus } from '../api';
import TimeUtils from '../common/TimeUtils';
import PaymentStep from './steps/PaymentStep';
import { useLanguage } from '../common/GeneralUtils';
import OrderUtils from './OrderUtils';
import TTLLocalStorage from '../common/TTLLocalStorage';
import ConfirmationDialog from '../common/ui/ConfimationDialog';
import MonkeybookWidgetFooter from '../common/ui/MonkeybookWidgetFooter';
import VoucherSummary from './steps/VoucherSummary';
import VoucherRedemptionSummary from './steps/VoucherRedemptionSummary';
import PluginUtils, { getPluginData, PluginType } from './PluginUtils';
import ErrorBox from './steps/ErrorBox';
import { StyledStepLabel } from './StyledStepLabel';
import { OrderPageTypography } from './OrderPageTypography';
import { OrderPageButton } from './OrderPageButton';
import { stepStyle } from './StepStyle';
import { priceFormatter } from './utils';
import { getHoliday } from '../common/getHoliday';
import { OrderPageError } from './OrderPageError';
import { SpecificEventSummary } from './steps/SpecificEventSummary';

const StyledDivider = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    '&::before': {
      borderTop: `thin solid ${theme.customTheme.palette.border}`,
    },
    '&::after': {
      borderTop: `thin solid ${theme.customTheme.palette.border}`,
    },
  },
  '& .MuiDivider-wrapper': {
    fontSize: 16,
  },
}));
export type PersonalDetails = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  custom_personal_details?: object;
  referral_source?: string;
};

export enum OrderPageIntent {
  BUY_NOW = 'BUY_NOW',
  GIFT_CARD = 'GIFT_CARD',
  REDEEM_GIFT_CARD = 'REDEEM_GIFT_CARD',
  MANAGE_BOOKING = 'MANAGE_BOOKING',
  // VOUCHER = 'VOUCHER',// JUST MONEY NOT YET SUPPORTED
  // PUNCHES = 'PUNCHES',// MORE THEN ONE USE NOT YET SUPPORTED
}

// this is used in case voucher is not valid for weekend and being redeemed
const calculateShouldHideWeekends = (orderPageState: any) => {
  // we care only if orderpage has weekend  plugin
  const pluginData = getPluginData(orderPageState.orderPage.plugins, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS);
  if (!pluginData) {
    return false;
  }
  const customDetails = orderPageState.details.custom_details;
  return (
    !PluginUtils.customDetailsHasPlugin(customDetails, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS) &&
    orderPageState.orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD
  );
};

enum OrderPageErrors {
  DATE_AND_TIME_NOT_SELECTED = 'dateAndTimeNotSelected',
  SAVE_ORDER_EXCEPTION = 'saveOrderException',
  PHONE_NUMBER_SAVE_ORDER_EXCEPTION = 'phoneNumberSaveOrderException',
}

const OrderPage = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();
  const navigate = useNavigate();
  // @ts-ignore
  const {
    // @ts-ignore
    reloadDates,
    // @ts-ignore
    orderPageState,
    // @ts-ignore
    setOrderPageState,
    // @ts-ignore
    setActiveStep,
    // @ts-ignore
    setOrderCustomDetails,
    // @ts-ignore
    setActiveStepAndOrderPageIntent,
    // @ts-ignore
    setTime,
  } = useOrderPage();
  const formRef = useRef();
  const [detailsStepErrors] = useState({});
  const [error, setError] = useState<OrderPageErrors | undefined>(undefined);
  const [activeStep, setLocalActiveStep] = useState<number>(orderPageState?.activeStep || 0);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const specificEvent = orderPageState?.specificEvent;
  const orderPageLanguages = orderPageState?.account.customer_facing_widgets_languages;
  const [shouldDiasbleNext, setShouldDiasbleNext] = useState(false);
  const language = useLanguage();
  const posthog = usePostHog();
  const { voucher, voucherError, orderPageIntent, orderPage, specificOrderPage, specificEventError } = orderPageState;
  const personalDetailsRef = useRef<HTMLDivElement | null>(null);
  const shouldShowDuration = orderPage.show_duration;
  const persons = orderPageState.details?.persons || 1;
  const shouldShowVoucherFlow = voucher && voucher.status === VoucherStatus.ACTIVE;

  const elementRef = useCallback((node: any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      const iframeHeight = document.body.offsetHeight;
      window.parent.postMessage({ height: iframeHeight }, '*');
    });
    resizeObserver.observe(node);
  }, []);

  let sessionTimeout: NodeJS.Timeout | null = null;
  const shouldHideWeekends = calculateShouldHideWeekends(orderPageState);

  const initSessionExpiredTimeout = (ttl?: number) => {
    if (!ttl) {
      ttl = OrderUtils.ORDER_PAGE_TTL * 1000;
    }

    sessionTimeout = setTimeout(() => {
      // todo make it nicer then alert.....
      // eslint-disable-next-line no-alert
      alert('Session expired. please reload');
      TTLLocalStorage.removeByPrefix('order-page-state-');
      window.location.reload();
    }, ttl);
  };

  useEffect(() => {
    void (() => {
      if (orderPageState.activeStep === 3) {
        const ttl = TTLLocalStorage.getTTLLeft(`order-page-state-${orderPageState.orderPage.id}`) || 0;
        if (ttl > 0) {
          initSessionExpiredTimeout(ttl);
        }
      }
    })();
  }, []);

  const moveToNextStep = (numberOfSteps = 1, intent?: OrderPageIntent) => {
    setLoading(false);
    if (intent) {
      setActiveStepAndOrderPageIntent(activeStep + numberOfSteps, intent);
    } else {
      setActiveStep(activeStep + numberOfSteps);
    }

    setLocalActiveStep((prevActiveStep) => prevActiveStep + numberOfSteps);
  };

  const handleDetailsDateTimeSelectionNext = (activeStep: number, intent?: OrderPageIntent) => {
    if (Object.keys(detailsStepErrors).length > 0) {
      return;
    }
    if (
      intent !== OrderPageIntent.GIFT_CARD &&
      (orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD || activeStep === 1) &&
      (!orderPageState.dateAndTimeDetails.date || !orderPageState.dateAndTimeDetails.time)
    ) {
      setError(OrderPageErrors.DATE_AND_TIME_NOT_SELECTED);
    } else if (orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD) {
      moveToNextStep(2, intent);
    } else {
      moveToNextStep(1, intent);
    }
  };

  const getPaymentForm = async (order: any) => {
    return PaymentsService.getPaymentForm({
      language,
      account_id: orderPageState.account.id,
      experience_id: orderPageState.experience.id,
      order_page_id: orderPageState.orderPage.id,
      entity_id: order.id,
      payment_form_type:
        orderPageIntent === OrderPageIntent.GIFT_CARD ? PaymentFormType.VOUCHER : PaymentFormType.ORDER,
    });
  };
  const clearSessionExpiredTimeout = () => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
  };
  const handleReset = () => {
    TTLLocalStorage.removeByPrefix('order-page-state-');
    clearSessionExpiredTimeout();
    window.location.reload();
  };
  const saveOrder = async (personalDetails?: any) => {
    if (orderPageIntent === OrderPageIntent.GIFT_CARD) {
      return VoucherService.createVoucher({
        language,
        ...(personalDetails || orderPageState.personalDetails),
        account_id: orderPageState.account.id,
        experience_id: orderPageState.experience.id,
        order_page_id: orderPageState.orderPage.id,
        ...orderPageState.details,
        can_spam: orderPageState.canSpam,
      });
    }
    try {
      const order = await OrderService.saveOrder({
        language,
        account_id: orderPageState.account.id,
        experience_id: orderPageState.experience.id,
        order_page_id: orderPageState.orderPage.id,
        details: orderPageState.details,
        date_and_time_details: orderPageState.dateAndTimeDetails,
        personal_details: personalDetails || orderPageState.personalDetails,
        can_spam: orderPageState.canSpam,
      });
      if (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD) {
        await CodesService.applyCode({
          entity_id: order.id,
          code: voucher.code,
        });
      }
      return order;
    } catch (e) {
      console.error(e);
      setError(OrderPageErrors.SAVE_ORDER_EXCEPTION);
      TTLLocalStorage.removeByPrefix('order-page-state-');
      clearSessionExpiredTimeout();
      setActiveStep(0);
      setLocalActiveStep(0);
      reloadDates();
      setLoading(false);
    }
    return undefined;
  };

  const handlePersonalDetailsNext = async () => {
    const data = formRef;
    if (!data.current) {
      return;
    }
    // @ts-ignore
    await data.current.submitForm();
    const { isValid } = data.current;
    if (isValid) {
      setLoading(true);
      // @ts-ignore
      const values = data.current.values as PersonalDetails;
      const personalDetails = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone.replace('-', ''),
        custom_personal_details: values.custom_personal_details,
        referral_source: values.referral_source,
      };
      let url = '';
      let order = null;
      if (orderPageState.account.has_payment_integration) {
        order = await saveOrder(personalDetails);
        if (orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD) {
          if (orderPageIntent === OrderPageIntent.BUY_NOW) {
            initSessionExpiredTimeout(); // no timeouts in guftcards as it's not blocking calendar.
          }
          try {
            url = await getPaymentForm(order);
          } catch (e: any) {
            console.error(e);
            setError(
              e?.body?.includes('717')
                ? OrderPageErrors.PHONE_NUMBER_SAVE_ORDER_EXCEPTION
                : OrderPageErrors.SAVE_ORDER_EXCEPTION,
            );
            TTLLocalStorage.removeByPrefix('order-page-state-');
            clearSessionExpiredTimeout();
            setActiveStep(0);
            setLocalActiveStep(0);
            reloadDates();
            setLoading(false);
            return;
          }
        }
      }
      setOrderPageState({
        ...orderPageState,
        activeStep: activeStep + 1,
        personalDetails,
        paymentUrl: url,
        order_id: order?.id,
        returningCustomer: order?.returning_customer || false,
      });
      setLoading(false);
      setLocalActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePaymentFormNext = async () => {
    setLoading(true);
    await saveOrder();
    navigate('/order/completed');
    setLoading(false);
  };
  const handleNext = async (intent?: OrderPageIntent) => {
    if (activeStep === 0 || activeStep === 1) {
      if (personalDetailsRef.current) {
        personalDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      handleDetailsDateTimeSelectionNext(activeStep, intent);
    } else if (activeStep === 2) {
      await handlePersonalDetailsNext();
    } else if (activeStep === 3) {
      await handlePaymentFormNext();
    }
  };

  const handleBack = () => {
    if (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD) {
      setActiveStepAndOrderPageIntent(activeStep - 1, OrderPageIntent.REDEEM_GIFT_CARD);
      setLocalActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      if (activeStep === 2) {
        // reset intent as we wont know if they want a voucher or what
        setActiveStepAndOrderPageIntent(activeStep - 1, OrderPageIntent.BUY_NOW);
      } else {
        setActiveStep(activeStep - 2);
      }
      setLocalActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
  };

  const { price } = orderPageState.details;
  const { date } = orderPageState.dateAndTimeDetails;

  const formattedDuration = useMemo(
    () => TimeUtils.formatDuration(orderPageState.details.duration, t),
    [orderPageState.details.duration, language, t],
  );
  const nextButtonText = () => {
    switch (activeStep) {
      case 0:
        return t('next');
      case 1:
        return t('next');
      case 2:
        return orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD ? t('redeem') : t('next');
      case 3:
        return t('finish');
      default:
        return t('next');
    }
  };
  const priceSummery = () => {
    return (
      activeStep === 0 &&
      orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && (
        <Grid container display="flex" flexDirection="column" xs={isSmallScreen ? undefined : 6}>
          {shouldShowDuration && (
            <Grid item gap={1} sx={{ display: { xs: 'none', sm: 'flex' } }} flexDirection="row">
              <OrderPageTypography>{t('order_page.duration')}</OrderPageTypography>
              <OrderPageTypography bold>{formattedDuration}</OrderPageTypography>
            </Grid>
          )}
          <Grid item gap={1} display="flex" flexDirection="row">
            <OrderPageTypography>{t('order_page.perTicket')}</OrderPageTypography>
            <OrderPageTypography bold>
              {priceFormatter(+(price / persons).toFixed(0), orderPageState.account)}
            </OrderPageTypography>
          </Grid>
          {getHoliday(orderPageState.account.holiday_calendar, date) && (
            <Grid item gap={1} display="flex" flexDirection="row">
              <OrderPageTypography bold>
                {getHoliday(orderPageState.account.holiday_calendar, date, language)}
              </OrderPageTypography>
            </Grid>
          )}
          <Grid item gap={1} display="flex" flexDirection="row">
            <OrderPageTypography>{t('order_page.total')}</OrderPageTypography>
            <OrderPageTypography bold>{priceFormatter(price, orderPageState.account)}</OrderPageTypography>
          </Grid>
        </Grid>
      )
    );
  };
  if (voucherError) {
    return <ErrorBox orderPageLabel={orderPageState.orderPage.label[language]} error={voucherError} />;
  }
  if (specificEventError) {
    return <ErrorBox orderPageLabel={orderPageState.orderPage.label[language]} error={specificEventError} />;
  }

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      sx={{ '::-webkit-scrollbar': { display: 'none' } }}
      style={{
        height: '100%',
        width: '100%',
      }}
      ref={personalDetailsRef}>
      <Grid
        ref={elementRef}
        container
        flexDirection="column"
        style={{
          borderRadius: '12px',
          background: theme.customTheme.palette.background,
          height: '100%',
          width: '100%',
          maxWidth: '710px',
        }}
        className="order-page-wrapper">
        {orderPageState.account.name === 'Speculo' && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-148720460-1" />
            <script src="/scripts/speculo-gtag.js" />
            <script src="/scripts/speculo-facebook.js" />
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=935698493466004&ev=PageView&noscript=1"
                alt="facebook link"
              />
            </noscript>
          </>
        )}
        {orderPageState.account.id === '64e246f85f3e1733728cb75f' && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-SZ6K3X7MKK" />
            <script src="/scripts/yaar-gtag.js" />
            <script src="/scripts/yaar-facebook.js" />
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=935698493466004&ev=PageView&noscript=1"
                alt="facebook link"
              />
            </noscript>
          </>
        )}

        <Helmet>
          <title>WeBook - {orderPageState.orderPage.label[language]}</title>
        </Helmet>

        <Grid
          px={isSmallScreen ? 2 : 4}
          container
          flexDirection="column"
          className={`order-page-box${activeStep !== 0 ? ' order-page-box-padding' : ''}`}
          style={{
            maxWidth: '650px',
            color: theme.customTheme.palette.text.primary,
            border: `2px solid ${theme.customTheme.palette.border}`,
          }}
          boxShadow={4}>
          <div className="page-title">{orderPageState.orderPage.label[language]}</div>

          <Grid container mt={isSmallScreen ? 0 : 3} mb={1}>
            <Grid container hidden={isSmallScreen} display={isSmallScreen ? 'none' : 'flex'} flexDirection="column">
              <Stepper activeStep={activeStep}>
                <Step key={1} sx={stepStyle(theme)}>
                  <StyledStepLabel name={t('order_page.step1')} />
                </Step>
                {orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD && (
                  <Step key={2} sx={stepStyle(theme)}>
                    <StyledStepLabel name={t('order_page.step2')} />
                  </Step>
                )}
                <Step key={3} sx={stepStyle(theme)}>
                  <StyledStepLabel name={t('order_page.step3')} />
                </Step>
                <Step key={4} sx={stepStyle(theme)}>
                  <StyledStepLabel name={t('order_page.step4')} />
                </Step>
              </Stepper>
            </Grid>
            <OrderPageError
              isShowingError={includes(
                [OrderPageErrors.SAVE_ORDER_EXCEPTION, OrderPageErrors.PHONE_NUMBER_SAVE_ORDER_EXCEPTION],
                error,
              )}
              message={
                error === OrderPageErrors.PHONE_NUMBER_SAVE_ORDER_EXCEPTION
                  ? t('orderPageErrors.saveExceptionNonIsraeliPhoneNumber')
                  : t('orderPageErrors.saveException')
              }
              setClearError={() => setError(undefined)}
            />
            {specificEvent && activeStep === 0 && <SpecificEventSummary specificEvent={specificEvent} />}
            <Grid
              container
              wrap="nowrap"
              mt={activeStep === 3 ? 0 : 5}
              flexDirection="column"
              className="current-step-container">
              <Grid item hidden={activeStep !== 0}>
                {shouldShowVoucherFlow ? (
                  <VoucherSummary voucher={voucher} />
                ) : (
                  <DetailsStep errors={detailsStepErrors} />
                )}
              </Grid>
              <Grid
                item
                py={3}
                hidden={
                  !isSmallScreen ||
                  orderPageState.specificOrderPage?.onlyCouples ||
                  orderPageState.specificOrderPage?.onlyVouchers ||
                  specificEvent
                }>
                {priceSummery()}
              </Grid>
              <Grid
                hidden={
                  activeStep > 1 ||
                  (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD && activeStep !== 1) ||
                  specificOrderPage?.onlyVouchers ||
                  specificEvent
                }>
                <DateAndTimeStep
                  setShouldDisableNext={setShouldDiasbleNext}
                  availableDates={orderPageState.availableDates}
                  setTime={setTime}
                  shouldHideWeekends={shouldHideWeekends}
                  orderPageIntent={orderPageIntent}
                  orderPage={orderPage}
                  date={orderPageState.dateAndTimeDetails.date}
                  time={orderPageState.dateAndTimeDetails.time}
                  isLoadingDates={orderPageState.isLoadingDates}
                  waitingListUrl={`/waiting-list/${orderPageState.account.id}/${orderPageState.experience.id}/${
                    orderPage.id
                  }/${orderPageState.details?.persons || 1}`}
                  orderDetails={orderPageState.details}
                  setDateTimeAndPrice={({
                    newDate,
                    newTime,
                    newPrice,
                  }: {
                    newDate?: string | null;
                    newTime?: number | null;
                    newPrice?: number;
                  }) =>
                    setOrderPageState({
                      ...orderPageState,
                      dateAndTimeDetails: {
                        date: newDate,
                        time: newTime,
                      },
                      details: {
                        ...orderPageState.details,
                        price: newPrice || orderPageState.details.price,
                      },
                    })
                  }
                  noMinDate={specificOrderPage.noMinDate}
                  troubleshoot={specificOrderPage.troubleshoot}
                  specificEvent={specificEvent}
                />
              </Grid>

              <Grid item hidden={activeStep !== 2}>
                <PersonalDetailsStep
                  formRef={formRef}
                  orderPage={orderPageState.orderPage}
                  setOrderCustomDetails={setOrderCustomDetails}
                  orderPageIntent={orderPageIntent}
                />
              </Grid>

              {activeStep === 3 && orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && (
                <PaymentStep orderPageIntent={orderPageIntent} />
              )}
              {activeStep === 3 && orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD && <VoucherRedemptionSummary />}
            </Grid>
            <Grid container className="middle-box" mb={2} justifyContent="center">
              {error === OrderPageErrors.DATE_AND_TIME_NOT_SELECTED &&
                activeStep < 2 &&
                (!orderPageState.dateAndTimeDetails.date || !orderPageState.dateAndTimeDetails.time) && (
                  <Fade in timeout={1000}>
                    <Alert sx={{ width: '230px', marginTop: '16px' }} severity="error">
                      <OrderPageTypography color="error">{t('order_page.date_error')}</OrderPageTypography>
                    </Alert>
                  </Fade>
                )}
            </Grid>
            {activeStep !== 3 && orderPageState.account.has_payment_integration && (
              <Grid
                container
                px={0.5}
                mx={activeStep !== 0 && !isSmallScreen ? 4 : 0}
                className="footer-container"
                display="flex"
                justifyContent="space-between"
                direction={isSmallScreen && activeStep === 0 ? 'column' : 'row'}>
                {activeStep !== 0 && (
                  <OrderPageButton variant="outlined" color="inherit" disabled={activeStep === 0} onClick={handleBack}>
                    <OrderPageTypography color="button.outlined">{t('back')}</OrderPageTypography>
                  </OrderPageButton>
                )}
                {priceSummery()}

                <Grid
                  item
                  xs={isSmallScreen ? undefined : 6}
                  flexDirection="column"
                  display={specificOrderPage?.onlyVouchers && activeStep === 0 ? 'none' : 'flex'}
                  alignItems="flex-end">
                  <OrderPageButton
                    disabled={orderPageState.isLoadingDates || shouldDiasbleNext || specificOrderPage.troubleshoot}
                    loadingButton
                    sx={{
                      width: '150px',
                    }}
                    loading={loading}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      posthog.startSessionRecording({});
                      posthog.capture('Button clicked', {
                        name: 'Start order',
                        orderPageId: orderPageState.orderPage.id,
                      });
                      void handleNext();
                    }}
                    className="next-button">
                    <OrderPageTypography variant="h6" color="button.contained">
                      {nextButtonText()}
                    </OrderPageTypography>
                  </OrderPageButton>
                </Grid>
              </Grid>
            )}

            {activeStep === 3 &&
              orderPageState.account.has_payment_integration &&
              orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && (
                <Box className="footer-container" sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                  <OrderPageButton
                    variant="outlined"
                    color="inherit"
                    onClick={() => setIsResetDialogOpen(true)}
                    sx={{ mr: 1 }}>
                    <OrderPageTypography>{t('reset')}</OrderPageTypography>
                  </OrderPageButton>
                </Box>
              )}
          </Grid>
          {activeStep === 0 && orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && !specificEvent && (
            <Grid container flexDirection="column" gap={2} my={3}>
              {!specificOrderPage?.onlyVouchers && (
                <StyledDivider sx={{ borderTop: 'red' }}>{t('order_page.divider')}</StyledDivider>
              )}
              <Grid
                item
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                alignItems="center"
                gap={2}>
                {!specificOrderPage?.onlyVouchers && <OrderPageTypography>{t('vouchersremark')}</OrderPageTypography>}
                <OrderPageButton
                  sx={{
                    width: '150px',
                  }}
                  startIcon={<Gift size={16} color={theme.customTheme.palette.text.button.outlined} />}
                  variant="outlined"
                  onClick={() => {
                    posthog.startSessionRecording({});
                    posthog.capture('Button clicked', {
                      name: 'Start voucher',
                      orderPageId: orderPageState.orderPage.id,
                    });
                    void handleNext(OrderPageIntent.GIFT_CARD);
                  }}
                  className="next-button"
                  disabled={specificOrderPage.troubleshoot || orderPageState.isLoadingDates}>
                  <OrderPageTypography variant="h6" color="button.outlined">
                    {specificOrderPage?.onlyVouchers ? t('purchaseVoucher') : t('purchasegift')}
                  </OrderPageTypography>
                </OrderPageButton>
              </Grid>
            </Grid>
          )}
        </Grid>

        <MonkeybookWidgetFooter languages={orderPageLanguages} />

        <ConfirmationDialog
          title={t('order_page.reset_dialog.title')}
          ctaText={t('reset')}
          isOpen={isResetDialogOpen}
          onClose={(actionConfirmed: any) => {
            setIsResetDialogOpen(false);
            if (actionConfirmed) {
              handleReset();
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OrderPage;
