import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { whatsAppLinkFormatter } from '../common/whatsAppLinkFormatter';

const WHATSAPP_COLOR = '#47b406';
type WhatsAppButtonProps = {
  cta?: string;
  messageText?: string;
  secondaryWhatsappButton?: boolean;
  color?: string;
};

function WhatsAppButton({ cta, messageText, secondaryWhatsappButton, color }: WhatsAppButtonProps) {
  const { t } = useTranslation();

  const phoneNumber = '972526149234';
  const whatsappUrl = whatsAppLinkFormatter(phoneNumber, messageText || 'Hey Webook!');
  return (
    <Button
      fullWidth
      disableElevation
      disableRipple
      size="medium"
      variant={secondaryWhatsappButton ? 'outlined' : 'contained'}
      sx={
        color
          ? { border: '1px solid black' }
          : secondaryWhatsappButton
          ? undefined
          : {
              backgroundColor: { WHATSAPP_COLOR },
              border: '0px solid black',
              '&:hover': {
                backgroundColor: '#235904',
              },
            }
      }
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer">
      <WhatsAppIcon sx={{ mr: 1, color: secondaryWhatsappButton ? undefined : 'white' }} />
      <Typography color={color || (secondaryWhatsappButton ? undefined : 'white')}>
        {cta || t('marketplace.header.whatsappcta')}
      </Typography>
    </Button>
  );
}

export default WhatsAppButton;
