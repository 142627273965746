import React from 'react';
import { TextFieldProps, useTheme } from '@mui/material';
import { StyledTextField } from './StyledTextField';

export const ThemedTextField = (props: TextFieldProps) => {
  const theme = useTheme();

  const textColor = theme.customTheme.palette.text.primary;
  const { lineColor } = theme.customTheme.palette;

  return <StyledTextField textColor={textColor} lineColor={lineColor} variant="standard" {...props} />;
};
