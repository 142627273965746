import moment from 'moment/moment';
import 'moment/locale/he';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Moment } from 'moment';
import CalendarDay from '../CalendarDay';
import { CalendarItems, DATE_FORMAT } from '../CalendarWrapper';
import HoursBordersHeader from './components/HoursBordersHeader';
import { AllDayCalendarCell } from '../AllDayCalendarCell';
import { CurrentTimeDivider } from './components/CurrentTimeDivider';
import { useAuth } from '../../session/InternalAuthProvider';
import { getHoliday } from '../../common/getHoliday';
import { useCalendarLocalPreferences } from './useCalendarLocalPreferences';
import { useLanguage } from '../../common/GeneralUtils';
import { EnrichedEventData, OrderData } from '../../api';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';

const HEADER_FORMAT_SMALL = 'ddd DD';
type MultipleDaysViewProps = {
  currentDate: string;
  calendarItems: CalendarItems;
  numOfDays: number;
  openOrder: (event: any, order: OrderData) => void;
  openEvent: (event: EnrichedEventData) => void;
};

const MultipleDaysView = ({ currentDate, calendarItems, numOfDays, openOrder, openEvent }: MultipleDaysViewProps) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { readOnly } = UseAuthPermissions();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { authState } = useAuth();
  // @ts-ignore
  const account = authState.account as AccountData;
  const today = moment().startOf('day').format(DATE_FORMAT);
  const {
    preferences,
    toggleShowUnavailability,
    toggleShouldShowOnlyCurrentUser,
    toggleShouldShowShifts,
    toggleShowUserUnavailability,
  } = useCalendarLocalPreferences();

  useEffect(() => {
    const lastScrollPosition = localStorage.getItem('webook_lastCalendarScroll');
    // @ts-ignore
    document.getElementById('calendar-container').scrollTo({ top: lastScrollPosition });
  }, []);
  const handleScroll = (event: any) => {
    localStorage.setItem('webook_lastCalendarScroll', event.currentTarget.scrollTop);
  };

  const isNumOfDayToday = (numOfDay: number) => moment(currentDate).add(numOfDay, 'days').format(DATE_FORMAT) === today;
  const cellBackgroundColor = (date: Moment) => {
    if (numOfDays === 7) {
      return date.weekday() % 2 === 0 ? 'white' : '#f8f8f8';
    }
    if (numOfDays === 1) {
      return 'white';
    }
    const formattedDate = date.subtract(1, 'day').format(DATE_FORMAT);
    return formattedDate === currentDate ? '#f8f8f8' : 'white';
  };
  return (
    <>
      <Grid container>
        <FormGroup>
          <Grid container my={isSmallScreen ? 1 : undefined} mx={isSmallScreen ? 1 : undefined}>
            <FormControlLabel
              control={<Checkbox style={{ padding: isSmallScreen ? 1 : undefined }} />}
              checked={preferences.shouldShowUnavailability}
              label={t('calendarOptions.hideUnavailability')}
              onChange={toggleShowUnavailability}
            />

            <FormControlLabel
              control={<Checkbox style={{ padding: isSmallScreen ? 1 : undefined }} />}
              checked={preferences.shouldShowOnlyCurrentUser}
              label={t('calendarOptions.showMyWorkshops')}
              onChange={toggleShouldShowOnlyCurrentUser}
            />

            <FormControlLabel
              control={<Checkbox style={{ padding: isSmallScreen ? 1 : undefined }} />}
              checked={preferences.shouldShowShifts}
              label={
                account.shifts_as_employee_unavailability
                  ? t('calendarOptions.showEmployeeUnavliability')
                  : t('calendarOptions.showShifts')
              }
              onChange={toggleShouldShowShifts}
            />
            {(!account.should_hide_user_availability_from_employees || !readOnly) && (
              <FormControlLabel
                control={<Checkbox style={{ padding: isSmallScreen ? 1 : undefined }} />}
                checked={preferences.shouldShowUserAvailabilities}
                label={t('calendarOptions.showUserAvailabilities')}
                onChange={toggleShowUserUnavailability}
              />
            )}
          </Grid>
        </FormGroup>
      </Grid>

      <div className="calendar-container" id="calendar-container" onScroll={handleScroll}>
        <Box className="sticky-part">
          <div className="shadow-box" />
          {numOfDays !== 1 && (
            <div className="flex header-row">
              <Box
                className="cell first-column"
                sx={{
                  borderBottom: 'none',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}>
                <Box
                  sx={{
                    borderBottom: '1px solid #e6e6e6',
                    width: '12px',
                    height: '50px',
                    boxSizing: 'border-box',
                  }}
                />
              </Box>
              {[...Array(numOfDays).keys()].map((numOfDay) => {
                const date = moment(currentDate).add(numOfDay, 'days');
                const dateCloned = date.clone();
                return (
                  <Grid
                    minWidth="24px"
                    pt={1}
                    container
                    flexDirection="column"
                    key={`header-${numOfDay}`}
                    sx={{
                      backgroundColor: dateCloned.format(DATE_FORMAT) === today ? '#D2D1D1' : cellBackgroundColor(date),
                      fontWeight: dateCloned.format(DATE_FORMAT) === today ? 800 : undefined,
                    }}
                    className={`cell flex-1`}
                    justifyContent="flex-start">
                    <Grid container justifyContent="center">
                      {dateCloned.clone().locale(language).format(HEADER_FORMAT_SMALL)}
                    </Grid>
                    <Typography fontSize="12px" color="grey">
                      {getHoliday(account.holiday_calendar, dateCloned.format(DATE_FORMAT), language)}
                    </Typography>
                  </Grid>
                );
              })}
            </div>
          )}
          {numOfDays === 1 && (
            <div className="flex header-row">
              <Box
                className="cell first-column"
                sx={{
                  borderBottom: 'none',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}>
                <Box
                  className="first-column"
                  sx={{
                    borderBottom: 'none',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                  }}>
                  {numOfDays === 1 && (
                    <Grid container flexDirection="column" alignItems="center">
                      <Grid item>{moment(currentDate).format('ddd')}</Grid>
                      <Grid item>{moment(currentDate).format('DD')}</Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
              <Grid minWidth="24px" pt={1} container justifyContent="center" alignItems="center">
                <Typography fontSize="12px" color="grey">
                  {getHoliday(account.holiday_calendar, moment(currentDate).format(DATE_FORMAT), language)}
                </Typography>
              </Grid>
            </div>
          )}
          <div className="flex body-row">
            <Box className=" first-column" />
            {[...Array(numOfDays).keys()].map((numOfDay) => {
              return (
                <div
                  key={numOfDay}
                  style={{ backgroundColor: cellBackgroundColor(moment(currentDate).add(numOfDay, 'days')) }}
                  className={` flex-1`}>
                  <AllDayCalendarCell
                    unavailabilities={
                      calendarItems.unavailabilities[moment(currentDate).add(numOfDay, 'days').format(DATE_FORMAT)]
                    }
                  />
                </div>
              );
            })}
          </div>
        </Box>

        <Box style={{ position: 'relative' }}>
          <div className="flex body-row last-body-row">
            <div className="cell first-column cell">
              <HoursBordersHeader tz={account.timezone} />
            </div>
            {[...Array(numOfDays).keys()].map((numOfDay) => {
              return (
                <div
                  key={numOfDay}
                  className={`cell flex-1`}
                  style={{
                    backgroundColor: cellBackgroundColor(moment(currentDate).add(numOfDay, 'days')),
                    borderInlineStart: '1.5px solid black',
                  }}>
                  {isNumOfDayToday(numOfDay) && <CurrentTimeDivider />}
                  <CalendarDay
                    shouldShowUnavailability={preferences.shouldShowUnavailability}
                    shouldShowOnlyCurrentUser={preferences.shouldShowOnlyCurrentUser}
                    shouldShowShifts={preferences.shouldShowShifts}
                    shouldShowUserAvailabilities={preferences.shouldShowUserAvailabilities}
                    dateToRender={moment(currentDate).add(numOfDay, 'days').format(DATE_FORMAT)}
                    items={calendarItems}
                    openOrder={openOrder}
                    openEvent={openEvent}
                  />
                </div>
              );
            })}
          </div>
        </Box>
      </div>
    </>
  );
};
export default MultipleDaysView;
