import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { FormikErrors, FormikHandlers, FormikState } from 'formik';
import { useAuth } from '../session/InternalAuthProvider';
import { AccountData, OrderPageData } from '../api';
import { useLanguage } from '../common/GeneralUtils';
import { WorkshopCircle } from '../common/ui/WorkshopCircle';

// I dont have a clue how this chatgpt type sorcery works
type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof NonFunctionProperties<ObjectType> &
    (string | number)]: NonFunctionProperties<ObjectType>[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<NonFunctionProperties<ObjectType>[Key]>}`
    : `${Key}`;
}[keyof NonFunctionProperties<ObjectType> & (string | number)];

type OrderPagesMultiSelectProps<T extends object> = {
  values: T;
  errors: FormikErrors<T>;
  touched: FormikState<T>['touched'];
  handleChange: FormikHandlers['handleChange'];
  orderPagesFieldName: NestedKeyOf<T>;
  account: AccountData;
  exceptOrderPageId?: string;
};

export const OrderPagesMultiSelect = <T extends Record<string, any>>({
  values,
  errors,
  touched,
  handleChange,
  orderPagesFieldName,
  account,
  exceptOrderPageId,
}: OrderPagesMultiSelectProps<T>) => {
  const { authState } = useAuth();
  const language = useLanguage();

  const getNestedValue = <T extends Record<string, any>>(obj: T, key: string): any => {
    return key.split('.').reduce((o, k) => (o ? o[k] : undefined), obj);
  };
  const fieldValue = getNestedValue(values, orderPagesFieldName);
  const fieldError = getNestedValue(errors, orderPagesFieldName);
  const fieldTouched = getNestedValue(touched, orderPagesFieldName);
  // @ts-ignore
  const orderPages = authState.account?.experiences[0].order_pages.filter((x) => x.id !== exceptOrderPageId) || [];

  return (
    <Grid container flexDirection="column">
      <FormGroup>
        {orderPages.map((page: OrderPageData) => (
          <FormControlLabel
            key={page.id}
            control={
              <Checkbox
                name={String(orderPagesFieldName)}
                value={page.id}
                checked={fieldValue.includes(page.id)}
                onChange={handleChange}
              />
            }
            label={
              <Grid container flexWrap="nowrap" gap={1} alignContent="baseline">
                <Grid pt={0.4}>
                  <WorkshopCircle account={account} orderPageId={page.id} language={language} />
                </Grid>
                <Typography>{page.label[language]}</Typography>
              </Grid>
            }
          />
        ))}
      </FormGroup>
      {fieldError && fieldTouched && <Typography color="error">{fieldError}</Typography>}
    </Grid>
  );
};
