import { useTranslation } from 'react-i18next';
import { AccordionDetails, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import OrderDetails from '../../OrderDetails';
import { useEditOrder } from '../../EditOrderContext';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';
import { usePriceRenderer } from '../../../../calendar-items/usePriceRenderer';

type ExperienceDetailPanelProps = {
  detailsStepErrors: Record<string, any>;
  isLoadingDates: boolean;
  expanded?: AccordionPanelsNames;
  handleChange: any;
};
export const ExperienceDetailPanel = ({
  detailsStepErrors,
  isLoadingDates,
  expanded,
  handleChange,
}: ExperienceDetailPanelProps) => {
  // @ts-ignore
  const { editOrderState } = useEditOrder();
  const { t } = useTranslation();
  const { SlimPrice } = usePriceRenderer({
    orderId: editOrderState.order_id,
    discountId: editOrderState.discountId,
    initialPrice: editOrderState.details.price,
    returningCustomerFromOrderId: editOrderState.returningCustomerFromOrderId,
    extraPaymentLines: editOrderState.extraPaymentsLines,
  });
  return (
    <Accordion
      onChange={handleChange}
      expandedPanelName={expanded}
      panelName={AccordionPanelsNames.ExperienceDetailPanel}>
      <AccordionSummary
        title={t('calendar.edit_order.experience')}
        summaryComponent={
          <Grid container gap={2}>
            <Typography variant="body1">
              {t('calendar.edit_order.participants', { number: editOrderState.details.persons })}
            </Typography>
            <Grid gap={1}>
              <Typography variant="body1">
                {t('calendar.edit_order.price', { number: editOrderState.details.price })}
              </Typography>
              <SlimPrice />
            </Grid>
          </Grid>
        }
      />
      <AccordionDetails>
        <OrderDetails errors={detailsStepErrors} isLoadingDates={isLoadingDates} />
      </AccordionDetails>
    </Accordion>
  );
};
