import { AccountData, Currency } from '../api';

type DeepOmitNull<T> = {
  [K in keyof T]: T[K] extends object ? DeepOmitNull<T[K]> : T[K];
};

export function priceFormatterFromCurrency(price: number, currency: Currency) {
  const locale = currency.toLowerCase() === 'ils' ? 'il-IL' : 'en-US';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  });
  return formatter.format(price);
}

export function deepOmitNull<T>(obj: T): DeepOmitNull<T> {
  if (!obj) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => [key, value !== null && typeof value === 'object' ? deepOmitNull(value) : value]),
  ) as DeepOmitNull<T>;
}

export function priceFormatter(price: number, account: AccountData) {
  return priceFormatterFromCurrency(price, account.currency);
}

export function getCurrencySymbol(account: AccountData) {
  const locale = account.currency.toLowerCase() === 'ils' ? 'il-IL' : 'en-US';

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: account.currency,
    maximumFractionDigits: 0,
  });

  return formatter.format(0).replace(/[0\s,]/g, '');
}
