import { Box, Stack, ThemeProvider, useTheme } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import './NumberSelector.scss';
import { CacheProvider } from '@emotion/react';
import merge from 'lodash/merge';
import { cacheLtr, ltrTheme } from '../GeneralUtils';
import { OrderPageButton } from '../../order_page/OrderPageButton';

const NumberSelector = (props) => {
  const { value, setValue, min, max, disabled } = props;
  const theme = useTheme();
  const changeVal = (val) => {
    if (val > max) {
      val = max;
    }
    if (val < min) {
      val = min;
    }
    setValue(val);
  };

  return (
    <CacheProvider value={cacheLtr}>
      <ThemeProvider theme={merge(theme, ltrTheme)}>
        <Stack spacing={1} direction="row" className="number-selector">
          <OrderPageButton
            className="number-button"
            onClick={() => changeVal(value - 1)}
            variant="contained"
            disabled={disabled}
            $disabled={disabled}>
            <Remove style={{ color: theme.customTheme.palette.background }} />
          </OrderPageButton>

          <Box className="value-box">{value}</Box>

          <OrderPageButton
            className="number-button"
            onClick={() => changeVal(value + 1)}
            variant="contained"
            disabled={disabled}>
            <Add style={{ color: theme.customTheme.palette.background }} />
          </OrderPageButton>
        </Stack>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default NumberSelector;
