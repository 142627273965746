const getConditionResult = (condition, orderState) => {
  if (!condition) return true;

  if (condition.data_type === 'custom') {
    const value = orderState.details.custom_details[condition.name];
    if (condition.condition_type === 'ne') {
      return value !== condition.value;
    }
  }
  return true;
};

const UiObjectUtils = {
  getConditionResult,
};

export default UiObjectUtils;
