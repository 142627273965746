import useSWR from 'swr';
import { AccountService } from '../../api';

export const useGetEmailTemplatesParts = () => {
  const { mutate, data, isLoading } = useSWR(
    { opId: 'getEmailTemplatesParts' },
    async () => AccountService.getEmailTemplatesParts(),
    {
      revalidateOnFocus: false,
    },
  );
  return { refetchTemplates: mutate, emailTemplatesParts: data, isLoading };
};
