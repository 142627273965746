import { Field, FieldProps, FormikHelpers } from 'formik';
import React from 'react';
import { Grid } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { OrderPageUpdate } from '../OrderPageSettingsPage';

type FormTimePickerProps = {
  disabled: boolean;
  fieldName: string;
  setFieldValue: FormikHelpers<OrderPageUpdate>['setFieldValue'];
  initialValue?: number;
};

export const FormTimePicker = ({ disabled, setFieldValue, fieldName, initialValue }: FormTimePickerProps) => {
  return (
    <Grid container width={70} justifyContent="flex-start">
      <Grid item>
        <Field name={fieldName}>
          {({ field }: FieldProps<string | number>) => {
            const changedField = initialValue !== field.value;
            return (
              <MobileTimePicker
                {...field}
                sx={{
                  '&& .MuiInputBase-root .MuiInputBase-input': {
                    height: '10px',
                    fontWeight: changedField ? '700' : undefined,
                    color: changedField ? '#47b406' : undefined,
                  },
                }}
                value={moment.unix(+field.value).tz('utc')}
                ampm={false}
                disabled={disabled}
                minutesStep={15}
                onChange={(newValue) => setFieldValue(fieldName, newValue!.hours() * 3600 + newValue!.minutes() * 60)}
              />
            );
          }}
        </Field>
        <StyledErrorMessage name={fieldName} />
      </Grid>
    </Grid>
  );
};
