import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderPageData } from '../api';

export const MaxStationsRestrictionTable = ({ orderPage }: { orderPage?: OrderPageData }) => {
  const { t } = useTranslation();

  const cellStyle = {
    padding: '2px',
    width: '20px',
    height: '20px',
    border: '1px solid #bfbfbf',
    borderRadius: '10px',
  };
  const personToStationsCapacities =
    orderPage?.availability_settings.restrictions_obj?.max_stations_restriction?.person_to_stations_capacities;

  if (!personToStationsCapacities) {
    return null; // Handle the case when the array is not available
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={cellStyle}>{t('restrictions.maxtable.key')}</TableCell>
              {personToStationsCapacities.map((_, index) => (
                <TableCell sx={cellStyle} key={index}>
                  {index}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={cellStyle}>{t('restrictions.maxtable.value')}</TableCell>
              {personToStationsCapacities.map((value, index) => (
                <TableCell sx={cellStyle} key={index}>
                  <Grid container justifyContent="center">
                    {value}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
