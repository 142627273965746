import React from 'react';
import UnavailabilityBox from './calendar-items/UnavailabilityBox';
import OrderBox from './calendar-items/OrderBox';
import EventBox from './calendar-items/EventBox';
import { CalendarItems } from './CalendarWrapper';
import { EnrichedEventData, OrderData } from '../api';

type MonthCalendarCellProps = {
  calendarItems: CalendarItems;
  dateToRender: string;
  openOrder: (event: any, order: OrderData) => void;
  openEvent: (event: EnrichedEventData) => void;
};
const MonthCalendarCell = ({ calendarItems, dateToRender, openOrder, openEvent }: MonthCalendarCellProps) => {
  if (!calendarItems) {
    return null;
  }
  let orders = calendarItems.orders[dateToRender] || [];
  orders = orders.map((o) => ({ ...o, entityType: 'order' }));
  let events = calendarItems.events[dateToRender] || [];
  events = events.map((e) => ({ ...e, entityType: 'event' }));
  // so it can be sorted with all other events
  const unavailabilities = calendarItems.unavailabilities[dateToRender] || [];
  unavailabilities?.map((x) => ({
    ...x,
    time: x.start_time,
  }));
  // todo: why the fuck did i do it
  let allItems = JSON.parse(JSON.stringify(orders));
  allItems = [...allItems, ...events, ...unavailabilities];
  allItems = allItems.sort((a: any, b: any) => a.time - b.time);

  return (
    <>
      {allItems.map((item: any) => {
        if (item.entityType === 'order') {
          return <OrderBox key={item.id} order={item as OrderData} viewType="month" openOrder={openOrder} />;
        }
        if (item.entityType === 'event') {
          return <EventBox key={item.id} event={item} viewType="month" openEvent={openEvent} />;
        }
        return <UnavailabilityBox key={item.id} unavailability={item} viewType="month" />;
      })}
    </>
  );
};

export default MonthCalendarCell;
