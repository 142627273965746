/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum HolidayCalendar {
  ISR = 'isr',
  ILS = 'ils',
  US = 'us',
}
