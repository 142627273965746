/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TipType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}
