import { Alert, Fade, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderPageTypography } from '../../../OrderPageTypography';
import { TimeSlotResponseOut } from '../../../../api';

type TroubleshootProps = {
  timeSlotResponseOut?: TimeSlotResponseOut;
};

export const Troubleshoot = ({ timeSlotResponseOut }: TroubleshootProps) => {
  const { t } = useTranslation();
  if (!timeSlotResponseOut) {
    return null;
  }
  return (
    <Fade in timeout={1000}>
      <Alert sx={{ marginTop: '16px' }} severity="warning">
        <Grid container flexDirection="column">
          <OrderPageTypography color="warning">
            {t(`troubleshoot.${timeSlotResponseOut.timeDisableReason}`)}
          </OrderPageTypography>
          {timeSlotResponseOut.timeDisabledDetails?.time && (
            <Grid container gap={1}>
              <OrderPageTypography color="warning">{t(`troubleshoot.problematicTime`)}</OrderPageTypography>
              <OrderPageTypography color="warning">
                {moment.unix(timeSlotResponseOut.timeDisabledDetails?.time).utc().format('HH:mm')}
              </OrderPageTypography>
            </Grid>
          )}
          {!isEmpty(timeSlotResponseOut.timeDisabledDetails?.orderNumbers) && (
            <Grid container gap={1}>
              <OrderPageTypography color="warning">{t(`troubleshoot.orderNumbers`)}</OrderPageTypography>
              {timeSlotResponseOut.timeDisabledDetails?.orderNumbers?.map((x) => (
                <OrderPageTypography color="warning">{x}</OrderPageTypography>
              ))}
            </Grid>
          )}
          {!isEmpty(timeSlotResponseOut.timeDisabledDetails?.unavailabilityStartTimes) && (
            <Grid container gap={1}>
              <OrderPageTypography color="warning">{t(`troubleshoot.unavailabilityStartTime`)}</OrderPageTypography>
              {timeSlotResponseOut.timeDisabledDetails?.unavailabilityStartTimes?.map((x) => (
                <OrderPageTypography color="warning">
                  {x === 0 ? t('calendar.unavailability_dialog.all_day') : moment.unix(x).utc().format('HH:mm')}
                </OrderPageTypography>
              ))}
            </Grid>
          )}
        </Grid>
      </Alert>
    </Fade>
  );
};
