import { Avatar, Box, Icon, Tooltip } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import React from 'react';
import './ActivityItem.scss';
import moment from 'moment';
import { useLanguage } from '../common/GeneralUtils';

const ActivityItem = (props) => {
  const { activityItem, usersDict } = props;
  const { timestamp, user_id: userId, icon, data } = activityItem;
  const language = useLanguage();

  let avatarContent = 'UU';
  if (userId) {
    const user = usersDict[userId];
    if (user) {
      avatarContent = user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase();
    }
  } else {
    avatarContent = <Icon>{icon}</Icon>;
  }

  return (
    <Box className="activity-item">
      <Avatar className="user-avatar" sx={{ bgcolor: deepPurple[500] }}>
        {avatarContent}
      </Avatar>

      <Box className="text-container">
        <Box className="activity-text" dangerouslySetInnerHTML={{ __html: data.text[language] }} />
        <Box className="date-text">
          <Tooltip title={moment.unix(timestamp).format('D MMMM YYYY - HH:mm')} arrow placement="left">
            <span>{moment.unix(timestamp).fromNow()}</span>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityItem;
