import { Button, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import '../orders/Orders.scss';
import '../orders/UpcomingOrders.scss';
import { useTranslation } from 'react-i18next';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { EditDiscountDialog } from './EditDiscountDialog';
import { DiscountOut } from '../api';

type DiscountsActionsProps = {
  onAdd?: (updated: DiscountOut) => void;
};
export const DiscountsActions = ({ onAdd }: DiscountsActionsProps) => {
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const { t } = useTranslation();

  const { readOnly } = UseAuthPermissions();

  return (
    <Grid container justifyContent="flex-end">
      <Tooltip title={readOnly ? t('noPermissions') : undefined}>
        <span>
          <Button
            disabled={readOnly}
            variant="contained"
            sx={{ textTransform: 'none', marginInlineStart: '20px' }}
            onClick={() => setEditDialogIsOpen(true)}>
            {t('discounts.addDiscount')}
          </Button>
        </span>
      </Tooltip>
      <EditDiscountDialog dialogIsOpen={editDialogIsOpen} setDialogIsOpen={setEditDialogIsOpen} onAdd={onAdd} />
    </Grid>
  );
};
