import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DayType } from '../DayType';
import { PickerCondensedDay } from './PickerCondensedDay';
import { OrderPageData } from '../../../../api';

type LegendProps = {
  orderPage?: OrderPageData;
};
export const Legend = ({ orderPage }: LegendProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const shouldRenderRunningOutDay =
    theme.customTheme.palette.calendarPicker.availableDay.background !==
    theme.customTheme.palette.calendarPicker.runningOutDay.background;
  const waitingListEnabled = orderPage?.waiting_list?.enabled;
  return (
    <Grid container flexDirection="column">
      <Grid container flexDirection="row" mb={1} gap={1} mt={3} alignItems="center">
        <Grid item>
          <PickerCondensedDay dayType={DayType.AVAILABLE_DAY} isLegend />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{t('calendarLegend.hasManyTickets')}</Typography>
        </Grid>
      </Grid>
      {shouldRenderRunningOutDay && (
        <Grid container flexDirection="row" mb={1} gap={1} alignItems="center">
          <Grid item>
            <PickerCondensedDay dayType={DayType.RUNNING_OUT_DAY} isLegend />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{t('calendarLegend.runningOut')}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid container flexDirection="row" mb={1} gap={1} alignItems="center">
        <Grid item>
          <PickerCondensedDay isLegend dayType={DayType.FULLY_BOOKED_DAY} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {waitingListEnabled ? t('calendarLegend.waitingListEnabled') : t('calendarLegend.soldOut')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
