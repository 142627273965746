import * as yup from 'yup';
import { Language } from '../../../api';

type DynamicSchema = {
  [key: string]: yup.StringSchema<string | null | undefined>;
};
export const dynamicLabelSchema = (t: any, languages: Language[], required?: boolean) =>
  languages.reduce((schema, lang) => {
    if (required) {
      schema[lang] = yup.string().required(t('editOrderPage.yup.required'));
    } else {
      schema[lang] = yup.string().nullable();
    }

    return schema;
  }, {} as DynamicSchema);
