import { useTranslation } from 'react-i18next';
import { AccordionDetails, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React from 'react';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';

type PaymentLinksPanelProps = {
  expanded?: AccordionPanelsNames;
  handleChange: any;

  setCreatePaymentLink: (value: any) => void;
  createPaymentLink: boolean;
  isEditOrder: boolean;
};
export const PaymentLinksPanel = ({
  expanded,
  handleChange,
  setCreatePaymentLink,
  isEditOrder,
  createPaymentLink,
}: PaymentLinksPanelProps) => {
  const { t } = useTranslation();

  return (
    <Accordion onChange={handleChange} expandedPanelName={expanded} panelName={AccordionPanelsNames.PaymentLinksPanel}>
      <AccordionSummary title={t('calendar.edit_order.paymentLinks')} />
      <AccordionDetails>
        {isEditOrder ? null : (
          <Grid container>
            <FormControlLabel
              control={<Checkbox />}
              checked={createPaymentLink}
              label={t('calendar.edit_order.createPaymentLink')}
              onChange={(e: any) => {
                setCreatePaymentLink(e.target.checked);
              }}
            />
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
