import 'moment/locale/he';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { CalendarItems } from '../CalendarWrapper';
import { EnrichedEventData } from '../../api';
import EventBox from '../calendar-items/EventBox';

type MultipleDaysViewProps = {
  calendarItems: CalendarItems;
  openEvent: (event: EnrichedEventData) => void;
};

export const CondensedCalendarView = ({ calendarItems, openEvent }: MultipleDaysViewProps) => {
  const groupedByMonth: Record<string, EnrichedEventData[]> = {};

  for (const [date, eventList] of Object.entries(calendarItems.events)) {
    const yearMonth = date.slice(0, 7);
    if (moment(date).isSameOrAfter(moment().startOf('month'), 'month')) {
      if (!groupedByMonth[yearMonth]) {
        groupedByMonth[yearMonth] = [];
      }
      groupedByMonth[yearMonth].push(...eventList);
    }
  }
  return (
    <div>
      {Object.entries(groupedByMonth).map(([month, events]) => (
        <Grid container flexDirection="column" mt={2}>
          <Grid container item alignContent="center" alignItems="baseline">
            <Typography fontSize="24px" fontWeight={800} mx={1}>
              {moment(month).format('MMMM')}
            </Typography>
            <Typography>
              {events.reduce((useTickets, item) => {
                return useTickets + item.used_tickets;
              }, 0)}
            </Typography>
            <Typography>/</Typography>
            <Typography>
              {events.reduce((availableTickets, item) => {
                return availableTickets + item.tickets;
              }, 0)}
            </Typography>
          </Grid>

          <Grid container gap={1}>
            {events.map((event) => (
              <Grid item maxWidth="150px">
                <EventBox key={event.id} event={event} viewType="condensed" openEvent={openEvent} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
