import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Alert, Snackbar, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { usePostHog } from 'posthog-js/react';
import { EventService } from '../../../api';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  '&:hover': {
    color: grey[700],
  },
}));
type SendEmailDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  eventId: string;
};
export default function SendEmailDialog({ isOpen, setIsOpen, eventId }: SendEmailDialogProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  const save = async () => {
    setLoading(true);
    await EventService.sendEmails({ event_id: eventId, message });
    posthog.capture('Email send to participants', { eventId });
    setSuccessOpen(true);
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle>{t('sendEmailDialog.title')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={message}
            minRows={5}
            multiline
            onChange={(event: any) => {
              setMessage(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <ColorButton onClick={handleClose}>{t('cancel')}</ColorButton>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={save}
            sx={{ boxShadow: 'none' }}
            autoFocus
            disabled={message === ''}>
            {t('send')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success">
          {t('sendEmailDialog.success')}
        </Alert>
      </Snackbar>
    </div>
  );
}
