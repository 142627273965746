import React from 'react';
import { Chip, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { PropelUserModel } from '../../api';

export const getUserFullName = (user: PropelUserModel) => `${user.first_name} ${user.last_name}`;
export const getUserFullNameFromUserId = (users: PropelUserModel[], userId: string) => {
  const user = users.find((x) => x.user_id === userId);
  if (user) {
    return getUserFullName(user);
  }
  return '';
};
export const UsersMultiSelect = ({
  users,
  selectedUsersIds,
  setSelectedUsersIds,
  size = 'medium',
}: {
  users: PropelUserModel[];
  selectedUsersIds: string[];
  setSelectedUsersIds: (selected: string[]) => void;
  size?: 'small' | 'medium';
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      variant="outlined"
      sx={{
        minWidth: '195px',
      }}>
      <InputLabel size={size === 'small' ? 'small' : 'normal'} id="select-label">
        {isEmpty(selectedUsersIds) ? t('calendar.event_popper.employees') : ''}
      </InputLabel>

      <Select
        size={size}
        labelId="select-label"
        multiple
        value={selectedUsersIds}
        onChange={(e) => {
          // @ts-ignore
          setSelectedUsersIds(e.target.value);
        }}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((userId) => (
              <Chip
                key={userId}
                label={getUserFullNameFromUserId(users, userId)}
                onDelete={() => setSelectedUsersIds(selectedUsersIds.filter((id) => id !== userId))}
                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
              />
            ))}
          </Stack>
        )}>
        {users
          ?.map((user) => ({ ...user, fullName: getUserFullName(user) }))
          .sort((a, b) => a.fullName.localeCompare(b.fullName))
          .map((user) => (
            <MenuItem key={`MenuItem-${user.user_id}`} value={user.user_id} sx={{ justifyContent: 'space-between' }}>
              {user.fullName}
              {selectedUsersIds.includes(user.user_id) ? <CheckIcon color="info" /> : null}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
