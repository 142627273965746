import { UploadButton } from '@bytescale/upload-widget-react';
import { useTranslation } from 'react-i18next';
import { UploadWidgetLocale, UploadWidgetResult } from '@bytescale/upload-widget';
import { AddCircleOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAuth } from '../../../../session/InternalAuthProvider';
import { AccountData } from '../../../../api';

type ImageUploadWidgetProps = {
  onImageUpload: (files: UploadWidgetResult[]) => void;
};
export const ImageUploadWidget = ({ onImageUpload }: ImageUploadWidgetProps) => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { t } = useTranslation();

  const localeOverride: UploadWidgetLocale = t('bytescaleWidgetOverrides', { returnObjects: true });
  const options = {
    apiKey: import.meta.env.VITE_REACT_APP_BYTESCALE,
    maxFileCount: 1,
    path: {
      // fileName: uuid(),
      fileNameVariablesEnabled: false,
      folderPath: `/uploads/${account.id}`,
      folderPathVariablesEnabled: false,
    },
    locale: localeOverride,
    maxFileSizeBytes: 5 * 1024 * 1024,
  };

  return (
    <UploadButton options={options} onComplete={onImageUpload}>
      {({ onClick }) => (
        <IconButton>
          <AddCircleOutline onClick={onClick} />
        </IconButton>
      )}
    </UploadButton>
  );
};
