import React from 'react';
import styled from 'styled-components';
import { UserAvailabilityType } from '../api';

const StyledCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid black;
`;

export const AvailabilityCircle = ({ type = 'working_days' }: { type?: UserAvailabilityType | 'working_days' }) => {
  let backgroundColor = '#0026bd';
  if (type === UserAvailabilityType.AVAILABLE) {
    backgroundColor = '#4ABD00';
  }
  if (type === UserAvailabilityType.BLOCKED) {
    backgroundColor = '#DA511F';
  }
  return <StyledCircle style={{ backgroundColor }} />;
};
