import { PropelUserModel } from '../api';

export const findUserById = (users: PropelUserModel[], user_id: string) => {
  return users?.find((user) => user.user_id === user_id);
};

export const getEmployeeNames = (employee_ids: string[], users: PropelUserModel[]): string[] => {
  const employees = employee_ids.map((id) => {
    const user = findUserById(users, id);
    if (user) {
      return `${user.first_name}`; // ${user.last_name}
    }
    return null;
  });
  return employees.filter((x) => x !== null) as string[];
};
