import './orderCompletion/OrderCompletedPage.scss';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const WaitingListCompletedPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="order-completed-page" dir={i18n.dir()}>
      <div className="order-completed-page-box">
        <CheckCircle className="order-completed-icon" />
        <div className="title">{t('order_page.waiting_list.completed.title')}</div>
        <div className="sub-text">{t('order_page.waiting_list.completed.sub_text')}</div>
      </div>
    </div>
  );
};

export default WaitingListCompletedPage;
