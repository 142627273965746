import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import { LoadingButton } from '@mui/lab';
import { AccountData, AccountService, OrderPageData, WorkingDaysData } from '../../../api';
import { OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { useAuth } from '../../../session/InternalAuthProvider';
import { WorkingDays } from '../../workingSchedule/WorkingDays';

type PricingSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
  accountWorkingDays: WorkingDaysData;
};

export type WorkingDaysFormValues = {
  working_days: WorkingDaysData;
};

export const WorkingDaysSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
  accountWorkingDays,
}: PricingSectionProps) => {
  const { t } = useTranslation();
  const { authState } = useAuth();

  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const validationSchema = yup.object().shape({});

  const initialValues: WorkingDaysFormValues = {
    working_days: orderPage?.working_days ? orderPage.working_days : accountWorkingDays,
  };

  const handleSubmit = async (values: WorkingDaysFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'workingDaysSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            duration: (orderPageInitialValues.duration || 0) * 60,
            ...values,
            id: orderPage.id,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit, setFieldValue }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              flexDirection={isSmallScreen ? 'column' : 'row'}
              justifyContent="space-between"
              flexWrap="nowrap"
              alignItems="flex-start">
              <WorkingDays
                initialWorkingDays={initialValues.working_days}
                workingDays={values.working_days}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                readOnly={readOnly}
              />

              <Grid container justifyContent="flex-end" m={1} lg={1}>
                <LoadingButton
                  size="large"
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || formIsDisabled}>
                  {t('editOrderPage.orderPageBufferSubmit')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
