import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { PriorityHigh } from '@mui/icons-material';
import OrderUtils from '../../order_page/OrderUtils';
import { useLanguage } from '../../common/GeneralUtils';

// Currently used only for rendering stations mark for speculo but a little bit generic. If we are going
// to render non numbers or more then one item, some ux should be improved
export const CustomDetailRenderer = ({ orderPage, order }: { orderPage: any; order: any }) => {
  const theme = useTheme();
  const language = useLanguage();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const customDetailsNamesToRender = OrderUtils.getShowableCustomDetails(orderPage);
  const customDetailsToRender: any[] = [];
  if (customDetailsNamesToRender.length > 0) {
    customDetailsNamesToRender.forEach((x) => {
      // for now we just render stations which are a number and now label.
      customDetailsToRender.push(order.custom_details[x.name]);
    });
  }
  if (customDetailsToRender.length === 0 && !order.comment) {
    return null;
  }
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="right"
      alignContent={language === 'he' ? 'flex-start' : 'flex-end'}
      style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
      {customDetailsToRender.length > 0 &&
        customDetailsToRender.map((x) => {
          if (x < 2) {
            return null;
          }
          return (
            <Grid
              key={`customdetail-${x}`}
              item
              className="custom-detail-circle"
              m={isSmallScreen ? 0 : 0.2}
              mb={0}
              border={`2px solid ${x === 2 ? '#00ff63' : '#7ceee1'}`}>
              {x}
            </Grid>
          );
        })}

      {order.comment && <PriorityHigh style={{ width: '12px' }} />}
    </Grid>
  );
};
