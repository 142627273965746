import * as React from 'react';
import { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import NumberSelector from '../../../common/ui/NumberSelector';
import { OrderData, OrderService } from '../../../api';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  '&:hover': {
    color: grey[700],
  },
}));

type CheckinDialogProps = {
  order?: OrderData;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  updateItem: (itemToUpdate: OrderData) => void;
};
export default function CheckinDialog({ order, isOpen, setIsOpen, updateItem }: CheckinDialogProps) {
  const [loading, setLoading] = useState(false);
  const [persons, setPersons] = useState(0);
  const { t } = useTranslation();
  const [comment, setComment] = useState(order?.comment);
  const save = async () => {
    setLoading(true);
    if (order) {
      const checkedInOrder = await OrderService.checkinOrder({
        order_id: order.id,
        persons,
        comment,
      });

      updateItem({ ...checkedInOrder });
    }

    setIsOpen(false);
    setLoading(false);
  };

  useMemo(() => {
    if (order) {
      let defaultPersons = order.persons;
      if (order && order.checkin) {
        defaultPersons = order.checkin.persons;
      }
      setPersons(defaultPersons);
      setComment(order.comment);
    }
  }, [order]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('checkin_dialog.title')}</DialogTitle>
        <DialogContent>
          {order && (
            <Grid container flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
              <NumberSelector
                value={persons}
                setValue={setPersons}
                disabled={order.persons === 1}
                min={0}
                max={order.persons}
              />
              <TextField
                multiline
                fullWidth
                label={t('checkin_dialog.comments')}
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton onClick={() => setIsOpen(false)}>{t('cancel')}</ColorButton>
          <LoadingButton loading={loading} variant="contained" onClick={save} sx={{ boxShadow: 'none' }} autoFocus>
            {t('save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
