/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RepeatingUserAvailabilityCreateDTO } from '../models/RepeatingUserAvailabilityCreateDTO';
import type { UserAvailabilityCreateDTO } from '../models/UserAvailabilityCreateDTO';
import type { UserAvailabilityOut } from '../models/UserAvailabilityOut';
import type { UserAvailabilityRepeatingUpdate } from '../models/UserAvailabilityRepeatingUpdate';
import type { UserAvailabilityUpdate } from '../models/UserAvailabilityUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAvailabilityService {
  /**
   * Get User Availability
   * @param startDate Start date (YYYY-MM-DD)
   * @param endDate End date (YYYY-MM-DD)
   * @param userId The ID of the employee
   * @returns UserAvailabilityOut Successful Response
   * @throws ApiError
   */
  public static getUserAvailability(
    startDate?: string,
    endDate?: string,
    userId?: string,
  ): CancelablePromise<Record<string, Array<UserAvailabilityOut>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user-availability',
      query: {
        start_date: startDate,
        end_date: endDate,
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit User Availability
   * @param requestBody
   * @returns UserAvailabilityOut Successful Response
   * @throws ApiError
   */
  public static editUserAvailability(requestBody: UserAvailabilityUpdate): CancelablePromise<UserAvailabilityOut> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user-availability',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create User Availability
   * @param requestBody
   * @returns UserAvailabilityOut Successful Response
   * @throws ApiError
   */
  public static createUserAvailability(requestBody: UserAvailabilityCreateDTO): CancelablePromise<UserAvailabilityOut> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user-availability',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete User Availability
   * @param userAvailabilityId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteUserAvailability(userAvailabilityId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/user-availability',
      query: {
        user_availability_id: userAvailabilityId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Repeating User Availability
   * @param requestBody
   * @returns UserAvailabilityOut Successful Response
   * @throws ApiError
   */
  public static editRepeatingUserAvailability(
    requestBody: UserAvailabilityRepeatingUpdate,
  ): CancelablePromise<Array<UserAvailabilityOut>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user-availability/repeating',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Repeating User Availability
   * @param requestBody
   * @returns UserAvailabilityOut Successful Response
   * @throws ApiError
   */
  public static createUserAvailabilityRepeating(
    requestBody: RepeatingUserAvailabilityCreateDTO,
  ): CancelablePromise<Array<UserAvailabilityOut>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user-availability/repeating',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Repeating User Availability
   * @param recurrentAvailabilityId
   * @param fromDate
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteRepeatingUserAvailability(
    recurrentAvailabilityId: string,
    fromDate: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/user-availability/repeating',
      query: {
        recurrent_availability_id: recurrentAvailabilityId,
        from_date: fromDate,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
