const HOUR_HEIGHT = 50;
const HOURS_START_OFFSET = 5; // This is used so we will not display all hours.

const getItemStartTime = (item) => {
  return item.time || item.start_time;
};

const getItemEndTime = (item) => {
  // Not proud of it. but hey! fuck you!
  return item.end_time || item.time + item.duration;
};

const getItemWidth = (intersectionLevel) => {
  return 100 / (intersectionLevel + 1);
};
const getItemCss = (item, intersectionLevel) => {
  let top = HOUR_HEIGHT * (getItemStartTime(item) / 3600 - HOURS_START_OFFSET);
  if (top < 0) {
    top = 0;
  }
  return {
    top,
    height: ((getItemEndTime(item) - getItemStartTime(item)) / 3600) * HOUR_HEIGHT,
    width: getItemWidth(intersectionLevel),
    left: intersectionLevel === 0 ? '0' : (100 / (intersectionLevel + 1)) * intersectionLevel,
  };
};
const getCalendarCellLocations = (orders, events, unavailabilities) => {
  const unified_list = [...orders, ...events, ...unavailabilities];
  const cssResult = {};
  if (!unified_list || unified_list.length === 0) {
    return cssResult;
  }

  unified_list.sort((a, b) => {
    return getItemStartTime(a) - getItemStartTime(b);
  });

  let lastItem = unified_list[0];
  cssResult[lastItem.id] = getItemCss(lastItem, 0);

  let intersectionLevel = 0;
  let previousIntersectedItems = [];
  for (let i = 1; i < unified_list.length; i++) {
    const currentItem = unified_list[i];

    if (getItemEndTime(lastItem) > getItemStartTime(currentItem)) {
      // intersected
      intersectionLevel++;
      previousIntersectedItems.push(lastItem);
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      previousIntersectedItems.forEach((item, index) => {
        cssResult[item.id].left = (100 / (intersectionLevel + 1)) * index;
        cssResult[item.id].width = getItemWidth(intersectionLevel);
      });
    } else {
      intersectionLevel = 0;
      previousIntersectedItems = [];
    }
    cssResult[currentItem.id] = getItemCss(currentItem, intersectionLevel);
    lastItem = currentItem;
  }
  return cssResult;
};

const CalendarItemsCssUtils = {
  getCalendarCellLocations,
  HOURS_START_OFFSET,
};

export default CalendarItemsCssUtils;
