import { useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ReactElement } from 'react';

type ResponsiveDialogProps = {
  content: ReactElement | string | null;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  fullScreen?: boolean;
};
const ResponsiveDialog = ({ content, isOpen, setOpen, fullScreen }: ResponsiveDialogProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const shouldBeFullScreen = smallScreen || fullScreen;
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={isOpen}
      onClose={() => setOpen(false)}
      fullScreen={shouldBeFullScreen}
      sx={{ zIndex: '200' }}>
      {content}
    </Dialog>
  );
};

export default ResponsiveDialog;
