import './OrderPage.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { includes } from 'lodash';
import { AccountService } from '../api';
import { useLanguage } from '../common/GeneralUtils';
import OrderPageCard from './OrderPageCard';
import './ChooseOrderPage.scss';
import MonkeybookWidgetFooter from '../common/ui/MonkeybookWidgetFooter';
import { WidgetThemeProvider } from './theme/Theme';
import { parseArrayFromQueryString } from '../orders/orderPageQSSelectorUtil';

const ChooseOrderPage = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { accountId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // for custom styling
  const bgColor = queryParams.get('bgcolor');
  const borderColor = queryParams.get('bcolor');
  const color = queryParams.get('color');
  const orderPagesToShow = parseArrayFromQueryString(queryParams, 'orderpages');

  const [experienceId, setExperienceId] = useState(null);
  const [orderPages, setOrderPages] = useState(null);
  const [experience, setExperience] = useState(null);

  useEffect(() => {
    void (async () => {
      const response = await AccountService.getAccount(accountId);
      setExperienceId(response.experiences[0].id);
      if (orderPagesToShow.length > 0) {
        setOrderPages(response.experiences[0].order_pages.filter((o) => includes(orderPagesToShow, o.id)));
      } else {
        setOrderPages(response.experiences[0].order_pages);
      }

      setExperience(response.experiences[0]);
    })();
  }, []);

  if (!orderPages) {
    return null;
  }

  return (
    <WidgetThemeProvider>
      <div className="order-page">
        <Box
          className="choose-order-page-box"
          style={{
            background: `#${bgColor}` || 'white',
            color: `#${color}`,
          }}>
          {experience.choose_order_page && experience.choose_order_page.pre_text && (
            <Box
              className="text-center"
              sx={{ paddingBottom: '10px' }}
              dangerouslySetInnerHTML={{
                __html: experience.choose_order_page.pre_text[language],
              }}
            />
          )}

          <Box className="page-title" sx={{ paddingBottom: '30px' }}>
            {experience.choose_order_page && experience.choose_order_page.title ? (
              <Typography variant="h4">{experience.choose_order_page.title[language]}</Typography>
            ) : (
              t('choose_order.title')
            )}
          </Box>

          {orderPages.map((orderPage, index) => {
            return (
              <OrderPageCard
                key={index}
                accountId={accountId}
                experienceId={experienceId}
                orderPage={orderPage}
                borderColor={borderColor}
                color={color}
                bgColor={bgColor}
              />
            );
          })}

          {experience.choose_order_page && experience.choose_order_page.post_text && (
            <Box className="text-center" sx={{ paddingTop: '10px' }}>
              {experience.choose_order_page.post_text[language]}
            </Box>
          )}
        </Box>
      </div>

      <MonkeybookWidgetFooter />
    </WidgetThemeProvider>
  );
};

export default ChooseOrderPage;
