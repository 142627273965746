import './DetailsStep.scss';
import React from 'react';
import { useOrderPage } from '../OrderPageContext';
import UiObjectsList from '../ui-objects/UiObjectsList';
import EscortsWarning from '../FreeEscortsWarning';

const DetailsStep = (props) => {
  const { errors } = props;
  const { orderPageState, setOrderPageState, setOrderDetailValue, setCustomOrderDetailValue, setOrderCustomDetails } =
    useOrderPage();

  return (
    <div className="details-step">
      <UiObjectsList
        isLoadingDates={orderPageState.isLoadingDates}
        type="order"
        orderState={orderPageState}
        setOrderState={setOrderPageState}
        setOrderDetailValue={setOrderDetailValue}
        setCustomOrderDetailValue={setCustomOrderDetailValue}
        setOrderCustomDetails={setOrderCustomDetails}
        errors={errors}
      />
      <EscortsWarning orderPage={orderPageState.orderPage} />
    </div>
  );
};

export default DetailsStep;
