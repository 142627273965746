import React, { useState } from 'react';
import RotatingLoader from '../common/ui/RotatingLoader';
import MonthView from './views/MonthView';
import MultipleDaysView from './views/MultipleDaysView';
import { CalendarItems, CalendarView, mapCalendarViewToNumOfDays } from './CalendarWrapper';
import { EnrichedEventData, OrderData } from '../api';
import OrderBoxPopper from './calendar-items/OrderBoxPopper';
import EventBoxDialog from './calendar-items/eventBoxDialog/EventBoxDialog';
import { CondensedCalendarView } from './views/CondensedCalendarView';

type CalendarContentProps = {
  calendarLoading: boolean;
  calendarView: CalendarView;
  calendarItems: CalendarItems;
  currentDate: string;
  isEventBoxOpen: boolean;
  setIsEventBoxOpen: (isEventBoxOpen: boolean) => void;
};
export const CalendarContent = ({
  calendarLoading,
  calendarView,
  calendarItems,
  currentDate,
  isEventBoxOpen,
  setIsEventBoxOpen,
}: CalendarContentProps) => {
  const [orderBoxPopperAnchor, setOrderBoxPopperAnchor] = useState<null | HTMLElement>(null);
  const [orderToOpen, setOrderToOpen] = useState<OrderData | undefined>(undefined);
  const [eventToOpen, setEventToOpen] = useState<EnrichedEventData | undefined>(undefined);
  const flattenedOrders: OrderData[] = [];
  Object.entries(calendarItems.orders).forEach(([key, orderDataArray]) => {
    flattenedOrders.push(...orderDataArray);
  });
  const getNextOrder = () => {
    if (!orderToOpen) {
      return undefined;
    }
    let orderIndex = 0;
    for (const order of flattenedOrders) {
      if (order.id === orderToOpen?.id) {
        break;
      }
      orderIndex++;
    }
    if (orderIndex < flattenedOrders.length - 1) {
      return flattenedOrders[orderIndex + 1];
    }
    return undefined;
  };

  const getPreviousOrder = () => {
    if (!orderToOpen) {
      return undefined;
    }
    let orderIndex = 0;
    for (const order of flattenedOrders) {
      if (order.id === orderToOpen?.id) {
        break;
      }
      orderIndex++;
    }
    if (orderIndex > 0) {
      return flattenedOrders[orderIndex - 1];
    }
    return undefined;
  };
  const flattenedEvents: EnrichedEventData[] = [];

  Object.entries(calendarItems.events).forEach(([key, eventsArrayArray]) => {
    flattenedEvents.push(...eventsArrayArray);
  });
  const getNextEvent = () => {
    if (!eventToOpen) {
      return undefined;
    }
    let eventIndex = 0;
    for (const event of flattenedEvents) {
      if (event.id === eventToOpen?.id) {
        break;
      }
      eventIndex++;
    }
    if (eventIndex < flattenedEvents.length - 1) {
      return flattenedEvents[eventIndex + 1];
    }
    return undefined;
  };
  const getPreviousEvent = () => {
    if (!eventToOpen) {
      return undefined;
    }
    let eventIndex = 0;
    for (const event of flattenedEvents) {
      if (event.id === eventToOpen?.id) {
        break;
      }
      eventIndex++;
    }
    if (eventIndex > 0) {
      return flattenedEvents[eventIndex - 1];
    }
    return undefined;
  };
  const openOrder = (event: any, order: OrderData) => {
    setOrderBoxPopperAnchor(orderBoxPopperAnchor || event.currentTarget);
    setOrderToOpen(order);
  };
  const closeOrderBoxPopper = () => {
    setOrderBoxPopperAnchor(null);
    setOrderToOpen(undefined);
  };
  if (calendarLoading) {
    return (
      <div className="loading-container">
        <RotatingLoader />
      </div>
    );
  }
  const openEvent = (event: EnrichedEventData) => {
    setOrderBoxPopperAnchor(null);
    setEventToOpen(event);
    setIsEventBoxOpen(true);
  };
  return (
    <>
      {calendarView === CalendarView.month ? (
        <MonthView
          calendarItems={calendarItems}
          currentDate={currentDate}
          openOrder={openOrder}
          openEvent={openEvent}
        />
      ) : calendarView === CalendarView.condensed ? (
        <CondensedCalendarView calendarItems={calendarItems} openEvent={openEvent} />
      ) : (
        <MultipleDaysView
          calendarItems={calendarItems}
          currentDate={currentDate}
          numOfDays={mapCalendarViewToNumOfDays(calendarView)}
          openOrder={openOrder}
          openEvent={openEvent}
        />
      )}
      {orderToOpen && orderBoxPopperAnchor && (
        <OrderBoxPopper
          anchorEl={orderBoxPopperAnchor}
          closeOrderBoxPopper={closeOrderBoxPopper}
          order={orderToOpen}
          getNextOrder={getNextOrder}
          getPreviousOrder={getPreviousOrder}
          openOrder={openOrder}
        />
      )}
      {isEventBoxOpen && eventToOpen && (
        <EventBoxDialog
          open={isEventBoxOpen}
          setOpen={setIsEventBoxOpen}
          event={eventToOpen}
          getNextEvent={getNextEvent}
          getPreviousEvent={getPreviousEvent}
          openEvent={openEvent}
        />
      )}
    </>
  );
};
