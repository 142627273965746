export const WEBOOK_SAVED_CURRENT_DATE = 'webook-saved-current-date';

const getItem = (key) => {
  try {
    let result = localStorage.getItem(key);

    if (!result) return null;

    result = JSON.parse(result);
    const now = new Date();

    if (now.getTime() > result.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return result.value;
  } catch (e) {
    return null;
  }
};

const getTTLLeft = (key) => {
  try {
    let result = localStorage.getItem(key);

    if (!result) return null;
    result = JSON.parse(result);
    const now = new Date();
    return result.expiry - now.getTime();
  } catch (e) {
    return null;
  }
};

const setItem = (key, value, ttlMilli = 86400000) => {
  try {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttlMilli,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.log(e);
  }
};

const removeByPrefix = (prefix) => {
  try {
    Object.entries(localStorage)
      .map((x) => x[0])
      .filter((x) => x.startsWith(prefix))
      .map((x) => localStorage.removeItem(x));
  } catch (e) {
    console.error(e);
  }
};
const removeItem = (x) => {
  try {
    localStorage.removeItem(x);
  } catch (e) {
    console.error(e);
  }
};

const TTLLocalStorage = {
  getItem,
  setItem,
  removeByPrefix,
  removeItem,
  getTTLLeft,
};

export default TTLLocalStorage;
