import { UserAvailabilityService } from '../../api';
import { useItems } from '../../calendar/swr/useItems';

const fetcher =
  (userId?: string) =>
  async ({ startDate, endDate }: { startDate: string; endDate: string }) =>
    UserAvailabilityService.getUserAvailability(startDate, endDate, userId);

type UseUserAvailabilityProps = { startDate: string; endDate: string; userId?: string };

export const useUserAvailability = ({ startDate, endDate, userId }: UseUserAvailabilityProps) => {
  const { items, error, isLoading, deleteItem, addItem, updateItem, addItems, reFetch } = useItems({
    startDate,
    endDate,
    opId: 'getUserAvailability',
    fetcher: fetcher(userId),
  });

  return {
    items: items || {},
    isLoading,
    isError: error,
    deleteItem,
    addItem,
    updateItem,
    addItems,
    reFetch,
  };
};
