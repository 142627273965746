import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import { Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import Color from 'color';
import { CustomDetail } from '../api';
import { useLanguage } from '../common/GeneralUtils';

type StyledToggleButtonProps = React.ComponentProps<typeof ToggleButton> & {
  customDetails?: CustomDetail[];
  troubleshoot?: boolean;
};
export const StyledToggleButton: React.FC<StyledToggleButtonProps> = ({
  customDetails,
  sx,
  troubleshoot,
  ...props
}: StyledToggleButtonProps) => {
  const language = useLanguage();
  const theme = useTheme();
  const hasCustomDetails = !isEmpty(customDetails);
  // for now we will just render the first one that should be rendered to customers. when will have more different
  // ux should be added
  const firstCustomDetail = customDetails?.find((x) => x.show_in_widget);
  return (
    <ToggleButton
      sx={{
        color: (theme) => (troubleshoot ? theme.customTheme.palette.railFill : theme.customTheme.palette.text.primary),
        borderColor: (theme) => theme.customTheme.palette.text.primary,

        backgroundColor: (theme) =>
          troubleshoot ? theme.customTheme.palette.toggleButton.disabledBg : theme.customTheme.palette.background,

        '&:hover': {
          color: (theme) => theme.customTheme.palette.text.primary,
          backgroundColor: (theme) => Color(theme.customTheme.palette.toggleButton.selectedBg).darken(0.3).hex(),
        },
        '&.Mui-selected': {
          color: (theme) => theme.customTheme.palette.text.primary,
          backgroundColor: theme.customTheme.palette.toggleButton.selectedBg,
          border: (theme) => `2px solid ${theme.customTheme.palette.primary}`,
          '&:hover': {
            color: (theme) => theme.customTheme.palette.text.primary,
            backgroundColor: (theme) => Color(theme.customTheme.palette.toggleButton.selectedBg).darken(0.3).hex(),
          },
        },

        '&.Mui-disabled': {
          color: (theme) => theme.customTheme.palette.railFill,
          borderColor: (theme) => theme.customTheme.palette.text.primary,
          backgroundColor: (theme) => theme.customTheme.palette.toggleButton.disabledBg,
        },
        ...sx,
      }}
      {...props}>
      <Grid container flexDirection="column" my={hasCustomDetails ? -1 : undefined}>
        {props.children}
        {hasCustomDetails && (
          <Typography variant="caption" display="block" gutterBottom mt={-1}>
            {firstCustomDetail?.label[language]}
          </Typography>
        )}
      </Grid>
    </ToggleButton>
  );
};
