import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';

import { useParams, useSearchParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import uuid from 'react-uuid';
import { Grid } from '@mui/material';
import { includes } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AccountService, EventService, OrderService, VoucherService, VoucherStatus } from '../api';
import OrderUtils, { SpecificEventError, VoucherError } from './OrderUtils';
import TTLLocalStorage from '../common/TTLLocalStorage';
import { PluginType } from './PluginUtils';
import { OrderPageIntent } from './OrderPage';
import { WidgetThemeProvider } from './theme/Theme';
import { deepOmitNull } from './utils';
import RotatingLoader from '../common/ui/RotatingLoader';
import { useLanguage } from '../common/GeneralUtils';
import { setUTMData } from './orderCompletion/utmLocalStorage';

export function pluginEnabled(orderCustomDetail, pluginName) {
  const item = orderCustomDetail[pluginName] === true;
  return !!item;
}

const initialState = OrderUtils.initialOrderState;

const OrderPageContext = createContext(initialState);

export const useOrderPage = () => useContext(OrderPageContext);

export const OrderPageProvider = ({ children }) => {
  const language = useLanguage();
  const { i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const [orderPageState, setOrderPageState] = useState(initialState);
  const { accountId, experienceId, orderPageId } = useParams();
  const posthog = usePostHog();
  const voucherCode = searchParams.get('vouchercode');
  const shouldUseDefaultTheme = searchParams.get('defaulttheme') === 'true';
  const onlyVouchers = searchParams.get('vouchers') === 'true';
  const onlyCouples = searchParams.get('couples') === 'true';
  const noMinDate = searchParams.get('nomindate') === 'true';
  const troubleshoot = searchParams.get('troubleshoot') === 'true';
  const specificEventId = searchParams.get('event');
  const initOrderPageState = (responseData, voucher, voucherError, specificEvent, specificEventError) => {
    const account = responseData;
    const accountDefaultLanguage = account.default_system_language;
    if (accountDefaultLanguage !== language && !searchParams.get('language')) {
      void i18n.changeLanguage(accountDefaultLanguage);
    }
    let experience = responseData.experiences[0];
    if (experienceId) {
      experience = responseData.experiences.find((e) => e.id === experienceId);
    }

    let orderPage = experience.order_pages[0];
    if (orderPageId) {
      orderPage = experience.order_pages.find((o) => o.id === orderPageId);
    }
    orderPageState.orderPageIntent = OrderPageIntent.BUY_NOW;
    if (voucher) {
      orderPageState.orderPageIntent = OrderPageIntent.REDEEM_GIFT_CARD;
      orderPageState.isLoadingDates = true;
    }
    const tempDetails = {
      ...orderPageState.details,
      persons: voucher?.persons || orderPage.persons,
      duration: voucher?.duration || orderPage.duration,
      custom_details: voucher?.custom_details || OrderUtils.getDefaultCustomDetails(orderPage),
    };

    const getStateInLocalStorage = (orderPageId) => {
      const key = `order-page-state-${orderPageId}`;
      return TTLLocalStorage.getItem(key);
    };
    posthog.setPersonPropertiesForFlags({ accountId: account.id });
    posthog.identify(uuid(), {
      accountId: account.id,
      orderPageId: orderPage.id,
      environment: import.meta.env.VITE_REACT_APP_ENVIRONMENT || 'local',
      context: 'order-page',
    });
    if (troubleshoot) {
      posthog.capture('Troubleshoot started', { orderPageId: orderPage.id });
    }
    const specificEventDateAndTimeDetails = specificEvent
      ? {
          dateAndTimeDetails: {
            date: specificEvent?.date,
            time: specificEvent?.time,
          },
        }
      : {};
    const localStorageState = getStateInLocalStorage(orderPage.id);
    if (localStorageState) {
      setOrderPageState({
        ...orderPageState,
        ...localStorageState,
        specificEvent,
        ...specificEventDateAndTimeDetails,
        specificOrderPage: {
          onlyCouples,
          onlyVouchers,
          noMinDate,
          troubleshoot,
        },
      });
    } else {
      setOrderPageState({
        ...orderPageState,
        specificEvent,
        account,
        experience,
        orderPage,
        ...specificEventDateAndTimeDetails,
        details: {
          ...tempDetails,
          price: voucher
            ? voucher.price
            : OrderUtils.calculatePrice(
                orderPage.price,
                tempDetails,
                orderPageState.dateAndTimeDetails,
                orderPage.plugins,
              ),
        },
        isInitialized: true,
        voucher,
        voucherError,
        specificEventError,
        specificOrderPage: {
          onlyCouples,
          onlyVouchers,
          noMinDate,
          troubleshoot,
        },
      });
    }
  };

  const setStateInLocalStorage = (newState) => {
    const ttl = OrderUtils.ORDER_PAGE_TTL * 1000;
    const key = `order-page-state-${newState.orderPage.id}`;
    TTLLocalStorage.setItem(key, newState, ttl);
  };

  useEffect(() => {
    void (async () => {
      const account = await AccountService.getAccount(accountId);

      let voucher;
      let voucherError;
      let specificEventError;
      let specificEvent = null;
      if (account?.marketing_integrations?.ga4_id) {
        ReactGA.initialize(account?.marketing_integrations?.ga4_id);
      }
      if (voucherCode) {
        posthog.startSessionRecording({});
        posthog.capture('Voucher redemption started', { voucherCode });
        try {
          voucher = await VoucherService.getVoucher(voucherCode);
          if (!includes([VoucherStatus.ACTIVE], voucher.status)) {
            if (voucher.status === VoucherStatus.USED) {
              voucherError = VoucherError.ALREADY_USED;
            }
            if (voucher.status === VoucherStatus.ARCHIVED) {
              voucherError = VoucherError.ARCHIVED;
            }
            voucher = null;
          }
        } catch (e) {
          if (e.status === 404) {
            voucherError = VoucherError.NOT_FOUND;
          }
          if (e.status === 400 && e.body.detail === 'voucher is not valid') {
            voucherError = VoucherError.EXPIRED;
          }
        }
      }
      if (specificEventId) {
        specificEvent = await EventService.getSlimEvent(specificEventId);
        if (specificEvent.tickets_left < 1) {
          specificEventError = SpecificEventError.NO_MORE_TICKETS;
        }
      }
      initOrderPageState(account, voucher, voucherError, specificEvent, specificEventError);
    })();
    setUTMData();
  }, []);
  useEffect(() => {
    void (async () => {
      if (orderPageState.isLoadingDates && orderPageState.account) {
        const response = await OrderService.getAvailableDates({
          account_id: orderPageState.account.id,
          order_page_id: orderPageState.orderPage.id,
          order_details: orderPageState.details,
        });
        setOrderPageState({ ...orderPageState, isLoadingDates: false, availableDates: response });
      }
    })();
  }, [orderPageState.isLoadingDates, orderPageState.account]);

  const setOrderDetailValue = (dataType, value) => {
    const tempDetails = {
      ...orderPageState.details,
      [dataType]: value,
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(orderPageState.details?.custom_details, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setCustomOrderDetailValue = (name, value) => {
    const tempDetails = {
      ...orderPageState.details,
      custom_details: {
        ...orderPageState.details.custom_details,
        [name]: value,
      },
    };

    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setOrderCustomDetails = (customDetails) => {
    const tempDetails = {
      ...orderPageState.details,
      custom_details: {
        ...orderPageState.details.custom_details,
        ...customDetails,
      },
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(customDetails, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setPersonalDetails = (personalDetails) => {
    const newState = { ...orderPageState, personalDetails };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setDate = (date) => {
    const tempDateDetails = {
      ...orderPageState.dateAndTimeDetails,
      date,
    };
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      orderPageState.details,
      tempDateDetails,
      orderPageState.orderPage.plugins,
    );

    const newState = {
      ...orderPageState,
      dateAndTimeDetails: {
        ...orderPageState.dateAndTimeDetails,
        date,
      },
      details: {
        ...orderPageState.details,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setTime = (time) => {
    const newState = {
      ...orderPageState,
      dateAndTimeDetails: {
        ...orderPageState.dateAndTimeDetails,
        time,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setPaymentUrl = (val) => {
    setOrderPageState({
      ...orderPageState,
      paymentUrl: val,
    });
  };
  const setTip = (type, value) => {
    const newState = {
      ...orderPageState,
      tip: { value, type },
    };
    setOrderPageState(newState);
  };

  const setActiveStep = (val) => {
    const newState = {
      ...orderPageState,
      activeStep: val,
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };
  const setActiveStepAndOrderPageIntent = (activeStep, orderPageIntent) => {
    const newState = {
      ...orderPageState,
      activeStep,
      orderPageIntent,
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };
  const reloadDates = () => {
    setOrderPageState({ ...orderPageState, isLoadingDates: true });
  };
  const ctx = useMemo(
    () => ({
      reloadDates,
      orderPageState,
      setOrderDetailValue,
      setCustomOrderDetailValue,
      setOrderCustomDetails,
      setOrderPageState: (val) => {
        setStateInLocalStorage(val);
        setOrderPageState(val);
      },
      setPersonalDetails,
      setDate,
      setTime,
      setPaymentUrl,
      setActiveStep,
      setActiveStepAndOrderPageIntent,
      setTip,
    }),
    [
      reloadDates,
      orderPageState,
      setOrderDetailValue,
      setCustomOrderDetailValue,
      setOrderCustomDetails,
      setOrderPageState,
      setPersonalDetails,
      setDate,
      setTime,
      setPaymentUrl,
      setActiveStep,
      setActiveStepAndOrderPageIntent,
      setTip,
    ],
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testingTheme = {
    palette: {
      primary: '#ea9a10',
      text: {
        primary: '#ea9a10',
        button: {
          contained: '#404040',
          outlined: '#e3c998',
        },
      },
      background: '#404040',
      toggleButton: {
        selectedBg: '#d82f72',
      },
      border: '#d82f72',
      warning: '#ed6c02',
      calendarPicker: {
        color: '#404040',
      },
    },
  };
  if (!orderPageState.isInitialized) {
    return (
      <Grid container minHeight={300}>
        <RotatingLoader />
      </Grid>
    );
  }
  const iframeHeight = document.body.offsetHeight;
  const iframeWidth = document.body.offsetWidth;

  window.parent.postMessage({ height: iframeHeight, width: iframeWidth }, '*');

  const externalTheme = deepOmitNull(orderPageState.account.custom_theme);
  const defaultTheme = {
    palette: {
      primary: '#4abd00',
      trackFill: 'rgba(74,189,0,0.63)',
      railFill: 'rgba(74,189,0,0.63)',
      handle: '#1e6203',
      text: {
        button: {
          outlined: '#4abd00',
        },
      },
      border: '#4abd00',
      button: {
        outlined: '#4abd00',
      },
    },
  };
  return (
    <WidgetThemeProvider remoteThemeOverride={shouldUseDefaultTheme ? defaultTheme : externalTheme}>
      <OrderPageContext.Provider value={ctx}>{children}</OrderPageContext.Provider>
    </WidgetThemeProvider>
  );
};
