import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ActivityService, UserService } from '../api';
import PageLoading from '../common/PageLoading';
import './ActivityLine.scss';
import ActivityItem from './ActivityItem';

const ActivityLine = (props) => {
  const { activityLineType, data } = props;
  const [activityItems, setActivityItems] = useState(null);
  const [usersDict, setUsersDict] = useState(null);

  useEffect(() => {
    void (async () => {
      const users = await UserService.getUsers();
      const usersDictTemp = {};
      for (const user of users) {
        usersDictTemp[user.user_id] = user;
      }
      setUsersDict(usersDictTemp);

      const result = await ActivityService.getActivityItems({
        activity_line_type: activityLineType,
        data,
      });
      setActivityItems(result);
    })();
  }, []);

  if (activityItems === null) {
    return <PageLoading />;
  }

  return (
    <Box className="activity-line">
      <Box className="activity-line-container">
        <Box className="line-border" />
        {activityItems.map((activityItem, index) => {
          return <ActivityItem key={index} activityItem={activityItem} usersDict={usersDict} />;
        })}
      </Box>
    </Box>
  );
};

export default ActivityLine;
