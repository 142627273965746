import { createDraggableItem, DraggableItem } from './DraggableItems';
import { EmailBlockData } from '../../../api';

const ITEMS_LOCAL_STORAGE_KEY = 'webook-emailTemplateItems';

export const saveDraggableItemsToLocalStorage = (items: DraggableItem[]) => {
  const emailBlockDataArray = items.map((item) => item.toEmailBlockData?.());
  localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(emailBlockDataArray));
};

export const loadDraggableItemsFromLocalStorage = (): DraggableItem[] => {
  const data = localStorage.getItem(ITEMS_LOCAL_STORAGE_KEY);
  if (!data) {
    return [];
  }

  const emailBlockDataArray: EmailBlockData[] = JSON.parse(data);
  return emailBlockDataArray.map(createDraggableItem);
};

export const clearDraggableItemsLocalStorage = () => {
  localStorage.removeItem(ITEMS_LOCAL_STORAGE_KEY);
};
