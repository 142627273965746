import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import { AccountData, AccountService, Language, OrderPageData } from '../../../api';
import { InformationSectionLanguageTab } from './InformationSectionLanguageTab';
import { OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { dynamicLabelSchema } from './dynamicLabelSchema';

type InformationSectionProps = {
  orderPage: OrderPageData;
  languages: Language[];
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export type InformationFormValues = {
  label: { [key in Language]?: string };
  description: { [key in Language]?: string } | undefined;
  location: { [key in Language]?: string } | undefined;
  phone: { [key in Language]?: string } | undefined;
  activity_label?: { [key in Language]?: string };
};

export const InformationSection = ({
  orderPage,
  languages,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: InformationSectionProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(languages[0]);
  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();

  const handleTabChange = (event: React.SyntheticEvent, newValue: Language) => {
    setActiveTab(newValue);
  };

  const validationSchema = yup.object().shape({
    label: yup.object().shape(dynamicLabelSchema(t, languages, true)),
    description: yup.object().shape(dynamicLabelSchema(t, languages)).nullable(),
    location: yup.object().shape(dynamicLabelSchema(t, languages)),
    phone: yup.object().shape(dynamicLabelSchema(t, languages)),
    activity_label: yup.object().shape(dynamicLabelSchema(t, languages)),
  });

  const initialValues: InformationFormValues = {
    label: orderPage.label,
    description: orderPage.description,
    location: orderPage.location,
    phone: orderPage.phone,
    activity_label: orderPage.activity_label,
  };
  const handleSubmit = async (values: InformationFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'informationSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            duration: (orderPageInitialValues.duration || 0) * 60,
            ...values,
            id: orderPage.id,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent={languages.length === 1 ? 'flex-end' : 'space-between'}>
              {languages.length > 1 && (
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="language tabs">
                  {languages.map((lang) => (
                    <Tab
                      sx={{ borderBottom: 2, borderColor: 'divider' }}
                      key={lang}
                      label={t(`emailTemplates.${lang}`)}
                      value={lang}
                    />
                  ))}
                </Tabs>
              )}
              <LoadingButton
                size="large"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || formIsDisabled}>
                {t('editOrderPage.orderPageBufferSubmit')}
              </LoadingButton>
            </Grid>
            <Grid container mt={2}>
              {languages.map((lang) => (
                <Grid key={lang} hidden={activeTab !== lang}>
                  {activeTab === lang && (
                    <InformationSectionLanguageTab
                      language={lang}
                      values={values}
                      initialValues={initialValues}
                      disabled={formIsDisabled}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
