import './VoucherSummary.scss';
import React, { useState } from 'react';
import { Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOrderPage } from '../OrderPageContext';
import { VoucherOut } from '../../api';
import { useLanguage } from '../../common/GeneralUtils';
import { OrderPageTypography } from '../OrderPageTypography';
import { OrderPageButton } from '../OrderPageButton';
import { getSummaryData } from '../orderUtils/getSummaryData';
import { priceFormatter } from '../utils';

type TableRowType = {
  key: string;
  icon: React.ElementType;
  value?: string | number | boolean;
};

export enum CustomDetailType {
  str = 'str',
  location = 'location',
  bool = 'bool',
  resourceInt = 'resourceInt',
}

type Label = {
  en: string;
  he: string;
};
export type CustomDetail = {
  name: string;
  icon?: string;
  type: CustomDetailType;
  label?: Label;
};
export type OrderCustomDetails = {
  [key: string]: number | boolean;
};
export type CancellationPolicy = {
  days_before: number;
  value: number;
  policy_type: CancellationPolicyType;
};

export enum CancellationPolicyType {
  FIXED = 'fixed',
  PERCENT = 'percent',
}

const VoucherSummary = ({ voucher }: { voucher: VoucherOut }) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const theme = useTheme();
  const [policiesOpen, setpPliciesOpen] = useState(false);

  const handleToggle = () => {
    setpPliciesOpen(!policiesOpen);
  };
  // @ts-ignore
  const { orderPageState } = useOrderPage();
  const cancellationsPolicies = orderPageState.account.cancellation_policies as CancellationPolicy[];

  if (!voucher) {
    return null;
  }
  const rows = getSummaryData({
    custom_details: voucher.custom_details,
    duration: orderPageState.details.duration,
    language,
    orderPage: orderPageState.orderPage,
    persons: voucher.persons,
    voucher,
    t,
  });
  const renderPolicyValue = (cancellationPolicy: CancellationPolicy) => {
    if (cancellationPolicy.policy_type === CancellationPolicyType.PERCENT) {
      return `${cancellationPolicy.value} %`;
    }
    return priceFormatter(cancellationPolicy.value, orderPageState.account);
  };
  const lastPolicyDays =
    cancellationsPolicies && cancellationsPolicies.length > 0
      ? cancellationsPolicies[cancellationsPolicies.length - 2]?.days_before
      : 0;
  return (
    <div className="details-step">
      <Grid container>
        <Grid item mb={4}>
          <OrderPageTypography variant="h3">{t('vouchers.redeemtitle')}</OrderPageTypography>
        </Grid>

        <Table className="table">
          <TableBody>
            {rows.map((row: TableRowType) => (
              <TableRow key={row.key}>
                <TableCell className="table-cell icon-cell">
                  <row.icon style={{ color: theme.customTheme.palette.primary }} />
                </TableCell>
                <TableCell className="table-cell">
                  <OrderPageTypography>{row.key}</OrderPageTypography>
                </TableCell>
                <TableCell className="table-cell">
                  <OrderPageTypography>{row.value}</OrderPageTypography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {cancellationsPolicies && cancellationsPolicies.length > 0 && (
          <Grid xs={12} mt={4}>
            <OrderPageButton fullWidth variant="outlined" onClick={handleToggle}>
              <OrderPageTypography variant="body1"> {t('cancelation.showterms')}</OrderPageTypography>
            </OrderPageButton>
            <Collapse in={policiesOpen}>
              <Grid item xs={6}>
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cell" style={{ fontWeight: 600 }}>
                        <OrderPageTypography variant="body2" bold>
                          {' '}
                          {t('cancelation.daysbefore')}
                        </OrderPageTypography>
                      </TableCell>
                      <TableCell className="table-cell" style={{ fontWeight: 600 }}>
                        <OrderPageTypography variant="body2" bold>
                          {t('cancelation.value')}
                        </OrderPageTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cancellationsPolicies.map((row: CancellationPolicy) => (
                      <TableRow key={`cancellationsPolicy${row.days_before}`}>
                        <TableCell className="table-cell">
                          <OrderPageTypography variant="body2">
                            {row.days_before === 0
                              ? t('cancelation.lessthen', { days: lastPolicyDays })
                              : row.days_before}
                          </OrderPageTypography>
                        </TableCell>
                        <TableCell className="table-cell">
                          <OrderPageTypography variant="body2">{renderPolicyValue(row)}</OrderPageTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default VoucherSummary;
