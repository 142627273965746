import { EmailTemplatesContent, EmailTemplateType, Language } from '../../api';

export type EmailTemplatesStaticContent = {
  headerHtml: string;
  footerHtml: string;
  sendgridHtml?: string;
};

export const resolveTemplate = (
  language: Language,
  emailTemplates?: EmailTemplatesContent,
  selectedEmailTemplateType?: EmailTemplateType,
): EmailTemplatesStaticContent | undefined => {
  if (!emailTemplates || !selectedEmailTemplateType) {
    return undefined;
  }
  return {
    headerHtml: emailTemplates[selectedEmailTemplateType]?.header_data?.[language] || '',
    footerHtml: emailTemplates[selectedEmailTemplateType]?.footer_data?.[language] || '',
    sendgridHtml: emailTemplates[selectedEmailTemplateType]?.sendgrid_data?.[language] || undefined,
  };
};
