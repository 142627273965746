import { Grid } from '@mui/material';
import './UnavailabilityBox.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { OrderPageData, UnavailabilityData, UnavailabilityDetailData } from '../../api';
import { useLanguage } from '../../common/GeneralUtils';
import { useAuth } from '../../session/InternalAuthProvider';
import UnavailabilityBoxPopper from './UnavailabilityBoxPopper';
import UnavailabilityDialog, {
  MAX_PARALLEL_PERSON_CAPACITY,
} from '../dialogs/UnavailabilityDialog/UnavailabilityDialog';
import { WorkshopCircle } from '../../common/ui/WorkshopCircle';
import { useCalendar } from '../CalendarContext';

const UnavailabilityBox = (props: { unavailability: UnavailabilityData; viewType?: 'month' | 'day'; css?: any }) => {
  const { unavailability, viewType, css } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUnavailabilityDialogOpen, setUnavailabilityDialogOpen] = useState(false);
  const {
    // @ts-ignore
    startDate,
    // @ts-ignore
    endDate,
    // @ts-ignore
  } = useCalendar();
  const { t } = useTranslation();
  const language = useLanguage();

  const { authState } = useAuth();
  // @ts-ignore
  const account = authState.account as AccountData;
  const orderPages = account.experiences[0].order_pages;
  const openUnavailability = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getTimeText = () => {
    if (unavailability.all_day) {
      return 'All day';
    }
    return `${moment.unix(unavailability.start_time).tz('utc').format('HH:mm')} - ${moment
      .unix(unavailability.end_time)
      .tz('utc')
      .format('HH:mm')}`;
  };

  let dynamicStyles = null;
  if (css) {
    dynamicStyles = {
      width: `calc(${css.width}% - 2px)`,
      left: `${css.left}%`,
      top: `${css.top - 1}px`,
      height: `${css.height - 5}px`,
      position: 'absolute',
    };
  }

  return (
    <>
      <Grid
        onClick={openUnavailability}
        className={`unavailability-box flex-1${viewType === 'month' ? ' month-unavailability-box' : ''}`}
        sx={dynamicStyles}>
        <Grid className="text-center unavailability-box-inner">
          {viewType === 'month' && (
            <p dir="ltr">
              <strong>{getTimeText()}</strong>
            </p>
          )}
          <p>
            <span>{unavailability.description ? unavailability.description : t('calendar.unavailability')}</span>
            {unavailability.order_page_id && (
              <span> - {orderPages.filter((o: any) => o.id === unavailability.order_page_id)[0].label[language]}</span>
            )}
          </p>
          {unavailability.custom_details && (
            <>
              {Object.keys(unavailability.custom_details).map((key) => {
                return (
                  <p dir="ltr">
                    <span>{unavailability.custom_details[key]}</span>
                    &nbsp;
                    <span>{key === MAX_PARALLEL_PERSON_CAPACITY ? t('availabilitySettings.blockResources') : key}</span>
                  </p>
                );
              })}
            </>
          )}
          {!isEmpty(unavailability.unavailability_details) &&
            unavailability.unavailability_details!.length < orderPages.length && (
              <Grid container justifyContent="center" gap="2px" my="4px">
                {unavailability.unavailability_details?.map((x: UnavailabilityDetailData) => (
                  <WorkshopCircle
                    withTooltip
                    account={account}
                    orderPageId={orderPages.find((orderPage: OrderPageData) => orderPage.id === x.order_page_id)?.id}
                    language={language}
                  />
                ))}
              </Grid>
            )}
        </Grid>
      </Grid>
      <UnavailabilityBoxPopper
        setUnavailabilityDialogOpen={setUnavailabilityDialogOpen}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        unavailability={unavailability}
      />
      <UnavailabilityDialog
        unavailability={unavailability}
        open={isUnavailabilityDialogOpen}
        setOpen={setUnavailabilityDialogOpen}
        calendarEndDate={endDate}
        calendarStartDate={startDate}
      />
    </>
  );
};

export default UnavailabilityBox;
