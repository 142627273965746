import React from 'react';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import UnavailabilityBox from './calendar-items/UnavailabilityBox';
import { UnavailabilityData } from '../api';

type AllDayCalendarCellProps = {
  unavailabilities: UnavailabilityData[];
};
export const AllDayCalendarCell = ({ unavailabilities }: AllDayCalendarCellProps) => {
  if (isEmpty(unavailabilities)) {
    return null;
  }
  const allDayItems = unavailabilities.filter((item) => item.all_day);

  if (isEmpty(allDayItems)) {
    return null;
  }

  return (
    <Grid flexDirection="column">
      {allDayItems.map((unavailability) => {
        return <UnavailabilityBox key={unavailability.id} unavailability={unavailability} />;
      })}
    </Grid>
  );
};
