import { CalendarService } from '../../api';
import { useItems } from './useItems';

const fetcher = async ({ startDate, endDate }: { startDate: string; endDate: string }) =>
  CalendarService.getCalendarEvents(startDate, endDate);

type UseEventsProps = {
  startDate: string;
  endDate: string;
};

export const useEvents = ({ startDate, endDate }: UseEventsProps) => {
  const { items, error, isLoading, deleteItem, addItems, updateItem, reFetch } = useItems({
    startDate,
    endDate,
    opId: 'getCalendarEvents',
    fetcher,
  });

  return {
    items: items || {},
    isLoading,
    isError: error,
    deleteItem,
    addItems,
    updateItem,
    reFetch,
  };
};
