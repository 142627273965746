import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface OrderPageButtonProps extends LoadingButtonProps {
  $disabled?: boolean;
  loadingButton?: boolean; // Explicitly define the type for the loading prop
  variant?: 'contained' | 'outlined' | 'text';
}

export const OrderPageButton = styled(({ loadingButton, ...props }: OrderPageButtonProps) =>
  loadingButton ? <LoadingButton {...props} /> : <Button {...props} />,
)<OrderPageButtonProps>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  background-color: ${({ theme, $disabled }) => ($disabled ? 'inherit' : theme.customTheme.palette.primary)};
  border-color: ${({ theme, $disabled }) => ($disabled ? 'inherit' : theme.customTheme.palette.border)};
  padding-right: 8px;
  padding-left: 8px;
  text-transform: none;

  box-shadow: none;

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      background-color: inherit;
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `}
  &:hover {
    background-color: ${({ theme, $disabled, variant }) =>
      $disabled || variant === 'outlined' ? 'inherit' : theme.customTheme.palette.primary};
    border-color: ${({ theme }) => theme.customTheme.palette.primary};
  }
`;
