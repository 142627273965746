import React from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import TimerIcon from '@mui/icons-material/Timer';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TuneIcon from '@mui/icons-material/Tune';
import TimeUtils from '../../common/TimeUtils';

import { CustomDetailData, LabelData, OrderPageData, VoucherOut } from '../../api';

export type TableRowType = {
  key: string;
  icon: React.ElementType;
  value?: string | number | boolean;
};

const retrieveCustomDetailsRows = (
  custom_details: CustomDetailData[],
  language: string,
  orderPage: any,
): TableRowType[] => {
  const rows: TableRowType[] = [];
  const orderPageCustomDetails = orderPage.custom_details as CustomDetailData[];
  orderPageCustomDetails.forEach((orderPageCustomDetail: CustomDetailData) => {
    let label = orderPageCustomDetail.label?.[language as keyof LabelData];
    // @ts-ignore
    const value = custom_details[orderPageCustomDetail.name as keyof CustomDetailData];
    if (label && value) {
      // we need to convert boolean to 1 for now so it's not having an empty cell
      const valueToSet = typeof value === 'boolean' ? 1 : value;
      label = label.charAt(0).toUpperCase() + label.slice(1);
      rows.push({ key: label, value: valueToSet, icon: TuneIcon });
    }
  });
  return rows;
};

type SummeryTableProps = {
  persons: number;
  custom_details: any;
  duration: number;
  voucher?: VoucherOut;
  language: string;
  orderPage: OrderPageData;
  t: any;
};
export const getSummaryData = ({
  t,
  persons,
  custom_details,
  duration,
  voucher,
  language,
  orderPage,
}: SummeryTableProps) => {
  const rows: TableRowType[] = [
    {
      key: t('order_page.persons'),
      value: persons,
      icon: GroupsIcon,
    },
    {
      key: t('order_page.duration'),
      value: TimeUtils.formatDuration(duration, t),
      icon: TimerIcon,
    },
  ];

  const customDetailsRows = retrieveCustomDetailsRows(custom_details, language, orderPage);
  rows.push(...customDetailsRows);
  if (voucher) {
    rows.push({ key: t('vouchernumber'), value: voucher.order_number, icon: ConfirmationNumberIcon });
    rows.push({ key: t('vouchercode'), value: voucher.code, icon: ConfirmationNumberIcon });
  }

  return rows;
};
