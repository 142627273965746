export function createQueryStringFromArray(paramName: string, array: string[]): string {
  const params = new URLSearchParams();

  array.forEach((value, index) => {
    params.append(`${paramName}[${index}]`, value);
  });

  return params.toString();
}

// Function to parse a query string into an array of strings
export function parseArrayFromQueryString(params: URLSearchParams, paramName: string): string[] {
  const array: string[] = [];

  params.forEach((value, key) => {
    if (key.startsWith(`${paramName}[`) && key.endsWith(']')) {
      array.push(value);
    }
  });

  return array;
}
