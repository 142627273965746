import React, { CSSProperties } from 'react';

import { FontWeight as StyledFontWeight, Text, TypeProps } from 'styled-typography';
import { Theme, useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { FontWeight } from './theme/types';

type Colors =
  | 'primary'
  | 'primary.main'
  | 'secondary'
  | 'disabled'
  | 'white'
  | 'warning'
  | 'error'
  | 'button.contained'
  | 'button.outlined'
  | 'footer';

type TypographyProps = {
  children: React.ReactNode;
  variant?: 'h1' | 'h3' | 'h6' | 'body1' | 'body2';
  style?: CSSProperties | undefined;
  color?: Colors;
  bold?: boolean;
} & Omit<TypeProps, 'color' | 'level'>;

function getColor(theme: Theme, $color: Colors) {
  switch ($color) {
    case 'white':
      return 'white';
    case 'primary.main':
      return theme.customTheme.palette.text.primary;
    case 'warning':
      return theme.customTheme.palette.warning;
    case 'error':
      return theme.customTheme.palette.error;
    case 'button.contained':
      return theme.customTheme.palette.text.button.contained;
    case 'button.outlined':
      return theme.customTheme.palette.text.button.outlined;
    case 'footer':
      return theme.customTheme.palette.text.footer;
    default:
      return theme.customTheme.palette.text.primary;
  }
}

const StyledText = styled(Text)`
  margin: 0;
  text-transform: none;
`;

function getLevel(variant: TypographyProps['variant']) {
  switch (variant) {
    case 'h1':
      return 1;
    case 'h3':
      return 3;
    case 'h6':
      return 4;
    case 'body1':
      return 4;
    case 'body2':
      return 5;
    default:
      return 3;
  }
}

function mapFontWeight(fontWeight: FontWeight) {
  switch (fontWeight) {
    case 'bold':
      return StyledFontWeight.Bold;
    case 'normal':
      return StyledFontWeight.Normal;
    default:
      return StyledFontWeight.Normal;
  }
}

function getFontWeight(theme: Theme, variant: TypographyProps['variant']) {
  const { typography } = theme.customTheme;
  switch (variant) {
    case 'h1':
      return mapFontWeight(typography.h1.fontWeight);
    case 'h3':
      return mapFontWeight(typography.h2.fontWeight);
    case 'h6':
      return mapFontWeight(typography.h3.fontWeight);
    case 'body1':
      return mapFontWeight(typography.body.fontWeight);
    case 'body2':
      return mapFontWeight(typography.body.fontWeight);
    default:
      return StyledFontWeight.Normal;
  }
}

export function OrderPageTypography(props: TypographyProps) {
  const { children, style, variant = 'body1', color = 'primary', bold, ...rest } = props;
  const theme = useTheme();

  const level = getLevel(variant);

  return (
    <StyledText
      {...rest}
      style={style}
      level={level}
      color={getColor(theme, color)}
      fontWeight={bold ? StyledFontWeight.Bold : getFontWeight(theme, variant)}>
      {children}
    </StyledText>
  );
}
