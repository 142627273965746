import './DetailsStep.scss';
import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const VoucherRedemptionSummary = () => {
  const { t } = useTranslation();

  return (
    <div className="details-step">
      <Grid container flexDirection="column" gap={2} alignItems="center" alignContent="center">
        <Typography variant="h5">{t('vouchers.donetitle')}</Typography>
        <Typography variant="h5">{t('vouchers.donesubtitle')}</Typography>
        <Typography variant="h5">{t('vouchers.donetext')}</Typography>
      </Grid>
    </div>
  );
};

export default VoucherRedemptionSummary;
