import { Button, FormControlLabel, Grid, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import RotatingLoader from '../common/ui/RotatingLoader';
import { AccountService } from '../api';
import { useGetAccountAuthenticated } from '../session/swr/useGetAccountAuthenticated';

// @ts-ignore
export const AccountSettingsPage = () => {
  const { t } = useTranslation();
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);

  const { account, isLoading, updateLocalAccount } = useGetAccountAuthenticated({});
  const handleChangeUnavliabilityNotifications = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdatingSettings(true);

    const updatedAccount = await AccountService.updateAccountSettings({
      should_send_unavailability_notifications: event.target.checked,
    });

    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
  };
  const handleChangeShouldSendManagerEmailNotifications = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.updateAccountSettings({
      should_send_manager_email_notifications: event.target.checked,
    });
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
  };
  const onLoginSuccess = async (codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.accountGcalConnect({
      auth_code: codeResponse.code,
    });

    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
  };
  const disconnectFromGoogle = async () => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.updateAccountSettings({
      google_integration_enabled: false,
      should_send_unavailability_notifications: account.should_send_unavailability_notifications,
    });
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
  };

  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    scope: 'https://www.googleapis.com/auth/calendar',
    flow: 'auth-code',
    include_granted_scopes: false,
  });

  if (isLoading || isUpdatingSettings) {
    return (
      <Grid
        container
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Grid>
    );
  }

  return (
    <Grid container p={5} flexDirection="column">
      <Grid item mb={2}>
        <Typography variant="h4">{t('accountSettings.title')}</Typography>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={account?.should_send_unavailability_notifications}
              onChange={handleChangeUnavliabilityNotifications}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={<Typography>{t('accountSettings.sendUnavliabilityNotifications')}</Typography>}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={account?.should_send_manager_email_notifications}
              onChange={handleChangeShouldSendManagerEmailNotifications}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Typography>
              {t('userSettings.orderEmailSync', {
                userEmail: account?.notifications_email,
              })}
            </Typography>
          }
        />
      </Grid>
      <Grid container flexDirection="column" gap={2} mt={4}>
        <Typography variant="h4">{t('userSettings.whereToSync')}</Typography>

        {account?.google_integration_enabled ? (
          <Grid container alignItems="center" gap={2}>
            <img src={'/images/gcallogo.png'} width="24px" />
            <Typography>
              {t('userSettings.gcalSyncEmail', {
                userEmail: account.gcal_synced_email,
              })}
            </Typography>
            <Grid item mx={4}>
              <Button variant="outlined" onClick={() => disconnectFromGoogle()}>
                {t('userSettings.disconnect')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container flexDirection="column" gap={2}>
            <Typography>
              {t('userSettings.userEmailSync', {
                userEmail: account?.notifications_email,
              })}
            </Typography>
            <Typography>{t('userSettings.googleSync')}:</Typography>
            <Grid item width={'250px'} mt={-1}>
              <Button onClick={login} variant="outlined" style={{ borderRadius: '100px', borderColor: 'black' }}>
                <Grid container dir="ltr" gap={1} mx="8px" my="4px">
                  <img src={'/images/gcallogo.png'} style={{ width: '24px' }} />
                  <Typography variant="button" style={{ textTransform: 'none' }} color="black">
                    Connect Google Calendar
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* <Grid container mt={10}> */}
      {/*  <div style={{ minWidth: '600px' }} dangerouslySetInnerHTML={{ __html: replacedHtml }} /> */}
      {/* </Grid> */}
    </Grid>
  );
};
