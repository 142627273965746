import { AccountData } from '../api';

function isTimeZoneInUSA(timezone: string): boolean {
  const usTimezones = ['America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles'];
  return usTimezones.includes(timezone);
}

export const formatDate = (account: AccountData) => {
  const timeZoneInUSA = isTimeZoneInUSA(account.timezone);
  return {
    shortDateFormat: timeZoneInUSA ? 'MM/DD' : 'DD/MM',
    longDateFormat: timeZoneInUSA ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
  };
};
