import { Alert, Grid, Snackbar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { usePostHog } from 'posthog-js/react';
import { includes } from 'lodash';
import RotatingLoader from '../common/ui/RotatingLoader';
import { OrderPageDataTypes, OrderPageTypes, PartialLabelData, PriceTypes, WorkingDaysData } from '../api';
import { useAuth } from '../session/InternalAuthProvider';
import { theme, useLanguage } from '../common/GeneralUtils';
import { InformationSection } from './sections/informationSection/InformationSection';
import { SettingsSection } from './sections/settingsSection/SettingsSection';
import { PricingSection } from './sections/pricingSection/PricingSection';
import { WorkingDaysSection } from './sections/workingDays/WorkingDaysSection';
import { RestrictionsSection } from './sections/restrictionsSection/RestrictionsSection';
import { CommunicationSection } from './sections/CommunicationSection/CommunicationSection';
import { useGetAccountAuthenticated } from '../session/swr/useGetAccountAuthenticated';
import { DeletionSection } from './sections/deletionSection/DeletionSection';

export type OrderPagePrice = {
  type: PriceTypes;
  data_type: OrderPageDataTypes;
  name?: string;
  price?: number;
  base_value?: number;
  base_price?: number;
  price_per_additional_value?: number;
  tiered_pricing?: {
    quantity: number;
    price_per_unit: number;
  }[];
  custom_per_unit_pricing?: number[];
};
export type OrderPageUpdate = {
  order_page_type: string;
  label: PartialLabelData;
  description: PartialLabelData;
  location: PartialLabelData;
  phone: PartialLabelData;
  // waiting_list: WaitingListData; not implemented yet
  color: string;
  min_date_in_days: number;
  max_date_in_days: number;
  after_buffer_minutes: number;
  duration: number;
  working_days?: WorkingDaysData;
  price: OrderPagePrice[];
  availability_settings: {
    parallel_order_page_ids?: string[];
  };
};

enum SettingsPageTabs {
  INFORMATION = 'information',
  PRICING = 'pricing',
  SETTINGS = 'settings',
  WORKING_DAYS = 'working_days',
  RESTRICTIONS = 'restrictions',
  COMMUNICATION = 'communication',
  DELETION = 'deletion',
}

export const OrderPageSettingsPage = () => {
  const language = useLanguage();
  const [activeTab, setActiveTab] = useState(SettingsPageTabs.SETTINGS);
  const { account, updateLocalAccount } = useGetAccountAuthenticated({});
  const [isUpdatedSnackOpen, setIsUpdatedSnackOpen] = useState(false);
  const { orderPageId } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const posthog = usePostHog();
  useEffect(() => {
    posthog.capture('Editing order page loaded');
  }, []);
  const { t } = useTranslation();
  const { authState } = useAuth();

  const orderPage = account?.experiences?.[0].order_pages.find((x) => x.id === orderPageId);
  const isEventOrderPage = orderPage?.order_page_type === OrderPageTypes.EVENT;

  const handleTabChange = (event: React.SyntheticEvent, newValue: SettingsPageTabs) => {
    setActiveTab(newValue);
    window.location.hash = `#${newValue}`;
  };
  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (Object.values(SettingsPageTabs).includes(hash as SettingsPageTabs)) {
      setActiveTab(hash as SettingsPageTabs);
    }
  }, []);
  if (!authState.isInitialized || !account || !orderPage) {
    return (
      <Grid
        container
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Grid>
    );
  }

  const initialValues: OrderPageUpdate = {
    ...orderPage,
    description: orderPage.description || {},
    location: orderPage.location || {},
    phone: orderPage.phone || {},
    min_date_in_days: orderPage.min_date_in_days || 0,
    color: orderPage?.color || '#6577b3',
    duration: (orderPage?.duration || 0) / 60,
    working_days: orderPage?.working_days ? orderPage.working_days : account.experiences[0].working_days,
    price: orderPage?.price,
  };

  const tabsToShow = Object.values(SettingsPageTabs).filter((tab) =>
    isEventOrderPage ? !includes([SettingsPageTabs.WORKING_DAYS], tab) : true,
  );
  const tabContent = {
    [SettingsPageTabs.INFORMATION]: (
      <InformationSection
        orderPage={orderPage}
        languages={account.customer_facing_widgets_languages}
        orderPageInitialValues={initialValues}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [SettingsPageTabs.SETTINGS]: (
      <SettingsSection
        orderPage={orderPage}
        orderPageInitialValues={initialValues}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [SettingsPageTabs.PRICING]: (
      <PricingSection
        orderPage={orderPage}
        orderPageInitialValues={initialValues}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [SettingsPageTabs.WORKING_DAYS]: (
      <WorkingDaysSection
        accountWorkingDays={account.experiences[0].working_days}
        orderPage={orderPage}
        orderPageInitialValues={initialValues}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [SettingsPageTabs.RESTRICTIONS]: (
      <RestrictionsSection
        orderPage={orderPage}
        orderPageInitialValues={initialValues}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
        account={account}
      />
    ),
    [SettingsPageTabs.COMMUNICATION]: <CommunicationSection orderPage={orderPage} />,
    [SettingsPageTabs.DELETION]: (
      <DeletionSection
        orderPage={orderPage}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
        account={account}
      />
    ),
  };

  return (
    <>
      <Grid container mb={isSmallScreen ? undefined : 2} flexDirection="column" padding={isSmallScreen ? 2 : undefined}>
        <Grid container gap={1}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} fontWeight={700}>
            {t('editOrderPage.title')}
          </Typography>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} fontWeight={700}>
            {orderPage?.label[language]}
          </Typography>
        </Grid>
        <Grid container gap={1}>
          <Typography fontWeight={700}>{t('editOrderPage.type')}</Typography>
          <Typography fontWeight={700}>
            {isEventOrderPage ? t('editOrderPage.orderPageTypeEvent') : t('editOrderPage.orderPageTypePrivate')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={isSmallScreen ? 'column' : 'row'} height="100vh" flexWrap="nowrap">
        <Snackbar
          open={isUpdatedSnackOpen}
          onClose={() => setIsUpdatedSnackOpen(false)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            sx={{ width: '100%' }}
            onClose={() => setIsUpdatedSnackOpen(false)}
            severity="success"
            variant="filled">
            {t('editOrderPage.orderPageTypeSuccess')}
          </Alert>
        </Snackbar>

        <Grid
          container
          flexDirection={isSmallScreen ? 'column' : 'row'}
          xs={isSmallScreen ? undefined : 1}
          sx={{
            overflowY: 'hidden',
            overflowX: 'auto',
          }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="language tabs"
            orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            sx={{
              borderBottom: isSmallScreen ? 2 : undefined,
              borderRight: isSmallScreen ? undefined : 2,
              borderColor: 'divider',
            }}>
            {tabsToShow.map((tab) => (
              <Tab
                sx={{
                  '& .MuiTouchRipple-root': {
                    width: '60px', // Adjust the width as needed
                  },
                  width: '60px',
                  paddingX: 0,
                  alignItems: isSmallScreen ? undefined : 'flex-start',
                  justifyContent: isSmallScreen ? undefined : 'flex-start',
                }}
                key={`tab-SettingsPageTabs.${tab}`}
                label={
                  <Typography variant="subtitle2" fontWeight="700">
                    {t(`editOrderPage.tabs.${tab}`)}
                  </Typography>
                }
                value={tab}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid
          p={2}
          container
          flexDirection="column"
          xs={isSmallScreen ? undefined : 11}
          sx={{
            overflowY: 'auto',
            maxHeight: '90vh',
          }}>
          {tabContent[activeTab] || null}
        </Grid>
      </Grid>
    </>
  );
};
