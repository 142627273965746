import useSWR from 'swr';
import { OrderData, OrderService } from '../../api';

const fetcher = async ({ eventId }: { eventId: string }) => OrderService.getEventOrders(eventId);

type UseEventOrdersProps = {
  eventId?: string;
};

export const useEventOrders = ({ eventId }: UseEventOrdersProps) => {
  // adding token to swr key for disconnects
  const { data, error, isLoading, mutate } = useSWR(
    eventId
      ? {
          eventId,
          opId: 'getEventOrders',
        }
      : null,
    fetcher,
    { refreshInterval: 10000 },
  );
  const addOrder = (order: OrderData) => {
    void mutate([...(data || []), order]);
  };
  const removeOrder = (order: OrderData) => {
    void mutate((data || []).filter((o: OrderData) => o.id !== order.id));
  };

  const updateOrder = (orderToUpdate: OrderData) => {
    void mutate((data || []).map((o: OrderData) => (o.id === orderToUpdate.id ? orderToUpdate : o)));
  };

  return {
    items: data || [],
    isLoading,
    isError: error,
    addOrder,
    removeOrder,
    updateOrder,
  };
};
