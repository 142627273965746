import './OrderPage.scss';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccountData, AccountService } from '../api';

import PageLoading from '../common/PageLoading';
import './WaitingList.scss';
import { WidgetThemeProvider } from './theme/Theme';
import { deepOmitNull } from './utils';

export const WaitingListThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { accountId } = useParams();
  const [account, setAccount] = useState<AccountData | undefined>(undefined);

  useEffect(() => {
    void (async () => {
      const accountData = (await AccountService.getAccount(accountId)) as AccountData;
      setAccount(accountData);
    })();
  }, [accountId]);

  if (!account) {
    return <PageLoading />;
  }

  const externalTheme = deepOmitNull(account.custom_theme);

  return (
    // @ts-ignore
    <WidgetThemeProvider remoteThemeOverride={externalTheme}>{children}</WidgetThemeProvider>
  );
};
