import React from 'react';
import { Box } from '@mui/material';
import TimeUtils from '../../../common/TimeUtils';
import { useLanguage } from '../../../common/GeneralUtils';

const HoursBordersHeader = (tz) => {
  const allDayHours = TimeUtils.getAllDayHours(tz);
  const language = useLanguage();

  const getFormattedHour = (hourMoment) => {
    if (language === 'he') {
      const ampm = hourMoment.locale('en').format('a');
      return hourMoment.format('h ') + (ampm === 'am' ? 'לפ' : 'אח');
    }
    return hourMoment.format('h a');
  };

  return (
    <>
      {allDayHours.map((hourMoment, index) => {
        return (
          <Box key={index} sx={{ width: '100%', display: 'flex', fontSize: '12px' }}>
            <Box
              className="flex-1"
              sx={{
                color: '#70757a',
                marginTop: '-8px',
                textAlign: 'right',
                paddingRight: '4px',
              }}>
              {index !== 0 && getFormattedHour(hourMoment)}
            </Box>
            <Box
              sx={{
                borderBottom: index === 23 ? 'none' : '1px solid #e6e6e6',
                width: '12px',
                height: '50px',
                boxSizing: 'border-box',
              }}
            />
          </Box>
        );
      })}
    </>
  );
};

export default HoursBordersHeader;
