import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelectField = styled(Select)(
  ({ lineColor, textColor }: { lineColor?: string; textColor?: string }) => ({
    '& .MuiSelect-icon': {
      color: textColor,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: lineColor,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: lineColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: lineColor,
    },
    '& .MuiInputLabel-root': {
      color: lineColor,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: lineColor, // or the color you want on focus
    },
    '& .MuiInputBase-input': {
      color: textColor,
    },
    '& .MuiInput-root': {
      '& fieldset': {
        borderColor: lineColor,
      },
      '&:hover fieldset': {
        borderColor: lineColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: lineColor,
      },
    },
  }),
);
