import { Popper, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';

const ResponsivePopper = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { children } = props;
  if (fullScreen) {
    return (
      <Dialog fullScreen {...props}>
        {children}
      </Dialog>
    );
  }
  return (
    <Popper {...props} sx={{ zIndex: '200' }}>
      {children}
    </Popper>
  );
};

export default ResponsivePopper;
