import * as React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

const CircularLoader = () => {
  return (
    <Box
      sx={{
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CircularProgress />
    </Box>
  );
};

export default CircularLoader;
