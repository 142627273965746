/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderPageTypes {
  PRIVATE = 'private',
  EVENT = 'event',
}
