/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ReferralSource {
  SOCIAL_MEDIA = 'social_media',
  GOOGLE = 'google',
  NEWSLETTER = 'newsletter',
  WORD_OF_MOUTH = 'word_of_mouth',
  REVIEW_SITES = 'review_sites',
  RETURNING = 'returning',
  OTHER = 'other',
}
