import React from 'react';
import moment from 'moment';
import {
  AvailablePickersDay,
  NotAvailablePickersDay,
  OccupiedPickersDay,
  RunningOutPickersDay,
} from '../DateAndTimeStep';
import { DayType, resolveDayType } from '../DayType';

export interface CustomDayProps {
  date: any;
  pickersDayProps: any;
}

export const CustomDay: React.FC<CustomDayProps> = (props: any) => {
  const { date, minDate, availableDatesDict, minDateForOrderBasedOnAcctPref, troubleshoot, ...pickersDayProps } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const resolvedDate = props.day || date;
  const dayType = resolveDayType({
    date: resolvedDate,
    availableDatesDict,
    minDate: moment.min(minDate, minDateForOrderBasedOnAcctPref),
  });
  const shouldDisableBasedOnAccountPrefferences = !resolvedDate.isSameOrAfter(minDateForOrderBasedOnAcctPref);

  switch (dayType) {
    case DayType.AVAILABLE_DAY:
      return <AvailablePickersDay {...pickersDayProps} disable={shouldDisableBasedOnAccountPrefferences} />;
    case DayType.NOT_AVAILABLE_DAY:
      return <OccupiedPickersDay {...pickersDayProps} />;
    case DayType.FULLY_BOOKED_DAY:
      return <NotAvailablePickersDay {...pickersDayProps} disabled={troubleshoot ? false : pickersDayProps.disabled} />;
    case DayType.RUNNING_OUT_DAY:
      return <RunningOutPickersDay {...pickersDayProps} />;
    default:
      return <NotAvailablePickersDay {...pickersDayProps} />;
  }
};
