import './LocationWithOptionsUiObject.scss';
import React, { useState } from 'react';
import { Checkbox, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../common/GeneralUtils';
import './SwitchUiObject.scss';
import UiObjectUtils from './UiObjectUtils';
import { OrderPageData } from '../../api';
import { priceFormatter } from '../utils';

type SwitchUiObjectProps = {
  uiObject: any; // UIObjectData is not properly extracted from api as it has classes
  orderState: any;
  setCustomOrderDetailValue: any;
};
const SwitchUiObject = ({ uiObject, orderState, setCustomOrderDetailValue }: SwitchUiObjectProps) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const orderPage = orderState.orderPage as OrderPageData;
  const getDefaultValue = () => {
    if (uiObject.data_type === 'custom' && orderState.details && orderState.details.custom_details) {
      return orderState.details.custom_details[uiObject.name];
    }
    return false;
  };

  const [checked, setChecked] = useState(getDefaultValue());
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    setCustomOrderDetailValue(uiObject.name, event.target.checked);
  };

  if (!UiObjectUtils.getConditionResult(uiObject.condition, orderState)) {
    return null;
  }
  // @ts-ignore
  const priceObj = orderPage.price.find((x) => x.name === uiObject.name)?.price;
  const priceIsPerPerson = priceObj.price_per_additional_value > 0;

  return (
    <div className="switch-ui-object">
      <Grid container flexDirection="row" wrap="nowrap" alignItems="flex-start">
        <Checkbox
          checked={checked}
          onChange={handleChange}
          sx={{
            paddingTop: '4px',
            paddingInlineStart: '0px',
            color: (theme) => theme.customTheme.palette.border,
            '&.Mui-checked': {
              color: (theme) => theme.customTheme.palette.primary,
            },
          }}
        />
        <Grid item container flexDirection="column">
          <Grid item container alignItems="flex-end" gap={0.5}>
            <Typography>{uiObject.label[language]}</Typography>
            {uiObject.link_to_details && (
              <a className="link" target="_blank" href={uiObject.link_to_details} rel="noreferrer">
                <Typography variant="subtitle2" color="gray" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  {t('uiObjects.includes')}
                </Typography>
              </a>
            )}
          </Grid>
          <Grid item container gap={0.5}>
            <Typography variant="subtitle2" color="gray">
              +{priceFormatter(priceObj.base_price, orderState.account)}
            </Typography>
            <Typography variant="subtitle2" color="gray">
              {priceIsPerPerson ? t('uiObjects.perPerson') : null}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SwitchUiObject;
