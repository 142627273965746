import { Grid, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import './MarketPlaceHeader.scss';
import { useTranslation } from 'react-i18next';
import { theme } from '../common/GeneralUtils';
import WhatsAppButton from '../marketingsite/WhatsAppButton';

const MarketPlaceHeader = ({
  activeTopBar,
  showHomePageLink,
  withReturnButton,
  secondaryWhatsappButton,
}: {
  activeTopBar?: boolean;
  showHomePageLink?: boolean;
  withReturnButton?: boolean;
  secondaryWhatsappButton?: boolean;
}) => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const redirect = () => {
    window.location.href = `${withReturnButton ? '/vouchers' : '/'}`;
  };

  return (
    <header className={`marketplace-header${activeTopBar ? ' active-marketplace-header' : ''}`}>
      <Grid className="logo-container" onClick={redirect}>
        <img className="logo-dark" src="/images/logos/monkeybook-dark.png" />
        <img className="logo-light" src="/images/logos/monkeybook-light.png" />
      </Grid>
      {showHomePageLink && (
        <div className="links">
          <a className="active">{t('marketplace.header.homepage')}</a>
        </div>
      )}

      <Grid
        container
        className="login-container"
        gap={1}
        flexDirection="row"
        justifyContent={smallScreen ? 'center' : 'flex-end'}
        mt={2}>
        <Grid item width={'152px'}>
          <WhatsAppButton
            secondaryWhatsappButton={secondaryWhatsappButton}
            cta={t('marketplace.header.vouchersCta')}
            messageText={'Hey Webook! I would like to join for free'}
          />
        </Grid>
        <Grid item mb={2} width={'152px'}>
          <Button fullWidth onClick={redirect} variant="outlined">
            {withReturnButton ? t('marketplace.header.return') : t('marketplace.header.home')}
          </Button>
        </Grid>
      </Grid>
    </header>
  );
};

export default MarketPlaceHeader;
