import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { formatDate } from '../../../common/formatDate';
import { useAuth } from '../../../session/InternalAuthProvider';
import { AccountData } from '../../../api';
import { DialogIntent } from './UserAvailabilityDialog';

export enum DeletionOption {
  SINGLE = 'single',
  FROM_NOW_ON = 'from_now_on',
}

type UserAvailabilityDeletionConfirmationProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onDeleteCallback: (deletionOption: DeletionOption) => void;
  dateToDeleteFrom: string;
  dialogIntent: DialogIntent;
};
export const UserAvailabilityDeletionConfirmation = ({
  isOpen,
  setIsOpen,
  onDeleteCallback,
  dateToDeleteFrom,
  dialogIntent,
}: UserAvailabilityDeletionConfirmationProps) => {
  const { authState } = useAuth();

  const { t } = useTranslation();
  const { account } = authState;
  const [deletionOption, setDeletionOption] = useState<DeletionOption>(DeletionOption.SINGLE);

  const { longDateFormat } = formatDate(account as unknown as AccountData);

  return (
    <ConfirmationDialog
      title={t('userAvailability.deleteDialog.title')}
      content={
        dialogIntent === DialogIntent.UPDATE_REPEATING ? (
          <Grid container flexDirection="column">
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup
                  value={deletionOption}
                  onChange={(e) => setDeletionOption(e.target.value as DeletionOption)}>
                  <FormControlLabel
                    value={DeletionOption.SINGLE}
                    control={<Radio />}
                    label={t('userAvailability.deleteDialog.single')}
                  />
                  <FormControlLabel
                    value={DeletionOption.FROM_NOW_ON}
                    control={<Radio />}
                    label={t('userAvailability.deleteDialog.repeating', {
                      date: moment(dateToDeleteFrom).format(longDateFormat),
                    })}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid>
              <Typography>{t('userAvailability.deleteDialog.final')}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography>{t('userAvailability.deleteDialog.final')}</Typography>
          </Grid>
        )
      }
      isOpen={isOpen}
      onClose={(actionConfirmed) => {
        setIsOpen(false);
        if (actionConfirmed) {
          void onDeleteCallback(deletionOption);
        }
      }}
    />
  );
};
