import Grid from '@mui/material/Grid';
import moment from 'moment';
import { TIME_FORMAT } from '../../CalendarWrapper';

type FinalDateDetailsProps = {
  date: moment.Moment;
  timeSeconds: number;
  duration: number;
};
const FinalDateDetails = ({ date, timeSeconds, duration }: FinalDateDetailsProps) => {
  const endTimeSeconds = timeSeconds + duration;

  return (
    <Grid container gap={2}>
      <Grid item>{date.format('dddd, MMMM, DD')}</Grid>
      <Grid item dir="ltr">
        {moment.unix(timeSeconds).tz('utc').format(TIME_FORMAT)} -{' '}
        {moment.unix(endTimeSeconds).tz('utc').format(TIME_FORMAT)}
      </Grid>
    </Grid>
  );
};

export default FinalDateDetails;
