import { useTranslation } from 'react-i18next';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { isEmpty } from 'lodash';
import { getEmployeeNames } from '../../../../CalendarUtils';
import { PropelUserModel } from '../../../../../api';
import TextWithTooltip from '../../../../../common/ui/TextWithTooltip';

type ManagementPanelSummaryProps = {
  selectedUsersIds: string[];
  users: PropelUserModel[];
  comment?: string;
  isEventOrderPage?: boolean;
};
export const ManagementPanelSummary = ({
  selectedUsersIds,
  users,
  comment,
  isEventOrderPage,
}: ManagementPanelSummaryProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container justifyContent="flex-end">
      {!isEventOrderPage && (
        <Grid
          container
          flexWrap="nowrap"
          alignItems="center"
          gap={1}
          justifyContent={isSmallScreen ? undefined : 'flex-end'}>
          <Grid item>
            <Typography variant="body1">{t('calendar.edit_event.employees')}</Typography>
          </Grid>
          <Grid item display="flex" gap={1}>
            {isEmpty(selectedUsersIds)
              ? t('calendar.edit_event.noemployees')
              : getEmployeeNames(selectedUsersIds, users).map((x) => (
                  <Grid item>
                    <Typography variant="body1">{x}</Typography>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      )}
      <Grid
        container
        flexWrap="nowrap"
        alignItems="center"
        gap={1}
        justifyContent={isSmallScreen ? undefined : 'flex-end'}>
        <Grid item>
          <Typography variant="body1">{t('calendar.edit_event.comment')}</Typography>
        </Grid>
        <Grid item>
          {isEmpty(comment) ? (
            t('calendar.edit_event.noemployees')
          ) : (
            <TextWithTooltip
              text={comment?.slice(0, 20) || ''}
              tooltip={comment || ''}
              width={'120'}
              variant={'body1'}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
