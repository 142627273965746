import { Divider, Grid, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { useGetAccountAuthenticated } from '../../session/swr/useGetAccountAuthenticated';
import { EmailTemplateType, Language } from '../../api';
import { useGetEmailTemplatesParts } from '../swr/useGetEmailTemplatesParts';
import { EmailTemplatesStaticContent, resolveTemplate } from '../utils/resolveTemplate';
import { useReplaceTemplateWIthDefaults } from '../utils/replaceTemplateWIthDefaults';
import RotatingLoader from '../../common/ui/RotatingLoader';
import { EmailTemplateWrapper } from '../templateEditor/EmailTemplateWrapper';

type EditEmailTemplatesProps = {
  orderPageIdOverride?: string;
};
export const EditEmailTemplates = ({ orderPageIdOverride }: EditEmailTemplatesProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { account, updateLocalAccount } = useGetAccountAuthenticated({});
  const isOverrideTemplates = !!orderPageIdOverride;
  const { t } = useTranslation();
  const [selectedEmailTemplateType, setSelectedEmailTemplateType] = useState<EmailTemplateType>(
    EmailTemplateType.CONFIRMATION,
  );
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(account.customer_facing_widgets_languages[0]);
  const { emailTemplatesParts, isLoading } = useGetEmailTemplatesParts();

  const templateHtml: EmailTemplatesStaticContent | undefined = resolveTemplate(
    selectedLanguage,
    emailTemplatesParts,
    selectedEmailTemplateType,
  );
  const templateWithDefaults = useReplaceTemplateWIthDefaults({
    language: selectedLanguage,
    account,
    emailTemplatesStaticContent: templateHtml,
    orderPageIdOverride,
  });
  const handleEmailTemplateTypeChange = (event: React.MouseEvent<HTMLElement>, newOption: EmailTemplateType) => {
    if (!newOption) {
      return;
    }
    setSelectedEmailTemplateType(newOption);
  };
  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newOption: Language) => {
    if (!newOption) {
      return;
    }
    setSelectedLanguage(newOption);
  };
  const shouldShowCustomDesignedTemplate = templateHtml?.sendgridHtml && templateHtml?.sendgridHtml.length > 0;
  if (isLoading) {
    return (
      <Grid
        container
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Grid>
    );
  }

  return (
    <Grid container p={5} flexDirection="column">
      {!isOverrideTemplates && (
        <Grid container justifyContent="center" mb={2} mt={-5}>
          <Typography variant="h4">{t('emailTemplates.title')}</Typography>
        </Grid>
      )}

      <Grid container justifyContent="center">
        <ToggleButtonGroup
          size="large"
          value={selectedEmailTemplateType}
          exclusive
          onChange={handleEmailTemplateTypeChange}
          aria-label="toggle button group">
          <ToggleButton value={EmailTemplateType.CONFIRMATION} aria-label="confirmation">
            <Typography>{t('emailTemplates.confirmation')}</Typography>
          </ToggleButton>
          <ToggleButton value={EmailTemplateType.PRE} aria-label="pre">
            <Typography>{t('emailTemplates.pre')}</Typography>
          </ToggleButton>
          <ToggleButton value={EmailTemplateType.POST} aria-label="post">
            <Typography>{t('emailTemplates.post')}</Typography>
          </ToggleButton>
          <ToggleButton value={EmailTemplateType.VOUCHER} aria-label="voucher">
            <Typography> {t('emailTemplates.voucher')}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {account.customer_facing_widgets_languages.length > 1 && (
        <Grid container mt={2} justifyContent="center" flexDirection="column" alignItems="center" gap={1}>
          <Grid mb={3}>
            <Divider style={{ width: '300px' }} />
          </Grid>
          <Grid item>
            <Typography variant="h5">{t('emailTemplates.language')}</Typography>
          </Grid>
          <ToggleButtonGroup
            value={selectedLanguage}
            exclusive
            onChange={handleLanguageChange}
            aria-label="toggle button group">
            {account.customer_facing_widgets_languages.map((lang: Language) => (
              <ToggleButton key={lang} value={lang} aria-label="confirmation">
                <Typography>{t(`emailTemplates.${lang}`)}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      )}

      {isEmpty(emailTemplatesParts) ? (
        <Grid
          container
          sx={{
            minHeight: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <RotatingLoader />
        </Grid>
      ) : (
        <Grid container justifyContent="center" mt={2}>
          {shouldShowCustomDesignedTemplate ? (
            <Grid container justifyContent="center" mt={2} gap={2}>
              <Typography>{t('emailTemplates.customTemplates')}</Typography>
              <Grid
                container
                style={{
                  minWidth: isSmallScreen ? undefined : '600px',
                  direction: selectedLanguage === 'he' ? 'rtl' : 'ltr',
                }}
                dangerouslySetInnerHTML={{ __html: templateWithDefaults?.sendgridHtml || '' }}
              />
            </Grid>
          ) : (
            <EmailTemplateWrapper
              account={account}
              updateLocalAccount={updateLocalAccount}
              selectedLanguage={selectedLanguage}
              templateType={selectedEmailTemplateType}
              templateWithDefaults={templateWithDefaults}
              orderPageIdOverride={orderPageIdOverride}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
