/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentIntegrationType {
  GROW_PAYMENTS = 'grow_payments',
  STRIPE_PAYMENTS = 'stripe_payments',
  TRANZILA_PAYMENTS = 'tranzila_payments',
  PAY_PLUS_PAYMENT = 'pay_plus_payment',
  GREEN_INVOICE_PAYMENT = 'green_invoice_payment',
}
