import { Grid, Link } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import CopyToClipboardButton from '../../calendar/dialogs/CopyToClipboardButton';

export const CopyLink = ({ link }: { link?: string }) => {
  const { t } = useTranslation();
  if (!link) {
    return null;
  }
  return (
    <Grid container width="100%" flexDirection="row" gap={2} mt={2}>
      <Grid item>
        <CopyToClipboardButton text={link} />
      </Grid>
      <Grid item>
        <Link href={link} color="inherit" target="_blank">
          <Typography>{t('vouchers.linkToRedumption')}</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
