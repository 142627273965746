import React from 'react';
import { OrderPageData } from '../../../api';
import { EmailTemplatesPage } from '../../../emailTemplates/EmailTemplatesPage';

type RestrictionsSettingsSectionProps = {
  orderPage: OrderPageData;
};

export const CommunicationSection = ({ orderPage }: RestrictionsSettingsSectionProps) => {
  return <EmailTemplatesPage orderPageIdOverride={orderPage.id} />;
};
