import { Grid, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Field, FieldProps, FormikHelpers } from 'formik';
import moment from 'moment';
import { Clear } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { OrderPageUpdate } from '../OrderPageSettingsPage';
import { WorkingHoursData } from '../../api';

type workingHoursProps = {
  isSubmitting: boolean;
  fieldValueDayIndex: string;
  setFieldValue: FormikHelpers<OrderPageUpdate>['setFieldValue'];
  initialWorkingHours?: WorkingHoursData;
};

export const WorkingHoursFixed = ({
  isSubmitting,
  fieldValueDayIndex,
  setFieldValue,
  initialWorkingHours,
}: workingHoursProps) => {
  const { readOnly } = UseAuthPermissions();
  const [deletedFixedValues, setDeletedFixedValues] = useState<number[]>([]);
  const [addingNewTime, setAddingNewTime] = useState(false);
  const [newTime, setNewTime] = useState(moment().startOf('day'));
  const formIsDisabled = readOnly || isSubmitting;
  return (
    <Grid display="flex" gap={1}>
      <Field name={`${fieldValueDayIndex}.start_seconds`}>
        {({ field }: FieldProps<number[]>) => {
          const deleted = initialWorkingHours?.start_seconds?.filter((item) => !field.value.includes(item));
          // it wont be always true, but faster for now
          if (deleted && deleted?.length !== deletedFixedValues.length) {
            setDeletedFixedValues(deleted);
          }
          return (
            <Grid container gap={2}>
              <Grid item>
                {addingNewTime ? (
                  <MobileTimePicker
                    {...field}
                    sx={{
                      width: '70px',
                      '&& .MuiInputBase-root .MuiInputBase-input': {
                        height: '10px',
                      },
                    }}
                    value={newTime}
                    ampm={false}
                    minutesStep={15}
                    onChange={(newValue) => setNewTime(newValue!)}
                    onAccept={(newValue) => {
                      void setFieldValue(
                        field.name,
                        [...field.value, newValue!.hours() * 3600 + newValue!.minutes() * 60].sort((a, b) => b - a),
                      );
                      setAddingNewTime(false);
                      setNewTime(moment().startOf('day'));
                    }}
                  />
                ) : (
                  <Button
                    disabled={formIsDisabled}
                    onClick={() => {
                      setAddingNewTime(true);
                    }}
                    variant="outlined"
                    sx={{ height: '40px' }}>
                    <Add />
                  </Button>
                )}
              </Grid>
              {[...field.value, ...deletedFixedValues]
                ?.sort((a, b) => b - a)
                .map((x) => {
                  const deletedValue = deletedFixedValues.find((val) => val === x);
                  const addedValue =
                    field.value.find((val) => val === x) &&
                    !initialWorkingHours?.start_seconds?.find((val) => val === x);
                  return (
                    <Grid item>
                      <TextField
                        disabled
                        sx={{
                          border: deletedValue ? '2px solid red' : addedValue ? '2px solid #47b406' : undefined,
                          borderRadius: '8px',
                          width: deletedValue ? '70px' : '90px',
                          '&& .MuiInputBase-root': {
                            paddingLeft: '0px',
                          },
                        }}
                        size="small"
                        value={moment().startOf('day').add(x, 'seconds').format('HH:mm')}
                        variant="outlined"
                        InputProps={{
                          endAdornment: deletedValue ? undefined : (
                            <IconButton
                              disabled={formIsDisabled}
                              onClick={() => {
                                setDeletedFixedValues([...deletedFixedValues, x]);
                                void setFieldValue(
                                  field.name,
                                  field.value.filter((val) => val !== x).sort((a, b) => b - a),
                                );
                              }}
                              size="small">
                              <Clear />
                            </IconButton>
                          ),
                          sx: {
                            paddingRight: 0,
                            color: 'black',
                            '& .MuiInputAdornment-positionEnd': {
                              marginLeft: 0,
                            },
                          },
                        }}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          );
        }}
      </Field>
    </Grid>
  );
};
