import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { AddCircle, Edit, Loop, WarningAmber } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { UserAvailabilityOut, WorkDayType, WorkingDaysData, WorkingHoursData } from '../api';
import { AvailabilityCircle } from './AvailabilityCircle';

type EmployeeAvailabilityCellProps = {
  userAvailabilities: Record<string, UserAvailabilityOut[]>;
  userWorkingDays?: WorkingDaysData;
  dateToRender: string;
  onCellClicked: (date: string) => void;
  isViewingOtherUser: boolean;
};
export const EmployeeAvailabilityCell = ({
  userAvailabilities,
  userWorkingDays,
  dateToRender,
  onCellClicked,
  isViewingOtherUser,
}: EmployeeAvailabilityCellProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCellHovered, setIsCellHovered] = useState(false);
  const userAvailabilitiesInDate = userAvailabilities[dateToRender] || [];
  const isDayInFuture = moment(dateToRender).isSameOrAfter(moment());
  const workingDayHours = userWorkingDays
    ? (userWorkingDays as Record<string, WorkingHoursData>)[
        moment(dateToRender).locale('en').format('dddd').toLowerCase()
      ]
    : undefined;
  const handleSetIsCellHovered = () => {
    if (isDayInFuture) {
      setIsCellHovered(true);
    }
  };
  const shouldShowDivider =
    isSmallScreen && workingDayHours?.work_day_type !== WorkDayType.NOT_WORKING && !isEmpty(userAvailabilitiesInDate);
  return (
    <Grid
      container
      position="relative"
      overflow="hidden"
      flexDirection="column"
      height={'100%'}
      onClick={() => onCellClicked(dateToRender)}
      p="2px"
      sx={{
        '&:hover': {
          border: '1px solid black',
        },
      }}
      onMouseEnter={() => handleSetIsCellHovered()}
      onMouseLeave={() => setIsCellHovered(false)}>
      {isCellHovered && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bgcolor="rgba(255, 255, 255, 0.8)"
          sx={{ cursor: 'pointer' }}>
          {isEmpty(userAvailabilitiesInDate) ? (
            <AddCircle fontSize="large" style={{ color: '#4ABD00' }} />
          ) : (
            <Edit fontSize="large" style={{ color: '#4ABD00' }} />
          )}
        </Grid>
      )}
      {workingDayHours?.work_day_type !== WorkDayType.NOT_WORKING && (
        <Grid item container gap={1} alignItems="center">
          <AvailabilityCircle type="working_days" />
          <Typography variant="subtitle2">{`${moment
            .unix(workingDayHours?.from_time || 0)
            .tz('utc')
            .format('H:mm')}-${moment
            .unix(workingDayHours?.to_time || 0)
            .tz('utc')
            .format('H:mm')}`}</Typography>
        </Grid>
      )}
      {shouldShowDivider && <Divider style={{ marginRight: '4px', marginLeft: '4px' }} />}
      {isViewingOtherUser && (
        <Grid my={1} width={'50px'}>
          <WarningAmber style={{ color: '#EF7B2B' }} />
        </Grid>
      )}
      {userAvailabilitiesInDate.map((item) => (
        <Grid item container key={item.id} gap={item.recurrent_availability_id ? 0 : 1} alignItems="flex-start">
          <Grid mt={'4px'}>
            <AvailabilityCircle type={item.type} />
          </Grid>
          {item.recurrent_availability_id && <Loop style={{ width: '14px', padding: '0px', marginTop: '-2px' }} />}
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography variant="subtitle2">
              {item.all_day
                ? t('userAvailability.allDay')
                : `${moment
                    .unix(item.start_time || 0)
                    .tz('utc')
                    .format('H:mm')}-${moment
                    .unix(item.end_time || 0)
                    .tz('utc')
                    .format('H:mm')}`}
            </Typography>
            <Grid mt={'-8px'}>
              {item.description && <Typography variant="subtitle2">{item.description}</Typography>}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
