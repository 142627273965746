import React from 'react';
import { OrderService } from '../api';
import { OrdersTable } from './OrdersTable';

export const AllOrders = () => {
  const opId = 'getAllOrders';
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const fetchingMethod = OrderService.getAllOrders;
  return <OrdersTable fetchingMethod={fetchingMethod} opId={opId} />;
};
