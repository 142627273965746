import React, { useState } from 'react';
import { Box, ButtonBase, Grid, Icon } from '@mui/material';
import { NavigationItemButton } from './NavigationItemButton';
import { NavigationHeader, NavigationItem } from './MainSidebar';

type NavigationMainButtonProps = {
  navigationHeader: NavigationHeader;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
};
export const NavigationHeaderButton = ({ navigationHeader, setIsSidebarOpen }: NavigationMainButtonProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Grid>
      <ButtonBase
        sx={{ width: '100%', height: '44px' }}
        className={'dashboard-link'}
        onClick={() => setIsExpanded(!isExpanded)}>
        <Icon className="sidebar-link-icon" sx={{ width: 0, direction: 'ltr!important' }}>
          {navigationHeader.icon}
        </Icon>
        <span className="sidebar-link-text">{navigationHeader.name}</span>
        <Box mx="auto" />
      </ButtonBase>
      {isExpanded &&
        navigationHeader.navigationSubItems?.map((navigationItem: NavigationItem | undefined) =>
          navigationItem ? (
            <NavigationItemButton
              key={navigationItem.key}
              setIsSidebarOpen={setIsSidebarOpen}
              navigationItem={navigationItem}
              height="24px"
            />
          ) : null,
        )}
    </Grid>
  );
};
