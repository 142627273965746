import { Grid, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme, useLanguage } from '../common/GeneralUtils';

const Title = ({ children }: { children: string }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Typography style={{ fontSize: isSmallScreen ? '22px' : '40px', fontWeight: 700, paddingTop: '8px' }}>
      {children}
    </Typography>
  );
};
const SubTitle = ({ children }: { children: any }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <Typography style={{ fontSize: isSmallScreen ? '13px' : '20px', fontWeight: 500 }}>{children}</Typography>;
};

export const PrivacyComponent = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const language = useLanguage();
  return language === 'he' ? (
    <Grid container padding={isSmallScreen ? 3 : 10} gap={2} flexDirection="column" dir="ltr">
      <Title>Privacy Policy</Title>
      <SubTitle>Last updated: 10.12.2023</SubTitle>
      <Title>Introduction</Title>
      <SubTitle>
        Welcome to Monkeybook! This Privacy Policy outlines how we collect, use, store, and share information obtained
        from Google users through our app.
      </SubTitle>
      <Title>Information We Collect when connecting our Google calender integration</Title>
      <SubTitle>We collect the following information from Google users:</SubTitle>
      <SubTitle>Email address</SubTitle>
      <Title>How We Use the Information?</Title>
      <SubTitle>
        We use the collected information to provide and improve our services, including but not limited to:
      </SubTitle>
      <SubTitle>Connecting to google calender in order to sync and manage Monkeybook events </SubTitle>
      <Title>How We Share Information?</Title>
      <SubTitle>We do not share the information with any other third party.</SubTitle>
      <Title>Limited Use Requirements Disclosure</Title>
      <SubTitle>We comply with the Limited Use Policy. As required, we provide the following disclosure:</SubTitle>
      <SubTitle>
        Our use and transfer of information received from Google APIs adhere to Google API Services User Data Policy,
        including the Limited Use requirements. For more information, please review the{' '}
        <Link to="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">
          Google API Services User Data Policy.
        </Link>
      </SubTitle>
      <Title>Updates to Privacy Policy</Title>
      <SubTitle>
        We may update our Privacy Policy from time to time. Any changes will be reflected on this page.
      </SubTitle>
      <Title>
        If you have any questions or concerns about our Privacy Policy, please contact us at info@monkeybook.io
      </Title>
    </Grid>
  ) : (
    <Grid container padding={isSmallScreen ? 3 : 10} gap={2} flexDirection="column">
      <Title>Privacy Policy</Title>
      <SubTitle>Last updated: 10.12.2023</SubTitle>
      <Title>Introduction</Title>
      <SubTitle>
        Welcome to Monkeybook! This Privacy Policy outlines how we collect, use, store, and share information obtained
        from Google users through our app.
      </SubTitle>
      <Title>Information We Collect when connecting our Google calender integration</Title>
      <SubTitle>We collect the following information from Google users:</SubTitle>
      <SubTitle>Email address</SubTitle>
      <Title>How We Use the Information?</Title>
      <SubTitle>
        We use the collected information to provide and improve our services, including but not limited to:
      </SubTitle>
      <SubTitle>Connecting to google calender in order to sync and manage Monkeybook events </SubTitle>
      <Title>How We Share Information?</Title>
      <SubTitle>We do not share the information with any other third party.</SubTitle>
      <Title>Limited Use Requirements Disclosure</Title>
      <SubTitle>We comply with the Limited Use Policy. As required, we provide the following disclosure:</SubTitle>
      <SubTitle>
        Our use and transfer of information received from Google APIs adhere to Google API Services User Data Policy,
        including the Limited Use requirements. For more information, please review the{' '}
        <Link to="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">
          Google API Services User Data Policy.
        </Link>
      </SubTitle>
      <Title>Updates to Privacy Policy</Title>
      <SubTitle>
        We may update our Privacy Policy from time to time. Any changes will be reflected on this page.
      </SubTitle>
      <Title>
        If you have any questions or concerns about our Privacy Policy, please contact us at info@monkeybook.io
      </Title>
    </Grid>
  );
};
