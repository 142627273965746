/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PolicyTypes {
  PERCENT = 'percent',
  FIXED = 'fixed',
}
