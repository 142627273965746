import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const Swipe = (props) => {
  const { onSwipeLeft, onSwipeRight, sx, shouldBlockSwiping } = props;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  useEffect(() => {
    if (shouldBlockSwiping) {
      setTouchStart(null);
      setTouchEnd(null);
    }
  }, [shouldBlockSwiping]);
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      onSwipeLeft();
    }
    if (isRightSwipe) {
      onSwipeRight();
    }
  };
  const { children } = props;
  return (
    <Box sx={sx} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      {children}
    </Box>
  );
};

export default Swipe;
