import { Grid } from '@mui/material';
import React from 'react';
import BusinessCard from './BusinessCard';
import PageLoading from '../common/PageLoading';
import { MarketPlaceItemData } from '../api/models/MarketPlaceItemData';

const BusinessCardList = (props: { marketPlaceItems: MarketPlaceItemData[] }) => {
  const { marketPlaceItems } = props;

  if (!marketPlaceItems) return <PageLoading />;

  return (
    <Grid container justifyContent="center" direction="row" spacing={4}>
      {marketPlaceItems
        .sort((a: MarketPlaceItemData, b: MarketPlaceItemData) => a.order! - b.order!)
        .map((marketPlaceItem: MarketPlaceItemData, index) => {
          return (
            <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={3} className="business-card-container">
              <BusinessCard marketPlaceItem={marketPlaceItem} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default BusinessCardList;
