import { Box, ClickAwayListener, Dialog, Fade, Grid, Icon, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  AttachMoney,
  Close,
  Delete,
  Event,
  Numbers,
  People,
  Redeem,
  Schedule,
  ShoppingCart,
  Subject,
  Title,
  Weekend,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import '../calendar/calendar-items/OrderBoxPopper.scss';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../common/GeneralUtils';
import { AccountData, DiscountOut, DiscountService, VoucherOut, VoucherService, VoucherStatus } from '../api';
import AuthStateUtils from '../session/AuthStateUtils';
import TimeUtils from '../common/TimeUtils';
import PageLoading from '../common/PageLoading';
import { useAuth } from '../session/InternalAuthProvider';
import { priceFormatter } from '../order_page/utils';
import { CopyLink } from '../common/ui/CopyLink';
import { whatsAppLinkFormatter } from '../common/whatsAppLinkFormatter';
import RotatingLoader from '../common/ui/RotatingLoader';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { useCalendar } from '../calendar/CalendarContext';

interface VoucherPopperProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  voucher?: VoucherOut;
  archiveVoucher: () => void;
}

const VoucherPopper = (props: VoucherPopperProps) => {
  const { open, voucher, setOpen, archiveVoucher } = props;
  const language = useLanguage();
  const { t } = useTranslation();
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [discount, setDiscount] = useState<DiscountOut | undefined>(undefined);
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { readOnly } = UseAuthPermissions();
  // @ts-ignore
  const { openEditOrderDialog } = useCalendar();

  useEffect(() => {
    const getDiscount = async () => {
      if (voucher?.discount_id && open) {
        setLoadingDiscount(true);
        // currently supported just percentage
        const discountOut = await DiscountService.getDiscountById(voucher.discount_id);
        setLoadingDiscount(false);
        setDiscount(discountOut);
        setLoadingDiscount(false);
      }
      if (!voucher?.discount_id) {
        setDiscount(undefined);
      }
    };

    void getDiscount();
  }, [open, voucher]);
  if (!voucher) {
    return open ? <PageLoading /> : null;
  }
  const orderPage = AuthStateUtils.getOrderPage(authState, undefined, voucher.order_page_id);
  const link = `${window.location.origin}/order/${voucher.account_id}/${voucher.experience_id}/${voucher.order_page_id}?vouchercode=${voucher.code}`;
  const deleteVoucher = async () => {
    if (voucher.status === VoucherStatus.ACTIVE) {
      await VoucherService.archiveVoucher({ voucher_id: voucher.id });
      archiveVoucher();
      setOpen(false);
    }
  };
  const redeemVoucher = () => {
    if (voucher.status === VoucherStatus.ACTIVE) {
      openEditOrderDialog(null, voucher.order_page_id, null, null, voucher);
      setOpen(false);
    }
  };
  return (
    <Dialog open={open}>
      <Fade in timeout={520}>
        <div className="height-100">
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Paper elevation={24} className="order-box-popper-content height-100">
              <Grid container direction="row" justifyContent="flex-end" padding="2px">
                <Tooltip
                  title={voucher.status !== VoucherStatus.ACTIVE ? t('vouchers.redeemdisabled') : t('redeem')}
                  arrow>
                  <span>
                    <IconButton onClick={redeemVoucher} disabled={voucher.status !== VoucherStatus.ACTIVE || readOnly}>
                      <Redeem />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={voucher.status !== VoucherStatus.ACTIVE ? t('vouchers.deleteDisabled') : t('vouchers.archive')}
                  arrow>
                  <span>
                    <IconButton onClick={deleteVoucher} disabled={voucher.status !== VoucherStatus.ACTIVE || readOnly}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>

                <IconButton onClick={() => setOpen(false)}>
                  <Tooltip title={t('close')} arrow>
                    <Close />
                  </Tooltip>
                </IconButton>
              </Grid>

              <Box className="details-container">
                <Box className="detail-container">
                  <div className="circle" />
                  <Box className="name-container">{`${voucher.firstname} ${voucher.lastname}`}</Box>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <Title />
                  </div>
                  <Box>{orderPage.label[language]}</Box>
                </Box>
                <Box className="detail-container">
                  <CopyLink link={link} />
                </Box>

                {voucher.order_number != null && voucher.order_number !== 0 && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Numbers />
                    </div>
                    <Tooltip title={t('order_number')} placement="top" arrow>
                      <Box>
                        <span>{t('order_number')}</span>
                        <span> &#35;</span>
                        <span>{voucher.order_number}</span>
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                <Box className="detail-container">
                  <div className="icon-container">
                    <People />
                  </div>
                  <Box>
                    {voucher.persons} {t('calendar.order_popper.persons')}
                  </Box>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <Schedule />
                  </div>
                  <Tooltip title={t('calendar.order_popper.duration')} placement="top" arrow>
                    <Box>{TimeUtils.formatDuration(voucher.duration, t)}</Box>
                  </Tooltip>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">{loadingDiscount ? <RotatingLoader /> : <AttachMoney />}</div>

                  <Tooltip title={t('calendar.order_popper.price')} placement="top" arrow>
                    <Box>
                      <Typography style={{ textDecoration: discount ? 'line-through' : undefined }}>
                        {priceFormatter(voucher.price, account)}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>

                {discount && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <AttachMoney />
                    </div>
                    <Tooltip title={t('calendar.order_popper.priceAfterDiscount')} placement="top" arrow>
                      <Box>{`${priceFormatter(((100 - (discount?.value || 0)) * voucher.price) / 100, account)} (${
                        discount.code
                      })`}</Box>
                    </Tooltip>
                  </Box>
                )}
                {voucher.custom_details.weekendAndIsraeliHolidays && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Weekend />
                    </div>
                    <Box>{t('calendar.order_popper.includes_weekend')}</Box>
                  </Box>
                )}
                {voucher.custom_details &&
                  orderPage.custom_details.map((custom_detail: any, index: number) => {
                    return (
                      <Box key={index} className="detail-container">
                        <div className="icon-container">
                          <Icon>{custom_detail.icon}</Icon>
                        </div>
                        <Tooltip title={custom_detail.name} placement="top" arrow>
                          <Box>
                            {custom_detail.type === 'str' && <span>{voucher.custom_details[custom_detail.name]}</span>}
                            {custom_detail.type === 'location' && (
                              <a
                                className="link"
                                target="_blank"
                                href={`https://maps.google.com/?q=term=${voucher.custom_details[custom_detail.name]}`}
                                rel="noreferrer">
                                {voucher.custom_details[custom_detail.name]}
                              </a>
                            )}
                            {custom_detail.type === 'bool' && (
                              <div>
                                {voucher.custom_details[custom_detail.name] ? (
                                  <span>{t('yes')}</span>
                                ) : (
                                  <span>{t('no')}</span>
                                )}
                              </div>
                            )}

                            {custom_detail.type === 'resourceInt' && (
                              <Typography>{voucher.custom_details[custom_detail.name]}</Typography>
                            )}
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })}

                {voucher.custom_personal_details &&
                  orderPage.custom_personal_details.map((custom_detail: any, index: number) => {
                    if (voucher.custom_personal_details[custom_detail.id]) {
                      return (
                        <Box key={index} className="detail-container">
                          <div className="icon-container">
                            <Icon>note</Icon>
                          </div>
                          <Tooltip title={custom_detail.label[language]} placement="top" arrow>
                            <Box>
                              {custom_detail.type === 'multiline' && (
                                <span>{voucher.custom_personal_details[custom_detail.id]}</span>
                              )}
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    }
                    return undefined;
                  })}

                <Box className="detail-container multiple-details-container">
                  <div className="icon-container">
                    <Subject />
                  </div>
                  <Box className="multiple-details-container">
                    {t('calendar.order_popper.name')}: {voucher.firstname} {voucher.lastname}
                    <br />
                    {t('calendar.order_popper.email')}: {voucher.email}
                    <br />
                    {t('calendar.order_popper.phone')}:{' '}
                    <a
                      className="link"
                      target="_blank"
                      href={whatsAppLinkFormatter(voucher.phone, '')}
                      rel="noreferrer">
                      {voucher.phone}
                    </a>
                  </Box>
                </Box>

                <Tooltip title={t('calendar.order_popper.creation_time')} placement="top" arrow>
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Event />
                    </div>
                    <Box>
                      {TimeUtils.getLocalTime(voucher.created_at, account.timezone).format('dddd, MMMM, DD')}
                      &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
                      {TimeUtils.getLocalTime(voucher.created_at, account.timezone).format('HH:mm')}
                    </Box>
                  </Box>
                </Tooltip>

                <Box className="detail-container">
                  <div className="icon-container">
                    <ShoppingCart />
                  </div>
                  <Tooltip title={t('calendar.order_popper.status')} placement="top" arrow>
                    <Box sx={{ textTransform: 'capitalize' }}>
                      {t(`vouchers.voucherstatus.${voucher.status.toLowerCase()}`)}
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </div>
      </Fade>
    </Dialog>
  );
};

export default VoucherPopper;
