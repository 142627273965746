import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from '@mui/icons-material';

const CopyToClipboardButton = ({
  text,
  IconComponent = ContentCopy,
  color,
  width,
}: {
  text: string;
  IconComponent?: any;
  color?: string;
  width?: string;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    void navigator.clipboard.writeText(text);
  };

  return (
    <>
      <IconComponent onClick={handleClick} cursor="pointer" style={{ color, width }} />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpen(false)} severity="success">
          {t('copiedToClipboard')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyToClipboardButton;
