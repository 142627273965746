import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardContent, Divider, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { OrderPageTypes } from '../../api';

type ChooseWorkshopTypeProps = {
  handleOrderPageTypeChange: (orderPageType: OrderPageTypes) => void;
};
export const ChooseWorkshopType = ({ handleOrderPageTypeChange }: ChooseWorkshopTypeProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Grid container flexDirection="column" alignItems="center" mt={isSmallScreen ? 2 : undefined}>
      <Typography variant={isSmallScreen ? 'h6' : 'h4'}>{t('createExperiencePage.chooseType')}</Typography>
      <Grid
        container
        gap={isSmallScreen ? 3 : 10}
        justifyContent="center"
        alignItems="center"
        mt={isSmallScreen ? 3 : 10}>
        <Card
          sx={{
            width: '250px',
            height: isSmallScreen ? '180px' : '250px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <CardActionArea
            onClick={() => handleOrderPageTypeChange(OrderPageTypes.EVENT)}
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Grid container flexDirection="column" gap={1} sx={{ flexGrow: 1 }}>
                <Typography variant={isSmallScreen ? 'subtitle1' : 'h5'}>
                  {t('createExperiencePage.eventEventType')}
                </Typography>
                <Divider />
                <Typography
                  variant={isSmallScreen ? 'subtitle2' : 'subtitle2'}
                  fontWeight={isSmallScreen ? '300' : undefined}>
                  {t('createExperiencePage.eventEventTypeExplain')}
                </Typography>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: '250px',
            height: isSmallScreen ? '180px' : '250px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <CardActionArea
            onClick={() => handleOrderPageTypeChange(OrderPageTypes.PRIVATE)}
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Grid container flexDirection="column" gap={1} sx={{ flexGrow: 1 }}>
                <Typography variant={isSmallScreen ? 'subtitle1' : 'h5'}>
                  {t('createExperiencePage.privateType')}
                </Typography>
                <Divider />
                <Typography
                  variant={isSmallScreen ? 'subtitle2' : 'subtitle2'}
                  fontWeight={isSmallScreen ? '300' : undefined}>
                  {t('createExperiencePage.privateTypeExplain')}
                </Typography>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};
