import * as React from 'react';
import { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useAuthInfo } from '@propelauth/react';
import { AccountData, EmployeesService, PropelUserModel, ShiftCreateDTO, ShiftOut } from '../../api';
import { BootstrapDialog, BootstrapDialogTitle } from '../../common/BootstrapDialog';
import { useCalendar } from '../CalendarContext';
import { getUserFullName, getUserFullNameFromUserId } from '../components/UsersMultiSelect';
import { useShifts } from '../swr/useShifts';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { UseRepeatingDays } from '../components/UseRepeatingDays';
import { useAuth } from '../../session/InternalAuthProvider';
import { useOrders } from '../swr/useOrders';
import { useEvents } from '../swr/useEvents';

const DATE_FORMAT = 'YYYY-MM-DD';
type ShiftDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  shift?: ShiftOut;
  startDate: string;
  endDate: string;
  createNewShiftPayload?: { startTime: number; endTime: number; userId: string; date: string; description?: string };
};

function getTitle(t: any, isUpdatingShift: boolean, isEmployeeUnavailability: boolean): string {
  if (isUpdatingShift && isEmployeeUnavailability) {
    return t('calendar.shift_dialog.title_edit_emp_unavaliability');
  }
  if (isUpdatingShift) {
    return t('calendar.shift_dialog.title_edit');
  }
  if (isEmployeeUnavailability) {
    return t('calendar.shift_dialog.title_add_emp_unavaliability');
  }
  return t('calendar.shift_dialog.title_add');
}

const ShiftDialog = ({ open, setOpen, shift, startDate, endDate, createNewShiftPayload }: ShiftDialogProps) => {
  // @ts-ignore
  const { users } = useCalendar();
  const { readOnly } = UseAuthPermissions();
  const { user: loggedInUser } = useAuthInfo();
  const { authState } = useAuth();
  // @ts-ignore
  const account = authState.account as AccountData;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const [dateOpen, setDateOpen] = useState(false);
  const [date, setDate] = useState(shift?.start_time ? moment.unix(shift?.start_time) : moment().startOf('day'));

  const [startTime, setStartTime] = useState(moment().hours(9).minutes(0).seconds(0));
  const [endTime, setEndTime] = useState(moment().hours(17).minutes(0).seconds(0));

  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);

  const [employeeId, setEmployeeId] = useState<string | undefined>();
  const [shouldPropagateShift, setShouldPropagateShift] = useState(false);

  const [loading, setLoading] = useState(false);
  const [repeating, setRepeating] = useState(false);
  const [description, setDescription] = useState<string | undefined>();
  const isUpdatingShift = !!shift && !createNewShiftPayload;
  const { updateItem: updateShift, addItems: addShifts } = useShifts({ startDate, endDate });
  const { reFetch: reFetchOrders } = useOrders({ startDate, endDate });
  const { reFetch: reFetchEvents } = useEvents({ startDate, endDate });
  const {
    RepeatingDaysComponent,
    days: repeatingDays,
    endDate: repeatingEndDate,
    setEndDate,
  } = UseRepeatingDays({ minDate: date });

  useEffect(() => {
    if (createNewShiftPayload) {
      setStartTime(moment.unix(createNewShiftPayload.startTime).tz('utc'));
      setEndTime(moment.unix(createNewShiftPayload.endTime).tz('utc'));
      setEmployeeId(createNewShiftPayload.userId);
      setDate(moment(createNewShiftPayload.date));
      setDescription(createNewShiftPayload.description);
    } else if (shift) {
      setEmployeeId(shift.employee_id);
      setDate(moment(shift.date));
      setStartTime(moment.unix(shift.start_time).tz('utc'));
      setEndTime(moment.unix(shift.end_time).tz('utc'));
      setDescription(shift.description);
    } else {
      setDate(moment().startOf('day'));
      setStartTime(moment().hours(9).minutes(0).seconds(0));
      setEndTime(moment().hours(17).minutes(0).seconds(0));
      if (users?.length > 0) {
        setEmployeeId(readOnly ? loggedInUser?.userId : users[0].user_id);
      }
    }
  }, [shift, users, createNewShiftPayload]);
  const handleClose = (e?: any, reason?: any) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  // logic should maybe move to backend and be tested there.
  const createShiftsPayload = (): ShiftCreateDTO[] => {
    const baseData: ShiftCreateDTO = {
      date: date.format(DATE_FORMAT),
      employee_id: employeeId || users[0],
      start_time: startTime.hours() * 3600 + startTime.minutes() * 60,
      end_time: endTime.hours() * 3600 + endTime.minutes() * 60,
      should_propagate_to_orders: false,
      description,
    };
    const shiftsToCreate: ShiftCreateDTO[] = [{ ...baseData, should_propagate_to_orders: shouldPropagateShift }];
    if (repeating) {
      let currentDate = moment(date).add(1, 'days');

      while (currentDate.isSameOrBefore(repeatingEndDate)) {
        const dayOfWeek = currentDate.day();
        if (repeatingDays.includes(dayOfWeek)) {
          shiftsToCreate.push({
            ...baseData,
            should_propagate_to_orders: shouldPropagateShift,
            date: currentDate.format(DATE_FORMAT),
          });
        }
        currentDate = currentDate.add(1, 'days');
      }
    }
    return shiftsToCreate;
  };

  const save = async () => {
    setLoading(true);

    const baseData: ShiftCreateDTO = {
      date: date.format(DATE_FORMAT),
      employee_id: employeeId || users[0],
      start_time: startTime.hours() * 3600 + startTime.minutes() * 60,
      end_time: endTime.hours() * 3600 + endTime.minutes() * 60,
      description,
    };
    if (isUpdatingShift) {
      const updatedShift = await EmployeesService.editShift({
        id: shift?.id,
        ...baseData,
      });
      updateShift(updatedShift);
    } else {
      const shiftsToCreate = createShiftsPayload();

      const newShifts = await EmployeesService.createShifts({
        shifts: shiftsToCreate,
      });
      addShifts(newShifts);

      if (shouldPropagateShift) {
        await Promise.all([reFetchOrders(), reFetchEvents()]);
      }
    }

    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        disableRestoreFocus
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {getTitle(t, isUpdatingShift, account.shifts_as_employee_unavailability)}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container flexDirection="column" gap={3} p={2}>
            <FormControl fullWidth>
              <InputLabel id="shift-employee-select">{t('calendar.shift_dialog.employee')}</InputLabel>
              <Select
                disabled={readOnly}
                labelId="shift-employee-select"
                id="shift-employee-select"
                value={employeeId}
                label={t('calendar.shift_dialog.employee')}
                onChange={(e) => setEmployeeId(e.target.value)}
                renderValue={(userId: string) => getUserFullNameFromUserId(users, userId)}>
                {users
                  ?.map((user: PropelUserModel) => ({ ...user, fullName: getUserFullName(user) }))
                  .sort((a: any, b: any) => a.fullName.localeCompare(b.fullName))
                  .map((user: any) => (
                    <MenuItem key={`MenuItem-${user.user_id}`} value={user.user_id}>
                      {user.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              label={t('calendar.shift_dialog.description')}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <DatePicker
              open={dateOpen}
              onOpen={() => setDateOpen(true)}
              onClose={() => setDateOpen(false)}
              label={t('calendar.shift_dialog.date')}
              format="DD/MM/YYYY"
              value={date}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                setDate(newValue);
                if (newValue?.isSameOrAfter(repeatingEndDate)) {
                  setEndDate(newValue);
                }
              }}
              disablePast={readOnly}
            />

            <MobileTimePicker
              minutesStep={15}
              minTime={moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })}
              ampm={false}
              open={startTimeOpen}
              onOpen={() => setStartTimeOpen(true)}
              onClose={() => setStartTimeOpen(false)}
              label={t('calendar.shift_dialog.start_time')}
              value={startTime}
              onChange={(newValue) => {
                setStartTime(newValue!);
              }}
            />

            <MobileTimePicker
              minutesStep={15}
              minTime={startTime}
              ampm={false}
              open={endTimeOpen}
              onOpen={() => setEndTimeOpen(true)}
              onClose={() => setEndTimeOpen(false)}
              label={t('calendar.shift_dialog.end_time')}
              value={endTime}
              onChange={(newValue) => {
                setEndTime(newValue!);
              }}
            />
            {!isUpdatingShift && (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={repeating}
                    label={
                      account.shifts_as_employee_unavailability
                        ? t('calendar.shift_dialog.repeating_emp_unavliability')
                        : t('calendar.shift_dialog.repeating')
                    }
                    onChange={(e: any) => {
                      setRepeating(e.target.checked);
                    }}
                  />
                </FormGroup>

                {repeating && (
                  <Grow in={repeating} timeout={500}>
                    <Grid>
                      <RepeatingDaysComponent />
                    </Grid>
                  </Grow>
                )}
              </>
            )}

            {!isUpdatingShift && !readOnly && !account.shifts_as_employee_unavailability && (
              <FormControlLabel
                control={<Checkbox />}
                checked={shouldPropagateShift}
                label={t('calendar.shift_dialog.propogate')}
                onChange={() => {
                  setShouldPropagateShift(!shouldPropagateShift);
                }}
              />
            )}
          </Grid>
        </DialogContent>
        <Grid p={2}>
          <DialogActions>
            <LoadingButton loading={loading} variant="contained" sx={{ textTransform: 'none' }} onClick={save}>
              {t('save')}
            </LoadingButton>
          </DialogActions>
        </Grid>
      </BootstrapDialog>
    </div>
  );
};

export default ShiftDialog;
