import { Box, Grid } from '@mui/material';
import './OrderBox.scss';
import React, { useState } from 'react';
import { AccountData, ShiftOut } from '../../api';
import ShiftBoxPopper from './ShiftBoxPopper';
import { useAuth } from '../../session/InternalAuthProvider';

const ShiftBox = (props: { shift: ShiftOut; css?: any; viewType: 'month' | 'day'; isUnifiedShift?: boolean }) => {
  const { authState } = useAuth();
  // @ts-ignore
  const account = authState.account as AccountData;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { shift, css, viewType, isUnifiedShift } = props;
  const openShift = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  return (
    <>
      {viewType === 'month' ? (
        // not yet supported
        <Box className="order-box month-order-box">
          <Box className="text-center">
            <p />
          </Box>
        </Box>
      ) : (
        <Grid
          onClick={openShift}
          container
          display="block"
          alignItems="flex-start"
          position="absolute"
          overflow="hidden"
          sx={{
            width: `calc(${css.width}% - ${isUnifiedShift ? 0 : 2}px)`,
            left: `${css.left}%`,
            marginTop: `${css.top}px`,
            height: `${css.height - 2}px`,
            backgroundColor: account.shifts_as_employee_unavailability ? '#9A989872' : '#e0b06e4f',
            border: !account.shifts_as_employee_unavailability ? '1px solid black' : undefined,
            cursor: 'pointer',
            borderRadius: '4px',
          }}>
          <Grid
            item
            container
            flexDirection="column"
            justifyContent="space-around"
            alignContent="center"
            alignItems="center"
          />
        </Grid>
      )}
      <ShiftBoxPopper anchorEl={anchorEl} setAnchorEl={setAnchorEl} shift={shift} />
    </>
  );
};

export default ShiftBox;
