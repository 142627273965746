/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplyCodeInput } from '../models/ApplyCodeInput';
import type { ApplyCodeResponseDTO } from '../models/ApplyCodeResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CodesService {
  /**
   * Apply Code
   * @param requestBody
   * @returns ApplyCodeResponseDTO Successful Response
   * @throws ApiError
   */
  public static applyCode(requestBody: ApplyCodeInput): CancelablePromise<ApplyCodeResponseDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/codes/apply',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
      },
    });
  }
}
