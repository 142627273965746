import { Box, Card, CardContent, CardMedia } from '@mui/material';
import React from 'react';
import './BusinessCard.scss';
import { Place, Subject } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useLanguage } from '../common/GeneralUtils';
import { MarketPlaceItemData } from '../api';

const BusinessCard = (props: { marketPlaceItem: MarketPlaceItemData }) => {
  const { marketPlaceItem } = props;
  const language = useLanguage();

  const images = marketPlaceItem.images.filter((img) => {
    return img.type === 'image';
  });

  return (
    <Link to={`/market/page/${marketPlaceItem.id}`}>
      <Card elevation={1} sx={{ height: 392, maxWidth: 360 }} className="business-card">
        <CardMedia
          component="img"
          width="360"
          height="220"
          image={images[0].value}
          sx={{ borderBottom: '1px solid #eee' }}
        />
        <CardContent>
          <h3>
            <Link to={`/market/page/${marketPlaceItem.id}`}>{marketPlaceItem.title[language]}</Link>
          </h3>
          <Box className="details-container">
            <Box className="detail-container">
              <div className="icon-container">
                <Subject />
              </div>
              <Box>{marketPlaceItem.subtitle[language]}</Box>
            </Box>

            <Box className="detail-container">
              <div className="icon-container">
                <Place />
              </div>
              <Box>
                <Link to={`https://maps.google.com/?q=term=${marketPlaceItem.location.name[language]}`}>
                  {marketPlaceItem.location.name[language]}
                </Link>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default BusinessCard;
