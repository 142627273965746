/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserAvailabilityType {
  AVAILABLE = 'available',
  BLOCKED = 'blocked',
}
