import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useLanguage } from '../../../../../common/GeneralUtils';

// @ts-ignore
export const ChooseWorkshopSelect = ({ orderPages, editOrderState, setOrderPage, setExpandedPanel }) => {
  const language = useLanguage();
  return (
    <FormControl>
      <Select
        value={editOrderState.orderPage.id}
        renderValue={() => (
          <Grid container alignItems="center">
            <div
              style={{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                background: editOrderState.orderPage.color || '#6577b3',
                marginInlineEnd: '12px',
              }}
            />
            <Grid item>{editOrderState.orderPage.label[language]}</Grid>
          </Grid>
        )}
        onChange={(event) => {
          setOrderPage(event.target.value);
          setExpandedPanel(undefined);
        }}>
        {orderPages.map((x: any) => (
          <MenuItem key={`ChooseWorkshopSelect-${x.id}`} value={x.id}>
            {x.label[language]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
