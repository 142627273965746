import React from 'react';
import { useTranslation } from 'react-i18next';
import TitledDrawer from '../../ui/TitledDrawer';
import './ActivityDialog.scss';
import { useCommonDialogs } from '../CommonDialogsContext';
import ActivityLine from '../../../activity/ActivityLine';

const ActivityDialogContent = () => {
  const { activityDialogState } = useCommonDialogs();
  const { activityLineType, data } = activityDialogState;

  return (
    <div className="activity-dialog-content">
      <ActivityLine activityLineType={activityLineType} data={data} />
    </div>
  );
};

const ActivityDialog = () => {
  const { activityDialogState, closeActivityDialog } = useCommonDialogs();
  const { t } = useTranslation();
  const title = t('activity.activity_timeline');
  return (
    <TitledDrawer title={title} anchor="right" open={activityDialogState.isOpen} onClose={closeActivityDialog}>
      <ActivityDialogContent />
    </TitledDrawer>
  );
};

export default ActivityDialog;
