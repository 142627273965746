/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentFormType {
  ORDER = 'order',
  VOUCHER = 'voucher',
}
