import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';

export const CurrentTimeDivider = () => {
  const calculateCurrentMinutePosition = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    const pixelsPerHour = 50;
    const pixelsPerMinute = pixelsPerHour / 60;
    return (currentHour - 5) * pixelsPerHour + currentMinute * pixelsPerMinute;
  };
  const [dividerPosition, setDividerPosition] = useState(calculateCurrentMinutePosition());

  useEffect(() => {
    const interval = setInterval(() => {
      setDividerPosition(calculateCurrentMinutePosition());
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(interval);
  }, []);

  return (
    <Divider
      style={{
        zIndex: 200,
        border: '0.5px solid #6d6d6d',
        marginTop: dividerPosition,
        position: 'absolute',
        width: '100%',
        left: 0,
      }}
    />
  );
};
