import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import React from 'react';
import uuid from 'react-uuid';
import { BuildingBlockType, EmailBlockData } from '../../../api';

type RenderProps = {
  t?: any;
  isSmallScreen?: boolean;
  gridDirection?: 'row' | 'row-reverse';
  borderColor?: string;
};

export interface DraggableItem {
  id: string;
  type: BuildingBlockType;
  render: (renderProps: RenderProps) => JSX.Element;
  toEmailBlockData?: () => EmailBlockData;
  // for items that are should not be moveable
  isStatic?: boolean;
}

export class TextItem implements DraggableItem {
  id: string;

  type = BuildingBlockType.TEXT;

  content: string;

  constructor(id: string, content: string) {
    this.id = id;
    this.content = content;
  }

  toEmailBlockData = (): EmailBlockData => ({
    type: BuildingBlockType.TEXT,
    content: this.content,
  });

  render = ({ gridDirection }: RenderProps) => (
    <Grid container flexDirection={gridDirection}>
      <div
        dangerouslySetInnerHTML={{
          __html: this.content.replaceAll(/<a /g, `<a style="color: #4abd00; text-decoration: underline;" `),
        }}
      />
    </Grid>
  );
}

export class LineBreakItem implements DraggableItem {
  id: string;

  type = BuildingBlockType.LINE_BREAK;

  constructor(id: string) {
    this.id = id;
  }

  render = ({ borderColor }: RenderProps) => (
    <Grid width="80%">
      <Divider style={{ width: '100%', border: `1px solid ${borderColor || '#4fbf13'} ` }} />
    </Grid>
  );

  toEmailBlockData = (): EmailBlockData => ({
    type: BuildingBlockType.LINE_BREAK,
  });
}

export class ImageItem implements DraggableItem {
  id: string;

  type = BuildingBlockType.IMAGE;

  src: string;

  constructor(id: string, src: string) {
    this.id = id;
    this.src = src;
  }

  render = ({ t, isSmallScreen }: RenderProps) =>
    this.src === '' ? (
      <Typography>{t('emailTemplates.noImage')}</Typography>
    ) : (
      <Grid container direction="column" flexWrap="nowrap" maxWidth="80%">
        <img
          src={this.src.replace('raw', 'image').concat('?w=600')}
          width={isSmallScreen ? '120px' : undefined}
          alt="logo"
        />
      </Grid>
    );

  toEmailBlockData = (): EmailBlockData => ({
    type: BuildingBlockType.IMAGE,
    src: this.src,
  });
}

export function createDraggableItem(data: EmailBlockData): DraggableItem {
  const id = `${data.type}-${uuid()}`;
  switch (data.type) {
    case BuildingBlockType.TEXT:
      return new TextItem(id, data.content || '');
    case BuildingBlockType.LINE_BREAK:
      return new LineBreakItem(id);
    case BuildingBlockType.IMAGE:
      return new ImageItem(id, data.src || '');
    default:
      throw new Error(`Unsupported BuildingBlockType: ${data.type}`);
  }
}
