import React from 'react';
import { Input, SelectProps, useTheme } from '@mui/material';
import { StyledSelectField } from './StyledSelectField';

export const ThemedSelectField = (props: Omit<SelectProps, 'variant'>) => {
  const theme = useTheme();

  const textColor = theme.customTheme.palette.text.primary;
  const { lineColor } = theme.customTheme.palette;

  return (
    <StyledSelectField
      textColor={textColor}
      lineColor={lineColor}
      variant="standard"
      input={
        <Input
          sx={{
            ':before': { borderBottomColor: lineColor },
            ':after': { borderBottomColor: lineColor },
          }}
        />
      }
      {...props}
    />
  );
};
