import React from 'react';
import { OrderService } from '../api';
import { OrdersTable } from './OrdersTable';

export const ArchivedOrders = () => {
  const opId = 'getArchivedOrders';
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const fetchingMethod = OrderService.getArchivedOrders;
  return <OrdersTable fetchingMethod={fetchingMethod} opId={opId} hideActions />;
};
