import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { FormikHelpers } from 'formik';
import { WorkDayType, WorkingHoursData } from '../../api';
import { FormTimePicker } from '../formComponents/FormTimePicker';
import { OrderPageUpdate } from '../OrderPageSettingsPage';
import { WorkingHoursFixed } from './WorkingHoursFixed';

type workingHoursProps = {
  workingHoursData: WorkingHoursData;
  isSubmitting: boolean;
  fieldValueDayIndex: string;
  setFieldValue: FormikHelpers<OrderPageUpdate>['setFieldValue'];
  initialWorkingHours?: WorkingHoursData;
  readOnly: boolean;
};

export const WorkingHours = ({
  workingHoursData,
  isSubmitting,
  fieldValueDayIndex,
  setFieldValue,
  initialWorkingHours,
  readOnly,
}: workingHoursProps) => {
  const { t } = useTranslation();
  const formIsDisabled = readOnly || isSubmitting;

  if (workingHoursData.work_day_type === WorkDayType.FIXED) {
    return (
      <WorkingHoursFixed
        setFieldValue={setFieldValue}
        fieldValueDayIndex={fieldValueDayIndex}
        isSubmitting={isSubmitting}
        initialWorkingHours={initialWorkingHours}
      />
    );
  }
  if (workingHoursData.work_day_type === WorkDayType.NOT_WORKING) {
    return null;
  }
  return (
    <Grid display="flex" alignItems="center">
      <Grid item>
        <Typography>{t('workingDays.from')}</Typography>
      </Grid>
      <Grid item mx={1}>
        <FormTimePicker
          disabled={formIsDisabled}
          fieldName={`${fieldValueDayIndex}.from_time`}
          setFieldValue={setFieldValue}
          initialValue={initialWorkingHours?.from_time}
        />
      </Grid>
      <Grid item>
        <Typography>{t('workingDays.to')}</Typography>
      </Grid>
      <Grid item mx={1}>
        <FormTimePicker
          disabled={formIsDisabled}
          fieldName={`${fieldValueDayIndex}.to_time`}
          setFieldValue={setFieldValue}
          initialValue={initialWorkingHours?.to_time}
        />
      </Grid>
    </Grid>
  );
};
