/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WorkDayType {
  INTERVAL = 'interval',
  FIXED = 'fixed',
  NOT_WORKING = 'not_working',
}
