import React from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { OrderPageData, UnavailabilityDetailData } from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';
import { ParallelPersonsCapacitiesSetting } from './ParallelPersonsCapacitiesSetting';
import { CAPACITIES_BLOCKS_ARRAY, MAX_PARALLEL_PERSON_CAPACITY, STATIONS } from './UnavailabilityDialog';

export const getOrderPageLabel = (language: 'en' | 'he', orderPageData: OrderPageData[], orderPageId: string) => {
  const orderPage = orderPageData.find((x) => x.id === orderPageId);
  if (orderPage) {
    return orderPage.label[language];
  }
  return '';
};

export const UnavailabilityOrderPagesMultiSelect = ({
  orderPages,
  unavailabilityDetails,
  setUnavailabilityDetails,
}: {
  orderPages: OrderPageData[];
  unavailabilityDetails: UnavailabilityDetailData[];
  setUnavailabilityDetails: (selected: UnavailabilityDetailData[]) => void;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const shouldBlockAllPages = unavailabilityDetails.length === orderPages.length;
  const updateCustomDetails = (order_page_id: string, key: string, value: any) => {
    const clonedDetails = [...unavailabilityDetails];

    const updatedDetails = clonedDetails.map((detail) => {
      if (detail.order_page_id === order_page_id) {
        return { ...detail, custom_details: { [key]: value } };
      }
      return detail;
    });

    setUnavailabilityDetails(updatedDetails);
  };

  function renderCustomDetails(orderPageId: string) {
    const orderPage = orderPages.find((x) => x.id === orderPageId);
    if (!orderPage) {
      return null;
    }
    const customDetail =
      orderPage?.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities;
    const unavailabilityDetail = unavailabilityDetails.find((x) => x.order_page_id === orderPageId);
    const hasLegacyCustomDetail = unavailabilityDetail?.custom_details?.[MAX_PARALLEL_PERSON_CAPACITY];
    const customResourceDetail = orderPage.custom_details?.find(
      (c: { cls: string }) => c.cls === 'CustomResourceDetail',
    );
    if (!customDetail && !customResourceDetail) {
      return null;
    }
    if (customResourceDetail) {
      // @ts-ignore
      const { max, label: labelData } = customResourceDetail;
      // @ts-ignore
      const label = labelData[language];
      // @ts-ignore
      const value = unavailabilityDetail?.custom_details?.[customResourceDetail.name];
      if (!value) {
        updateCustomDetails(orderPageId, STATIONS, 3);
      }
      return (
        <Grid container gap={1} alignItems="center" py={1}>
          <Grid item>
            <Typography variant="body1">{label}</Typography>
          </Grid>
          <TextField
            size="small"
            type="number"
            InputProps={{
              inputProps: { min: 0, max },
            }}
            value={value}
            onChange={(e) => updateCustomDetails(orderPageId, customResourceDetail?.name, e.target.value)}
          />
        </Grid>
      );
    }
    if (hasLegacyCustomDetail) {
      return (
        <Grid container sx={{ marginTop: '30px' }} gap={3} alignItems="center">
          <TextField
            label={t('availabilitySettings.blockResources')}
            sx={{ width: '120px' }}
            type="number"
            value={unavailabilityDetail?.custom_details?.[MAX_PARALLEL_PERSON_CAPACITY]}
            InputProps={{
              inputProps: { min: 0, max: customDetail?.length },
            }}
            onChange={(e) => updateCustomDetails(orderPageId, MAX_PARALLEL_PERSON_CAPACITY, +e.target.value)}
          />
        </Grid>
      );
    }
    return (
      <ParallelPersonsCapacitiesSetting
        orderPageData={orderPage}
        initialBlockedCapacities={unavailabilityDetail?.custom_details?.[CAPACITIES_BLOCKS_ARRAY] || customDetail}
        setDetail={(blockedCapacities: number[]) =>
          updateCustomDetails(orderPageId, CAPACITIES_BLOCKS_ARRAY, blockedCapacities)
        }
      />
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <FormControl sx={{ minWidth: '250px' }}>
      <FormControlLabel
        control={
          <Checkbox
            indeterminate={!shouldBlockAllPages && unavailabilityDetails.length !== 0}
            checked={shouldBlockAllPages}
          />
        }
        label={t('calendar.unavailability_dialog.selectAllPages')}
        onChange={(e: any) => {
          setUnavailabilityDetails(
            e.target.checked ? orderPages.map((x: OrderPageData) => ({ order_page_id: x.id })) : [],
          );
        }}
      />
      <Grid container flexDirection="column" ml={3} gap={1}>
        {orderPages.map((orderPage) => {
          const checked = !!unavailabilityDetails.find((x) => x.order_page_id === orderPage.id);
          return (
            <FormControlLabel
              key={`orderpage-${orderPage.id}`}
              control={
                <Grid display="flex" alignItems="flex-start" height="100%" paddingTop={0}>
                  <Checkbox
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        const newDetails = [...unavailabilityDetails, { order_page_id: orderPage.id }];
                        setUnavailabilityDetails(newDetails);
                      } else {
                        setUnavailabilityDetails(unavailabilityDetails.filter((x) => x.order_page_id !== orderPage.id));
                      }
                    }}
                    checked={checked}
                  />
                </Grid>
              }
              label={
                <Grid container flexDirection="column" borderRadius="8px" border="1px solid #c4c4c4" p={0.5} px={1}>
                  <Grid item>{getOrderPageLabel(language, orderPages, orderPage.id)}</Grid>
                  <Grid item>{checked && renderCustomDetails(orderPage.id)}</Grid>
                </Grid>
              }
            />
          );
        })}
      </Grid>
    </FormControl>
  );
};
