import moment from 'moment/moment';
import 'moment/locale/he';
import React from 'react';
import { CalendarItems } from '../CalendarWrapper';
import './MonthView.scss';
import MonthCalendarCell from '../MonthCalendarCell';
import { EnrichedEventData, OrderData } from '../../api';
import { MonthViewSkeleton } from './MonthViewSkeleton';

type DayComponentProps = {
  date: string;
  calendarItems: CalendarItems;
  openOrder: (event: any, order: OrderData) => void;
  openEvent: (event: EnrichedEventData) => void;
};
const DayComponent: React.FC<DayComponentProps> = ({ date, calendarItems, openOrder, openEvent }) => (
  <MonthCalendarCell
    calendarItems={calendarItems}
    dateToRender={moment(date).format('YYYY-MM-DD')}
    openOrder={openOrder}
    openEvent={openEvent}
  />
);
type MonthViewProps = {
  currentDate: string;
  calendarItems: CalendarItems;
  openOrder: (event: any, order: OrderData) => void;
  openEvent: (event: EnrichedEventData) => void;
};
const MonthView = ({ currentDate, calendarItems, openOrder, openEvent }: MonthViewProps) => {
  return (
    <MonthViewSkeleton
      currentDate={currentDate}
      DayComponent={(props) => (
        <DayComponent {...props} calendarItems={calendarItems} openOrder={openOrder} openEvent={openEvent} />
      )}
    />
  );
};
export default MonthView;
