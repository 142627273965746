import React from 'react';
import { ErrorMessage } from 'formik';
import styled from 'styled-components';

const StyledErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 2px;
`;

type StyledErrorMessageProps = {
  name: string;
};
export const StyledErrorMessage = ({ name }: StyledErrorMessageProps) => (
  <StyledErrorText>
    <ErrorMessage name={name} />
  </StyledErrorText>
);
