import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import './OrderPageCard.scss';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLanguage } from '../common/GeneralUtils';

const OrderPageCard = (props) => {
  const { orderPage, accountId, experienceId, borderColor, color, bgColor } = props;
  const { i18n } = useTranslation();
  const language = useLanguage();

  let arrow = <NavigateNext className="arrow" />;
  if (i18n.dir() === 'rtl') {
    arrow = <NavigateBefore className="arrow" />;
  }

  return (
    <Link
      className="no-style-link order-page-card-link"
      to={`/order/${accountId}/${experienceId}/${orderPage.id}?bgcolor=${bgColor}&bcolor=${borderColor}&color=${color}`}>
      <Box className="order-page-card" style={{ border: `1px solid #${borderColor || 'ccc'}` }}>
        <Box className="card-flexbox">
          <Box className="image-container">
            <CardMedia component="img" height="180" width="180" image={orderPage.photo} className="image-card" />
          </Box>
          <Box className="flex-1" sx={{ padding: '12px' }}>
            <Typography gutterBottom variant="h5" component="div">
              {orderPage.label[language]}
            </Typography>
            <Typography variant="body2" color={color || 'text.secondary'}>
              {orderPage.description[language]}
            </Typography>
          </Box>
          <Box className="arrow-container">{arrow}</Box>
        </Box>
      </Box>
    </Link>
  );
};

export default OrderPageCard;
