import './MainSidebar.scss';
import { Drawer, SwipeableDrawer, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { isEmpty } from 'lodash';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import 'moment/dist/locale/he';
import { NavigationItemButton } from './NavigationItemButton';
import { NavigationHeaderButton } from './NavigationHeaderButton';
import { useLanguage } from '../../common/GeneralUtils';

export const mainSidebarWidth = 260;
export type NavigationItem = {
  key: string;
  name: string;
  icon?: string;
  navigate: string;
  external?: boolean;
  target?: string;
};

export type NavigationHeader = NavigationItem & {
  navigationSubItems?: (NavigationItem | undefined)[];
};
const MainSidebar = (props: any) => {
  const { isSidebarOpen, setIsSidebarOpen } = props;
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const lang = useLanguage();
  const discountsEnabled = useFeatureFlagEnabled('discounts');
  const { readOnly, acctSettingsEditor: settingsEnabled } = UseAuthPermissions();
  const templateEditorEnabled = useFeatureFlagEnabled('template-editor');

  const navigations: (NavigationHeader | undefined)[] = [
    {
      key: 'calendar',
      name: t('main_sidebar.calendar'),
      icon: 'calendar_today',
      navigate: '/dashboard/calendar',
    },
    !readOnly
      ? {
          key: 'orders',
          name: t('main_sidebar.orders'),
          icon: 'people',
          navigate: '/dashboard/orders',
        }
      : undefined,
    !readOnly
      ? {
          key: 'vouchers',
          name: t('main_sidebar.vouchers'),
          icon: 'local_activity',
          navigate: '/dashboard/vouchers',
        }
      : undefined,
    {
      key: 'order-page',
      name: t('main_sidebar.order_pages'),
      icon: 'shopping_cart',
      navigate: '/dashboard/order-pages',
    },
    discountsEnabled
      ? {
          key: 'discounts',
          name: t('main_sidebar.discounts'),
          icon: 'percent',
          navigate: `/dashboard/discounts`,
        }
      : undefined,
    {
      key: 'employees',
      name: t('main_sidebar.employees'),
      icon: 'group',
      navigate: `${import.meta.env.VITE_REACT_APP_AUTH_URL}/org`,
      external: true,
      target: '_blank',
    },
    {
      key: 'user-settings',
      name: t('main_sidebar.usersettings'),
      icon: 'settings',
      navigate: '/dashboard/user-settings',
      navigationSubItems: [
        { key: 'user-avliaty', navigate: '/dashboard/user-availability', name: t('main_sidebar.useravailability') },
        {
          key: 'user-settings-settings',
          navigate: '/dashboard/user-settings',
          name: t('main_sidebar.usersettings'),
        },
      ],
    },
    settingsEnabled
      ? {
          key: 'settings',
          name: t('main_sidebar.settings'),
          icon: 'settings',
          navigate: `/dashboard/account-settings`,
          navigationSubItems: [
            { key: 'account-settings', navigate: '/dashboard/account-settings', name: t('main_sidebar.settings') },
            templateEditorEnabled
              ? {
                  key: 'email-templates',
                  navigate: '/dashboard/email-templates',
                  name: t('main_sidebar.email_templates'),
                }
              : undefined,
          ],
        }
      : undefined,
  ];

  const changeLang = (lang: string) => {
    void i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    moment.locale(lang);
    localStorage.setItem('calendar_language', lang);
  };

  const drawer = (
    <div className="main-sidebar">
      <div className="header-container">
        <div className="logo">
          <img src="/images/logos/monkeybook-dark.png" />
        </div>

        <div className="language-container">
          <span onClick={() => changeLang('en-US')} className={lang === 'en' ? 'selected' : ''}>
            en
          </span>{' '}
          | &nbsp;
          <span onClick={() => changeLang('he')} className={lang === 'he' ? 'selected' : ''}>
            he
          </span>
        </div>
      </div>

      <div className="links-container">
        {navigations.map((item) => {
          if (!item) {
            return null;
          }
          if (!isEmpty(item.navigationSubItems)) {
            return <NavigationHeaderButton navigationHeader={item} setIsSidebarOpen={setIsSidebarOpen} />;
          }
          return <NavigationItemButton setIsSidebarOpen={setIsSidebarOpen} navigationItem={item} height={'44px'} />;
        })}
      </div>
    </div>
  );

  return (
    <>
      <SwipeableDrawer
        onOpen={() => setIsSidebarOpen(true)}
        anchor="left"
        className="drawer-container"
        variant="temporary"
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: mainSidebarWidth,
          },
        }}>
        {drawer}
      </SwipeableDrawer>

      <Drawer
        className="drawer-container"
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: mainSidebarWidth,
            zIndex: '100',
          },
        }}
        open>
        {drawer}
      </Drawer>
    </>
  );
};

export default MainSidebar;
