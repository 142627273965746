/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentLinkType {
  MAIN_PAYMENT = 'main_payment',
  ADDITIONAL_PAYMENT = 'additional_payment',
}
